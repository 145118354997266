<template>
  <div id="mainMenu">
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
      <div class="botMenu bcn-icon-menu" :class="{'opacity-20': layerMenuStatus.opened || infoPanel.visible}" @click="toggleMenu">
        <span class="incidencias-badge"
              v-if="!mainMenuStatus.opened && trafficIncidents.new_incidents.length>0 && !trafficIncidents.first_read">
          {{trafficIncidents.new_incidents.length}}
        </span>
      </div>
    </transition>
    <transition enter-active-class="animated fadeIn slideInLeft" leave-active-class="animated fadeOut slideOutLeft">
      <div class="menu-content" v-if="mainMenuStatus.opened" :style="menuHeight" v-touch:swipe.left="toggleMenu">
        <div class="comshiva">Com s'hi va</div>
        <div class="closeButton" @click="toggleMenu"></div>
        <div class="botMenu bcn-icon-menu" :class="{'opacity-0': mainMenuStatus.opcionMenu!=''}" v-if="mainMenuStatus.opened"></div>

        <!-- Level 1 Menu -->
        <ul class="level-1">
          <li class="pb-0" style="display:none">
            <div class="text-link"><a href="">Inicia sessió</a> / <a href="">Registra't</a></div>
            <div class="text-description">
              Quan ets usuari registrat del Com s'hi va, pots desar rutes i itineraris habituals, històric de recerques i moltes coses més.
            </div>
            <hr>
          </li>
          <li class="has-arrow" @click="openOption('menu-preferencias')">{{$t('route_preferences')}} </li>
          <li class="has-arrow" @click="openOption('menu-travel-card')">{{$t('hello_barcelona_travel_card')}} </li>
          <li class="has-arrow" @click="openOption('menu-airport')">{{$t('go_to_the_airport')}} </li>
          <li class="has-arrow" @click="openOption('menu-beyond-barcelona')">{{$t('beyond_barcelona')}} </li>
          <li class="has-arrow" @click="openOption('menu-lineas')">{{$t('maps_and_lines')}}</li>
          <li class="has-arrow" @click="openOption('menu-incidencias')">
            {{$t('traffic_incidents')}}
            <span class="incidencias-badge"
                  v-if="trafficIncidents.new_incidents.length>0">
              {{trafficIncidents.new_incidents.length}}
            </span>
          </li>
          <li class="has-arrow" @click="openOption('menu-recommendations')">{{$t('recommendations')}}</li>
          <li class="has-arrow" @click="openHelp">{{$t('help')}}</li>
          <li class="has-arrow"><a :href="$t('link_contact')" target="_blank">{{$t('contact')}}</a></li>
          <li class="has-arrow"><a :href="$t('link_legal')" target="_blank">{{$t('legal_disclaimer')}}</a></li>
          <li class="has-arrow"><a :href="$t('link_accessibility')" target="_blank">{{$t('accessibility')}}</a></li>
        </ul>
        <div class="logo-ajuntament">
          <img src="@/assets/img/logo-ajuntament.png">
        </div>
        <transition-group enter-active-class="animated fadeIn slideInLeft" leave-active-class="animated fadeOut slideOutLeft">

          <!-- Level 2: Preferences -->
          <div class="level-2 menu-preferencias" key="preferences" :style="level2Height" v-if="mainMenuStatus.opcionMenu=='menu-preferencias'">
            <div class="title" @click="openOption('')">{{$t('route_preferences')}}</div>
            <div class="subtitle mt-4 mb-3">{{$t('by_type_of_route')}}</div>
            <ul class="radios">
              <li>
                <p-radio class="p-default p-round" name="tipoRuta" @change="preferencesChanged" v-model="preferencias.tipoRuta" value="BEST-ROUTE" color="primary-o">{{$t('best_route')}}</p-radio>
              </li>
              <li>
                <p-radio class="p-default p-round" name="tipoRuta" @change="preferencesChanged" v-model="preferencias.tipoRuta" value="LESS_WALKING" color="primary-o">{{$t('less_walking')}}</p-radio>
              </li>
              <li>
                <p-radio class="p-default p-round" name="tipoRuta" @change="preferencesChanged" v-model="preferencias.tipoRuta" value="FEWER_TRANSFERS" color="primary-o">{{$t('fewer_transfers')}}</p-radio>
              </li>
              <li>
                <p-radio class="p-default p-round" name="tipoRuta" @change="preferencesChanged" v-model="preferencias.tipoRuta" value="ACCESSIBLE-ROUTES" color="primary-o">{{$t('accessible_routes')}}</p-radio>
                <div class="text-description">
                  {{$t('transport_public_priority')}}
                </div>
              </li>
              <li>
                <p-radio class="p-default p-round" name="tipoRuta" @change="preferencesChanged" v-model="preferencias.tipoRuta" value="WALKING" color="primary-o">{{$t('walk')}}</p-radio>
              </li>
              <li>
                <p-radio class="p-default p-round" name="tipoRuta" @change="preferencesChanged" v-model="preferencias.tipoRuta" value="BICYCLING" color="primary-o">{{$t('bici')}}</p-radio>
              </li>
              <li>
                <p-radio class="p-default p-round" name="tipoRuta" @change="preferencesChanged" v-model="preferencias.tipoRuta" value="TRANSIT" color="primary-o">{{$t('public_transport')}}</p-radio>
              </li>
              <li>
                <p-radio class="p-default p-round" name="tipoRuta" @change="preferencesChanged" v-model="preferencias.tipoRuta" value="DRIVING" color="primary-o">{{$t('car_moto')}}</p-radio>
              </li>
            </ul>
            <div class="subtitle mb-3 mt-4">{{$t('by_type_of_transport')}}</div>
            <ul class="pb-5 checks">
              <li>
                <p-check class="p-icon" @change="preferencesChanged" v-model="preferencias.transitModes" value="SUBWAY">
                  <i class="icon bcn-icon-ok-bold" slot="extra"></i>
                  {{$t('metro')}}
                </p-check>
              </li>
              <li>
                <p-check class="p-icon" @change="preferencesChanged" v-model="preferencias.transitModes" value="BUS">
                  <i class="icon bcn-icon-ok-bold" slot="extra"></i>
                  {{$t('bus')}}
                </p-check>
              </li>
              <li>
                <p-check class="p-icon" @change="preferencesChanged" v-model="preferencias.transitModes" value="TRAIN">
                  <i class="icon bcn-icon-ok-bold" slot="extra"></i>
                  {{$t('fgc_trains')}}
                </p-check>
              </li>
              <li>
                <p-check class="p-icon" @change="preferencesChanged" v-model="preferencias.transitModes" value="RAIL">
                  <i class="icon bcn-icon-ok-bold" slot="extra"></i>
                  {{$t('local_trains')}}
                </p-check>
              </li>
              <li>
                <p-check class="p-icon" @change="setPreferencias" v-model="preferencias.transitModes" value="TRAM">
                  <i class="icon bcn-icon-ok-bold" slot="extra"></i>
                  {{$t('trams')}}
                </p-check>
              </li>
            </ul>
          </div>

          <!-- Level 2: Maps and transport lines -->
          <div class="level-2 top0 menu-lineas" key="maps" :style="level2Height" v-show="mainMenuStatus.opcionMenu=='menu-lineas'">
            <div class="title" @click="openOption('')">{{$t('maps_and_lines')}}</div>

            <!-- Search -->
            <search ref="mainmenuSearch" :placeholder="$t('search_line')" layers="transport"></search>

            <!-- Transport options -->
            <ul class="lineas" v-if="!activeSearch">
              <li>
                <div v-b-toggle.menu-lineas-metro class="bot-tipo-transport">{{$t('metro')}}</div>
                <b-collapse tag="ul" id="menu-lineas-metro">
                  <li class="linea" @click="selectLineaMetro(linea)" v-for="(linea, index) in lineas.metro" v-bind:key="index">
                        <span>
                          <img class="logo-metro" :src="require(`@/assets/img/metro/${linea.linea}.png`)">
                        </span>
                    <span>{{linea.descripcio}}</span>
                    <span class="csvicon-alerta" v-if="alterationAlertVisible(linea.linea)"></span>
                  </li>
                </b-collapse>
              </li>
              <li>
                <div v-b-toggle.menu-lineas-bus class="bot-tipo-transport">{{$t('bus')}}</div>
                <b-collapse tag="ul" id="menu-lineas-bus">
                  <li class="linea" @click="selectLineaBus(linea)" v-for="(linea, index) in lineas.bus" v-bind:key="index">
                    <div>
                      <span class="marca" :style="{'background-color': `#${linea.color}`}">{{linea.linea}}</span>
                    </div>
                    <span>{{linea.descripcio}}</span>
                  </li>
                </b-collapse>
              </li>
              <li>
                <div v-b-toggle.menu-lineas-bus-north class="bot-tipo-transport">Bus Estació del Nord</div>
                <b-collapse tag="ul" id="menu-lineas-bus-north">
                  <li class="linea" @click="selectLineaBusNorth(linea)" v-for="(linea, index) in lineas.busnorth" v-bind:key="index">
                    <span>{{linea.descripcio}}</span>
                  </li>
                </b-collapse>
              </li>
              <li>
                <div v-b-toggle.menu-lineas-ferrocarril class="bot-tipo-transport">{{$t('fgc_trains')}}</div>
                <b-collapse tag="ul" id="menu-lineas-ferrocarril">
                  <li class="linea" @click="selectLineaFerrocarril(linea)" v-for="(linea, index) in lineas.ferrocarril" v-bind:key="index">
                    <span><img class="logo-fgc" :src="require(`@/assets/img/fgc/${linea.linea}.png`)"></span>
                    {{linea.descripcio}}
                  </li>
                </b-collapse>
              </li>
              <li>
                <div v-b-toggle.menu-lineas-rodalies class="bot-tipo-transport">{{$t('local_trains')}}</div>
                <b-collapse tag="ul" id="menu-lineas-rodalies">
                  <li class="linea" @click="selectLineaRodalies(linea)" v-for="(linea, index) in lineas.rodalies" v-bind:key="index">
                    <span><img class="logo-metro" :src="require(`@/assets/img/rodalies/${linea.linea}.png`)"></span>
                    {{linea.descripcio}}
                  </li>
                </b-collapse>
              </li>
              <li>
                <div v-b-toggle.menu-lineas-tramvia class="bot-tipo-transport">{{$t('trams')}}</div>
                <b-collapse tag="ul" id="menu-lineas-tramvia">
                  <li class="linea" @click="selectLineaTram(linea)" v-for="(linea, index) in lineas.tram" v-bind:key="index">
                    <span><img class="logo-metro" src="@/assets/img/logo-tram.png"></span>
                    <strong class="mr-1">{{linea.linea}}</strong> {{linea.descripcio}}
                  </li>
                </b-collapse>
              </li>
              <li>
                <div v-b-toggle.menu-lineas-aerobus class="bot-tipo-transport">{{$t('aerobus')}}</div>
                <b-collapse tag="ul" id="menu-lineas-aerobus">
                  <li class="linea" @click="selectLineaAerobus(linea)" v-for="(linea, index) in lineas.aerobus" v-bind:key="index">
                    <span class="marca" :style="{'background-color': '#'+linea.color}">{{linea.linea}}</span> {{linea.descripcio}}
                  </li>
                </b-collapse>
              </li>
              <li>
                <div v-b-toggle.menu-lineas-funicular class="bot-tipo-transport">{{$t('funicular')}}</div>
                <b-collapse tag="ul" id="menu-lineas-funicular">
                <li class="linea" @click="selectLineaFunicular(linea)" v-for="(linea, index) in lineas.funicular" v-bind:key="index">
                  <span><img class="logo-metro" :src="require(`@/assets/img/${linea.logo}`)"></span>
                  {{linea.descripcio}}
                </li>
                </b-collapse>
              </li>
              <li>
                <div class="bot-tipo-transport single" @click="loadTaxi()">Taxi</div>
              </li>
            </ul>
          </div>

          <!-- Level 2: Incidents -->
          <div class="level-2 menu-incidencias" key="incidents" :style="level2Height" v-if="mainMenuStatus.opcionMenu=='menu-incidencias'">
            <div class="title" @click="openOption('')">{{$t('traffic_incidents')}}</div>
            <div class="incidents">
              <div class="incident" v-for="(incident, key) in trafficIncidents.incidents" v-bind:key="key">
                <div class="text" v-html="incident"></div>
                <span class="share-icon bcn-icon-comparteix" @click="share(incident)"></span>
              </div>
              <div class="no-incidents" v-if="trafficIncidents.incidents.length==0 && !trafficIncidents.first_read">
                {{$t('no_incidents')}}
              </div>
            </div>
          </div>
          <!-- Level 2: Recommendations -->
          <div class="level-2 menu-recommendations" key="incidents" :style="level2Height" v-if="mainMenuStatus.opcionMenu=='menu-recommendations'">
            <div class="title" @click="openOption('')">{{$t('recommendations')}}</div>
            <div class="recommendations">
              <div class="recommendation">
                <div class="text">{{$t('recommendation_1')}}</div>
              </div>
              <div class="recommendation">
                <div class="text">{{$t('recommendation_2')}}</div>
              </div>
              <div class="recommendation">
                <div class="text">{{$t('recommendation_3')}}</div>
              </div>
            </div>
          </div>
          <!-- Level 2: Hola Barcelona Travel Card -->
          <div class="level-2 menu-page-content" key="incidents" :style="level2Height" v-if="mainMenuStatus.opcionMenu=='menu-travel-card'">
            <div class="title" @click="openOption('')">{{$t('hello_barcelona_travel_card')}}</div>
            <div class="content">
              <p>
                {{$t('travel_card_txt')}}
              </p>
              <ul>
                <li><strong>2 {{$t('days')}} (48 h)</strong></li>
                <li><strong>3 {{$t('days')}} (72 h)</strong></li>
                <li><strong>4 {{$t('days')}} (96 h)</strong></li>
                <li><strong>5 {{$t('days')}} (120 h)</strong></li>
                <li>({{$t('consecutive_days')}})</li>
              </ul>
              <p class="mt-4 ml-3">
                <a target="_blank" class="text-link" href="https://www.holabarcelona.com/ca/tickets/hola-bcn-barcelona-travel-card"><strong>{{$t('more_info')}}</strong></a>
              </p>
            </div>
          </div>
          <!-- Level 2: Go to the airport -->
          <div class="level-2 menu-page-content" key="incidents" :style="level2Height" v-if="mainMenuStatus.opcionMenu=='menu-airport'">
            <div class="title" @click="openOption('')">{{$t('go_to_the_airport')}}</div>
            <div class="content">
              <ul class="lines">
                <li @click="openLine('aerobus', 'A1')">Aerobús</li>
                <li @click="openLine('metro', 'L9S')">{{ $t('airport_metro') }}</li>
                <li @click="openLine('rodalies', 'R2N')">{{ $t('airport_local_trains') }}</li>
                <li @click="openTaxi()">Taxi</li>
              </ul>
            </div>
          </div>
          <!-- Level 2: Beyond Barcelona -->
          <div class="level-2 menu-page-content" key="incidents" :style="level2Height" v-if="mainMenuStatus.opcionMenu=='menu-beyond-barcelona'">
            <div class="title" @click="openOption('')">{{$t('beyond_barcelona')}}</div>
            <div class="content">
              <ul class="lines">
                <li @click="openLine('ferrocarril', 'S4')">Montserrat</li>
                <li @click="openLine('rodalies', 'R17')">Port Aventura</li>
                <li @click="openLine('rodalies', 'R2S')">Sitges</li>
              </ul>
            </div>
          </div>
        </transition-group>

        <!-- Level 3: Stations -->
        <transition enter-active-class="animated fadeIn slideInLeft" leave-active-class="animated fadeOut slideOutLeft">
          <div class="level-2 top0 menu-detalle-linea" :style="level2Height" v-show="mainMenuStatus.opcionMenu=='menu-lineas' && lineaSelected!=''">
            <div class="title mb-4" @click="deselectLine">
              <span v-if="lineaSelected.type!='busnorth'">{{lineaSelected.linea}}</span>
              {{lineaSelected.descripcio}}
            </div>
            <div class="link" v-if="lineaSelected.type!='busnorth'">
              <a href="javascript://" @click="loadLineMap(lineaSelected.type, lineaSelected.linea)">
                <i class="bcn-icon-mapa mr-1"></i>
                <span>{{$t('see_line_map')}}</span>
              </a>
            </div>
            <div v-if="alterationAlertVisible(lineaSelected.linea)" class="alterationMessage">
              <span class="csvicon-alerta"></span>
              <a :href="alterationAlertVisible(lineaSelected.linea).links[$i18n.locale]" target="_blank">
                {{ alterationAlertVisible(lineaSelected.linea).missatges[$i18n.locale] }}
              </a>
            </div>
            <div class="link" v-if="false">
              <a href="javascript://" @click="getScheduleUrl(lineaSelected)">
                <i class="bcn-icon-rellotge mr-1"></i>
                <span>Horaris del servei</span>
              </a>
            </div>
            <hr>
            <ul class="estacions">
              <li
                @click="loadStationMap(lineaSelected.type, lineaSelected.linea, estacio.codi)"
                v-for="(estacio, key, index) in lineaSelected.estacions"
                v-bind:key="index"
                :class="{'first':index==0, 'last': index==(Object.keys(lineaSelected.estacions).length-1)}"
              >
                <span class="nomestacio">{{estacio.nom}}</span>
                <span class="correspondencies" v-if="estacio.correspondencies">
                  <span v-for="(corr, index) in estacio.correspondencies.Metro" v-bind:key="index">
                    <img class="logo-metro" :src="require(`@/assets/img/metro/${corr}.png`)">
                  </span>
                  <span v-if="estacio.correspondencies.FGC">
                    <img class="logo-metro" src="@/assets/img/logo-fgc.png">
                  </span>
                  <span v-if="estacio.correspondencies['Rodalies-BCN'] || estacio.correspondencies['Rodalies-Regionals']">
                    <img class="logo-metro" src="@/assets/img/logo-rodalies.png">
                  </span>
                  <span v-if="estacio.correspondencies.TRAM">
                    <img class="logo-metro" src="@/assets/img/logo-tram.png">
                  </span>
                  <span v-if="estacio.correspondencies['Metro-Funicular']">
                    <img class="logo-metro" src="@/assets/img/logo-funicular.png">
                  </span>
                </span>
                <span class="accessos">
                  <span class="csvicon-wheelchair mr-1" v-if="estacio.accessibilitat"></span>
                  <span class="csvicon-ascensor mr-1" v-if="estacio.elevador"></span>
                  <span class="bcn-icon-dreta-medium icon-go-map"></span>
                </span>
              </li>
            </ul>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { find } from 'lodash';
import { TransportMixins } from './mixins/TransportMixins';
import { LayerMixins } from './mixins/LayerMixins';
import Search from './Search.vue';
import '../classes/LineaTransportAerobus';
import '../classes/LineaTransportBus';
import '../classes/LineaTransportCercanias';
import '../classes/LineaTransportFerrocarril';
import '../classes/LineaTransportFunicular';
import '../classes/LineaTransportMetro';
import '../classes/LineaTransportTram';
import '../classes/LineaTransportBusNorth';

export default {
  name: 'MainMenu',
  mixins: [
    TransportMixins,
    LayerMixins,
  ],
  components: {
    Search,
  },
  data() {
    return {
      opened: false,
    };
  },
  computed: {
    ...mapState('incidents', [
      'trafficIncidents',
      'trafficIncidentsReaded',
    ]),
    ...mapState('infopanel', [
      'infoPanel',
    ]),
    ...mapState('search', [
      'searchResults',
      'activeSearch',
    ]),
    ...mapState('menu', [
      'mainMenuStatus',
      'layerMenuStatus',
    ]),
    ...mapState('transport', [
      'preferencias',
      'lineas',
      'lineaSelected',
    ]),
    ...mapState('map', [
      'activeLayers',
    ]),
  },
  methods: {
    ...mapMutations({
      setLineas: 'transport/setLineas',
      setLineaSelected: 'transport/setLineaSelected',
      setInfoPanel: 'infopanel/setInfoPanel',
      setMainMenuStatus: 'menu/setMainMenuStatus',
      setPreferencias: 'transport/setPreferencias',
      setSearchResults: 'search/setSearchResults',
      cleanActiveLayers: 'map/cleanActiveLayers',
      setTrafficIncidentsReaded: 'incidents/setTrafficIncidentsReaded',
      setTrafficIncidentsNew: 'incidents/setTrafficIncidentsNew',
      setTrafficIncidentsFirstRead: 'incidents/setTrafficIncidentsFirstRead',
      setHelpOpened: 'help/setOpened',
    }),
    toggleMenu() {
      this.setInfoPanel({
        visible: false,
      });
      this.setMainMenuStatus({
        opened: !this.mainMenuStatus.opened,
      });
      this.$root.$children[0].$refs.planificador.closePanel();
    },
    openOption(option) {
      this.setMainMenuStatus({
        opcionMenu: option,
      });
      if (option == 'menu-incidencias') {
        this.setTrafficIncidentsReaded(true);
        this.setTrafficIncidentsNew(0);
        this.setTrafficIncidentsFirstRead(false);
      }
    },
    preferencesChanged() {
      this.setPreferencias(this.preferencias);
    },
    preferSelected(preference) {
      if (this.preferencias.tipoRuta == preference) {
        return 'selected';
      }
      return '';
    },
    selectPrefer(preference) {
      this.setPreferencias({
        tipoRuta: preference,
      });
    },
    openHelp() {
      this.setMainMenuStatus({ opened: false });
      this.setHelpOpened(true);
    },
    openLine(type, linea_name) {
      this.setMainMenuStatus({
        opcionMenu: 'menu-lineas',
      });
      let TransportClass = null;
      let selectFunction = null;
      switch (type) {
        case 'metro':
          TransportClass = LineaTransportMetro;
          selectFunction = this.selectLineaMetro;
          break;
        case 'bus':
          TransportClass = LineaTransportBus;
          selectFunction = this.selectLineaBus;
          break;
        case 'aerobus':
          TransportClass = LineaTransportAerobus;
          selectFunction = this.selectLineaAerobus;
          break;
        case 'funicular':
          TransportClass = LineaTransportFunicular;
          selectFunction = this.selectLineaFunicular;
          break;
        case 'tram':
          TransportClass = LineaTransportTram;
          selectFunction = this.selectLineaTram;
          break;
        case 'rodalies':
          TransportClass = LineaTransportCercanias;
          selectFunction = this.selectLineaRodalies;
          break;
        case 'ferrocarril':
          TransportClass = LineaTransportFerrocarril;
          selectFunction = this.selectLineaFerrocarril;
          break;
        default:
          break;
      }
      const linea = TransportClass.getLinea(linea_name);
      selectFunction(linea);
    },
    openTaxi() {
      const transportGroup = find(this.layerMenu, (o) => { return o.code == 'transport'; });
      const optionTaxi = find(transportGroup.options, (o) => { return o.code == 'taxi'; });
      optionTaxi.selected = false;
      this.loadTaxi();
      this.setMainMenuStatus({
        opened: false,
      });
      this.$root.$children[0].$refs.mapa.goToMyPosClick();
    },
    async share(incident) {
      const directory = process.env.VUE_APP_PUBLIC_PATH.substr(1);
      const url = `${process.env.VUE_APP_BASE_URL}${directory}${this.$i18n.locale}`;
      const shareData = {
        title: 'Com s\'hi va',
        text: incident,
        url,
      };
      await navigator.share(shareData);
    },
  },
};
</script>
