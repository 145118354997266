<template>
  <div id="planificador">
    <div class="buttons"
         v-if="displayMode!='route' && (trafficIncidents.new_incidents.length==0 || !trafficIncidents.first_read || forceClose)">
      <div
        v-for="(item,index) of modos"
        v-bind:key="index"
        class="button"
        :class="getClassButton(item, index)"
        @click="changeModo(item.modo)"
      >
        <div class="icon" :class="checkedModo(item)"></div>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="opcions-closed"
         v-if="!opened && (trafficIncidents.new_incidents.length==0 || !trafficIncidents.first_read || forceClose)">
      <div class="location-selected" @click="togglePlanificador()">
        <div class="initial-text" v-html="$t('where_to_go')" v-if="!selectedLocation"></div>
        <div class="location-text" v-if="selectedLocation">
          <div class="texts">
            <div class="icon" :class="selectedLocation.icon" v-if="selectedLocation.icon"></div>
            <div>
              <div class="main_text">{{selectedLocation.main_text}}</div>
              <div class="secondary_text">{{selectedLocation.secondary_text}}</div>
            </div>
          </div>
        </div>
        <div class="bcn-icon-cerca"></div>
      </div>
      <button class="bot-menu-planificador" @click="togglePlanificador()"></button>
      <div class="clearfix"></div>
    </div>
    <div class="opcions" v-if="opened">
      <button class="bot-menu-planificador" v-if="!opened" @click="togglePlanificador()"></button>
      <div>
        <div class="input from">
          <label :class="labelAutocomplete('from')" class="labelInput">{{$t('origin')}}</label>
          <input v-model="fromValue"
                 type="text"
                 class="autocomplete"
                 @focus="focusInput('fromInput',true)"
                 @keyup="getSuggestions('from',$event)"
                 v-click-outside="hideResults"
          >
          <div class="clear-input bcn-icon-tancar-medium" @click="clearInput('from')" v-show="fromValue!=''"></div>
          <div class="results" v-if="fromInput && showResultsPanel">
            <div v-if="fromValue==''">
              <div  @click="selectMyPos('from')" v-if="geolocationActive">
                <div class="group"><span class="ico-ubicacio"></span> {{$t('by_position')}}</div>
                <div class="result" :class="{'last': places.length==0}">
                  <div class="text">{{$t('my_current_location')}}</div>
                </div>
              </div>
              <div v-if="places.length>0">
                <div class="group" >{{$t('recent_searches')}}</div>
                <div
                  class="result"
                  v-for="(search, index) in places"
                  v-bind:key="index"
                  @click="selectSavedPlace('from', search)"
                  :class="{'last': index==places.length-1}"
                >
                  <div class="text">
                    {{getPlaceText('main_text', search)}}
                  </div>
                  <div class="desc">
                    {{getPlaceText('secondary_text', search)}}
                  </div>
                </div>
              </div>
            </div>
            <div class="result last" v-if="suggestionsNoResults">
              <div class="text font-600">
                {{$t('no_matches_found')}}
              </div>
                <div class="desc">
                  {{$t('change_keyword')}}
              </div>
            </div>
            <div
              class="result"
              v-for="(prediction, index) in predictions"
              v-bind:key="index"
              @click="selectPrediction('from', prediction)"
              :class="{'last': index==predictions.length-1}"
            >
              <div class="text">
                {{prediction.structured_formatting.main_text}}
              </div>
                <div class="desc">
                {{prediction.structured_formatting.secondary_text}}
              </div>
            </div>
          </div>
        </div>
        <div class="input to">
          <label :class="labelAutocomplete('to')" class="labelInput">{{$t('destination')}}</label>
          <input v-model="toValue"
                 type="text"
                 class="autocomplete"
                 @focus="focusInput('toInput',true)"
                 @click="hideResults"
                 @keyup="getSuggestions('to',$event)"
                 v-click-outside="hideResults"
          >
          <div class="clear-input bcn-icon-tancar-medium" @click="clearInput('to')" v-show="toValue!=''"></div>
          <button class="bot-switch-planificador" @click="swapFields"></button>
          <div class="results" v-if="toInput && showResultsPanel">
            <div v-if="toValue==''">
              <div @click="selectMyPos('to')" v-if="geolocationActive">
                <div class="group"><span class="ico-ubicacio"></span> {{$t('by_position')}}</div>
                <div class="result" :class="{'last': places.length==0}">
                  <div class="text">{{$t('my_current_location')}}</div>
                </div>
              </div>
              <div v-if="places.length>0">
              <div class="group" >{{$t('recent_searches')}}</div>
              <div
                class="result"
                v-for="(search, index) in places"
                v-bind:key="index"
                @click="selectSavedPlace('to', search)"
                :class="{'last': index==places.length-1}"
              >
                <div class="text">
                  {{getPlaceText('main_text', search)}}
                </div>
                <div class="desc">
                  {{getPlaceText('secondary_text', search)}}
                </div>
              </div>
            </div>
            </div>
            <div class="result last" v-if="suggestionsNoResults">
              <div class="text font-600">
                {{$t('no_matches_found')}}
              </div>
              <div class="desc">
                {{$t('change_keyword')}}
              </div>
            </div>
            <div
              class="result"
              v-for="(prediction, index) in predictions"
              v-bind:key="index"
              @click="selectPrediction('to', prediction)"
              :class="{'last': index==predictions.length-1}"
            >
              <div class="text">
                {{prediction.structured_formatting.main_text}}
              </div>
                <div class="desc">
                {{prediction.structured_formatting.secondary_text}}
              </div>
            </div>
          </div>
        </div>
        <div v-if="modo=='TRANSIT'" class="mt-4">
          <div class="select-time">
            <v-select
              @input="setWhenToGo"
              :options="whenToGoOptions"
              :value="whenToGo"
              :searchable="false"
              :clearable="false"
              :reduce="selected => selected.value">
            </v-select>
          </div>
          <div v-if="whenToGo!='now'" class="fechahora">
            <div class="input-grp fecha">
              <span class="bcn-icon-calendari"></span>
              <datetime :placeholder="$t('date')" :value="transitDate" @input="setTransitDate" type="date" :phrases="dateButtons" format="dd/MM/yyyy"></datetime>
            </div>
            <div class="input-grp hora">
              <span class="bcn-icon-rellotge"></span>
              <datetime :placeholder="$t('time')"  :value="transitTime" @input="setTransitTime" type="time" :phrases="dateButtons" format="HH:mm" :title="$t('time')"></datetime>
            </div>
          </div>
        </div>
        <div class="car-options mt-4" v-if="modo=='DRIVING'">
          <button @click="togglePreferences()" :class="{ 'open': preferencesOpened }">{{$t('preferences')}}</button>
          <div class="preferences" v-if="preferencesOpened">
            <p-check class="p-icon" :value="avoidHighways" @change="setAvoidHighways">
              <i class="icon bcn-icon-ok-bold" slot="extra"></i>
              {{$t('avoid_highways')}}
            </p-check>
            <p-check class="p-icon" :value="avoidTolls" @change="setAvoidTolls">
              <i class="icon bcn-icon-ok-bold" slot="extra"></i>
              {{$t('avoid_tolls')}}
            </p-check>
          </div>
        </div>
        <div class="bot-planifica" @click="planRoute()">
          <button>{{$t('plan_route')}}</button>
        </div>
        <div class="bcn-icon-tancar-medium close-planificador" @click="planningClose()"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { clone, find } from 'lodash';
import { mapState, mapMutations } from 'vuex';
import ClickOutside from 'vue-click-outside';
import { app } from '../main';

export default {
  name: 'Planificador',
  directives: {
    ClickOutside,
  },
  data() {
    return {
      modos: [
        { icon: 'iconPie', modo: 'WALKING' },
        { icon: 'iconBici', modo: 'BICYCLING' },
        { icon: 'iconTren', modo: 'TRANSIT' },
        { icon: 'iconCoche', modo: 'DRIVING' },

      ],
      whenToGoOptions: [
        { label: this.$t('leave_now'), value: 'now' },
        { label: this.$t('leave_at'), value: 'leave' },
        { label: this.$t('arrive_at'), value: 'arrive' },
      ],
      dateButtons: { ok: 'Ok', cancel: this.$t('cancel') },
    };
  },
  computed: {
    ...mapState('incidents', [
      'trafficIncidents',
      'forceClose',
    ]),
    ...mapState('planroute', [
      'places',
      'modo',
      'displayMode',
      'whenToGo',
      'opened',
      'selectedLocation',
      'preferencesOpened',
      'fromInput',
      'toInput',
      'fromValue',
      'toValue',
      'fromObject',
      'toObject',
      'predictions',
      'suggestionsNoResults',
      'showResultsPanel',
      'routes',
      'directionsRenderer',
      'transitDate',
      'transitTime',
      'avoidTolls',
      'avoidHighways',
    ]),
    ...mapState('map', [
      'mapObj',
      'geolocationActive',
    ]),
    ...mapState('transport', [
      'preferencias',
    ]),
    ...mapState('menu', [
      'layerMenu',
    ]),
  },
  methods: {
    ...mapMutations({
      setLayerMenu: 'menu/setLayerMenu',
      setHelpOpened: 'help/setOpened',
    }),
    ...mapMutations({
      setPlaces: 'planroute/setPlaces',
      setModo: 'planroute/setModo',
      setDisplayMode: 'planroute/setDisplayMode',
      setWhenToGo: 'planroute/setWhenToGo',
      setOpened: 'planroute/setOpened',
      setSelectedLocation: 'planroute/setSelectedLocation',
      setPreferencesOpened: 'planroute/setPreferencesOpened',
      setFromInput: 'planroute/setFromInput',
      setToInput: 'planroute/setToInput',
      setFromValue: 'planroute/setFromValue',
      setToValue: 'planroute/setToValue',
      setFromObject: 'planroute/setFromObject',
      setToObject: 'planroute/setToObject',
      setPredictions: 'planroute/setPredictions',
      setSuggestionsNoResults: 'planroute/setSuggestionsNoResults',
      setShowResultsPanel: 'planroute/setShowResultsPanel',
      setRoutes: 'planroute/setRoutes',
      setDirectionsRenderer: 'planroute/setDirectionsRenderer',
      setTransitDate: 'planroute/setTransitDate',
      setTransitTime: 'planroute/setTransitTime',
      setAvoidTolls: 'planroute/setAvoidTolls',
      setAvoidHighways: 'planroute/setAvoidHighways',
      setPanelOpened: 'planroute/setPanelOpened',
      setTrafficIncidentsReaded: 'incidents/setTrafficIncidentsReaded',
      setTrafficIncidentsNew: 'incidents/setTrafficIncidentsNew',
      setTrafficIncidentsFirstRead: 'incidents/setTrafficIncidentsFirstRead',
      setTrafficIncidentsForceClose: 'incidents/setForceClose',
    }),
    changeModo(modo) {
      this.setModo(modo);
      const layerMenu = clone(this.layerMenu);
      let index;
      for (let c = 0; c < layerMenu.length; c++) {
        if (layerMenu[c].modo == this.modo) {
          index = c;
          break;
        }
      }
      layerMenu.splice(1, 0, layerMenu.splice(index, 1)[0]);
      this.setLayerMenu(layerMenu);
    },
    planningClose() {
      this.setOpened(false);
      this.elementsVisibility(true);
      if (this.selectedLocation !== null) {
        this.setDisplayMode('route');
      }
    },
    focusInput(input, focus) {
      this.setPredictions([]);
      if (input == 'fromInput') {
        this.setFromInput(focus);
        this.setToInput(false);
      } else {
        this.setFromInput(false);
        this.setToInput(focus);
      }
      this.setSuggestionsNoResults(false);
      this.setShowResultsPanel(true);
      if (focus) {
        this.elementsVisibility(false);
      }
    },
    clearInput(input) {
      if (input == 'from') {
        this.setFromValue('');
        this.setFromObject(null);
      } else {
        this.setToValue('');
        this.setToObject(null);
        this.setSelectedLocation(null);
      }
      this.setSuggestionsNoResults(false);
      this.closePanel();
    },
    labelAutocomplete(input) {
      return {
        focused: this[`${input}Input`],
        filling: this[`${input}Value`],
      };
    },
    hideResults(e) {
      if (e != undefined && $(e.target).closest('.results').length > 0) return;
      this.setFromInput(false);
      this.setToInput(false);
      this.setSuggestionsNoResults(false);
      this.elementsVisibility(true);
      this.setShowResultsPanel(false);
    },
    elementsVisibility(visible) {
      if (visible) {
        $('#barra-ajuntament').show();
        $('.buttons').show();
        $('.mainHeader').show();
        $('#mainMenu').show();
      } else {
        $('#barra-ajuntament').hide();
        $('.buttons').hide();
        $('.mainHeader').hide();
        $('#mainMenu').hide();
      }
    },
    swapFields() {
      const {
        fromValue,
        toValue,
        fromObject,
        toObject,
      } = this;
      this.setFromValue(toValue);
      this.setToValue(fromValue);
      this.setFromObject(toObject);
      this.setToObject(fromObject);
    },
    checkedModo(item) {
      return {
        checked: item.modo == this.modo,
      };
    },
    getClassButton(item, index) {
      let clase = item.icon;
      if (index == 0) clase += ' first';
      if (index == this.modos.length - 1) clase += ' last';
      return clase;
    },
    openPlanificador() {
      this.setOpened(true);
    },
    togglePlanificador() {
      this.setOpened(!this.opened);
      this.setDisplayMode('');
    },
    togglePreferences() {
      this.setPreferencesOpened(!this.preferencesOpened);
    },
    carOptionsChanged() {

    },
    getSuggestions(input, e) {
      const word = e.target.value;
      if (word == '') return;
      const googleRoutesApi = new GoogleRoutesApi(this.$i18n.locale);
      googleRoutesApi.getSuggestions(word).then((result) => {
        if (result.status != 'OK') {
          this.setSuggestionsNoResults(true);
        }
        this.setPredictions(result.predictions);
        this.setShowResultsPanel(true);
        if (input == 'from') {
          this.setFromValue(e.target.value);
        } else {
          this.setToValue(e.target.value);
        }
      });
    },
    selectPrediction(input, prediction) {
      if (input == 'from') {
        this.setFromValue(prediction.description);
        this.setFromObject(prediction);
      } else {
        this.setToValue(prediction.description);
        this.setToObject(prediction);
      }
      this.setPredictions([]);
      this.hideResults();
    },
    setStationDestination(linea, station) {
      const googleRoutesApi = new GoogleRoutesApi(this.$i18n.locale);
      const position = new google.maps.LatLng(station.geometry.coordinates[1], station.geometry.coordinates[0]);
      googleRoutesApi.geocode(position).then((results) => {
        const val = results[0];
        this.toValue = `${linea.linea} - ${station.nom}`;
        val.custom_text = {
          main_text: station.nom,
          secondary_text: `${linea.linea} - ${linea.descripcio}`,
        };
        this.toObject = val;
        this.selectedLocation = {
          icon: linea.icon,
          main_text: station.nom,
          secondary_text: `${linea.linea} - ${linea.descripcio}`,
        };
        this.$forceUpdate();
        this.hideResults();
      });
    },
    selectMyPos(input) {
      const googleRoutesApi = new GoogleRoutesApi(this.$i18n.locale);
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        googleRoutesApi.geocode(pos).then((results) => {
          const val = results[0];
          if (input == 'from') {
            this.setFromValue(val.formatted_address);
            this.setFromObject(val);
          } else {
            this.setToValue(val.formatted_address);
            this.setToObject(val);
          }
          this.$forceUpdate();
          this.hideResults();
        });
      });
    },
    selectSavedPlace(input, search) {
      const value = `${this.getPlaceText('main_text', search)} ${this.getPlaceText('secondary_text', search)}`;
      if (input == 'from') {
        this.setFromValue(value);
        this.setFromObject(search);
      } else {
        this.setToValue(value);
        this.setToObject(search);
      }
      this.hideResults();
    },
    getPlaceText(property, place) {
      let componentsObject = null;
      let addressText = null;
      if (property == 'main_text' && place.custom_text) {
        return place.custom_text.main_text;
      }

      if (property == 'secondary_text' && place.custom_text) {
        return place.custom_text.secondary_text;
      }
      if (property == 'main_text') {
        if (place.structured_formatting) {
          return place.structured_formatting.main_text;
        }
        componentsObject = GoogleRoutesApi.addressComponentObject(place.address_components);
        addressText = GoogleRoutesApi.getMainSecondaryAddresText(componentsObject);
        return addressText.main_text;
      }
      if (property == 'secondary_text') {
        if (place.structured_formatting) {
          return place.structured_formatting.secondary_text;
        }
        componentsObject = GoogleRoutesApi.addressComponentObject(place.address_components);
        addressText = GoogleRoutesApi.getMainSecondaryAddresText(componentsObject);
        return addressText.secondary_text;
      }
      return '';
    },
    planRoute() {
      if (this.toObject == null || this.fromObject == null) {
        return;
      }
      const googleRoutesApi = new GoogleRoutesApi(this.$i18n.locale);
      const options = {
        travelMode: this.modo,
      };

      if (this.modo == 'TRANSIT') {
        options.transitOptions = { modes: ['SUBWAY', 'BUS', 'RAIL', 'TRAIN', 'TRAM'] };
        if (this.preferencias.tipoRuta == 'FEWER_TRANSFERS' || this.preferencias.tipoRuta == 'LESS_WALKING') {
          options.transitOptions.routingPreference = this.preferencias.tipoRuta;
        }
        if (this.preferencias.transitModes.length > 0) {
          options.transitOptions.modes = this.preferencias.transitModes;
        }
      }
      if (this.modo == 'TRANSIT' && this.whenToGo != 'now') {
        const date = new Date(this.transitDate);
        const hour = new Date(this.transitTime).getHours();
        const minute = new Date(this.transitTime).getMinutes();
        date.setHours(hour);
        date.setMinutes(minute);
        if (this.whenToGo == 'leave') {
          options.transitOptions.departureTime = date;
        }
        if (this.whenToGo == 'arrive') {
          options.transitOptions.arrivalTime = date;
        }
      }
      if (this.modo == 'DRIVING') {
        if (this.avoidHighways) options.avoidHighways = true;
        if (this.avoidTolls) options.avoidTolls = true;
      }
      googleRoutesApi.route(this.fromObject.place_id, this.toObject.place_id, options).then((response, status) => {
        response.routes.forEach((route) => {
          route.travelMode = this.modo;
        });
        this.setRoutes(response);
        if (this.directionsRenderer != null) {
          this.directionsRenderer.setMap(null);
          this.setDirectionsRenderer(null);
        }
        if (response.status == 'OK') {
          GoogleRoutesApi.savePlace(this.fromObject);
          GoogleRoutesApi.savePlace(this.toObject);
          this.setDisplayMode('route');
          this.setOpened(false);
          if (this.modo == 'TRANSIT' && this.preferencias.tipoRuta == 'ACCESSIBLE-ROUTES') {
            GoogleRoutesApi.orderByAccessibily(response).then((response) => {
              app.$children[0].$refs.routePanel.setRoutes(response);
            });
          } else if (this.modo !== 'DRIVING') {
            app.$children[0].$refs.routePanel.setRoutes(response);
          }
          this.setSelectedLocation({
            main_text: this.getPlaceText('main_text', this.toObject),
            secondary_text: this.getPlaceText('secondary_text', this.toObject),
          });
          const bicycleGroup = find(this.layerMenu, (g) => { return g.code == 'bici'; });
          const cycleLaneGroup = find(bicycleGroup.options, (g) => { return g.code == 'carrilbicigrupo'; });
          const cycleLaneLayer = find(cycleLaneGroup.options, (o) => { return o.code == 'carrilsbici'; });
          if (this.modo == 'BICYCLING') {
            cycleLaneLayer.selected = false;
          } else {
            cycleLaneLayer.selected = true;
          }
          this.$root.$children[0].$refs.layerMenu.selectOpcion(cycleLaneLayer);
          if (this.modo !== 'DRIVING') {
            this.setDirectionsRenderer(new google.maps.DirectionsRenderer());
            this.directionsRenderer.setMap(this.mapObj);
            this.directionsRenderer.setDirections(response);
          } else {
            this.getSostenibleRoute(response);
          }
        }
        if (response.status != 'OK') {
          this.$swal(this.$t('no_routes_available'), '', 'warning');
        }
      });
    },
    getSostenibleRoute(carResponse) {
      const googleRoutesApi = new GoogleRoutesApi(this.$i18n.locale);
      const options = {
        travelMode: 'TRANSIT',
      };
      googleRoutesApi.route(this.fromObject.place_id, this.toObject.place_id, options).then((response, status) => {
        if (response.status == 'OK') {
          carResponse.routes.unshift(this.getFirstRouteWithTransit(response.routes));
          this.setDirectionsRenderer(new google.maps.DirectionsRenderer());
          this.directionsRenderer.setMap(this.mapObj);
          this.directionsRenderer.setDirections(carResponse);
          app.$children[0].$refs.routePanel.setRoutes(carResponse);
        }
      });
    },
    getFirstRouteWithTransit(routes) {
      let routeWithTransit = null;
      routes.forEach((route) => {
        const { steps } = route.legs[0];
        steps.forEach((step) => {
          if (step.travel_mode == 'TRANSIT') {
            routeWithTransit = route;
            routeWithTransit.travelMode = 'TRANSIT';
          }
        });
      });
      return routeWithTransit;
    },
    closePanel() {
      this.setPanelOpened(false);
    },
    resetPlanificador(response) {
      this.setOpened(false);
      this.setSelectedLocation(null);
      this.setPreferencesOpened(false);
      this.setFromInput(false);
      this.setToInput(false);
      this.setFromValue('');
      this.setToValue('');
      this.setFromObject(null);
      this.setToObject(null);
      $('.map-buttons').css({ 'margin-bottom': 0 });
    },
    openPlan(origin, destination, origin_txt, destination_txt, travelMode) {
      this.changeModo(travelMode);
      this.setOpened(true);
      const googleRoutesApi = new GoogleRoutesApi(this.$i18n.locale);
      if (origin) {
        const posOrigin = {
          lat: parseFloat(origin.split(',')[0]),
          lng: parseFloat(origin.split(',')[1]),
        };
        googleRoutesApi.geocode(posOrigin).then((results) => {
          const val = results[0];
          this.setFromValue(origin_txt);
          val.custom_text = {
            main_text: origin_txt,
            secondary_text: `${val.formatted_address}`,
          };
          if (val.custom_text.main_text == val.custom_text.secondary_text) {
            delete val.custom_text;
            val.custom_text = {
              main_text: this.getPlaceText('main_text', val),
              secondary_text: this.getPlaceText('secondary_text', val),
            };
          }
          this.setFromObject(val);
        });
      }
      if (destination) {
        const posDestination = {
          lat: parseFloat(destination.split(',')[0]),
          lng: parseFloat(destination.split(',')[1]),
        };
        googleRoutesApi.geocode(posDestination).then((results) => {
          const val = results[0];
          val.custom_text = {
            main_text: destination_txt,
            secondary_text: `${val.formatted_address}`,
          };
          if (val.custom_text.main_text == val.custom_text.secondary_text) {
            delete val.custom_text;
            val.custom_text = {
              main_text: this.getPlaceText('main_text', val),
              secondary_text: this.getPlaceText('secondary_text', val),
            };
          }
          this.setToValue(destination_txt);
          this.setToObject(val);
        });
      }
      this.$forceUpdate();
      this.hideResults();
    },
  },
  created() {
    if (this.$route.query.destination != undefined) {
      this.setTrafficIncidentsReaded(true);
      this.setTrafficIncidentsNew(0);
      this.setTrafficIncidentsFirstRead(false);
      this.setHelpOpened(false);
      this.setTrafficIncidentsForceClose(true);
      const travelMode = (this.$route.query.travelMode) ? this.$route.query.travelMode : 'TRANSIT';
      this.openPlan(
        this.$route.query.origin,
        this.$route.query.destination,
        this.$route.query.origin_txt,
        this.$route.query.destination_txt,
        travelMode,
      );
    }
    if (find(this.modos, (o) => { return o.modo == this.preferencias.tipoRuta; })) {
      this.setModo(this.preferencias.tipoRuta);
    }
  },
};
</script>
