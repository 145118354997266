<template>
  <div class="map-component">
    <div id="map-canvas">
    </div>
    <div class="map-buttons">
      <div class="map-button-geoposition" @click="goToMyPosClick" :class="geolocationActiveClass"></div>
      <div class="map-button-menu" @click="openLayerMenu" :class="layersActive"></div>
    </div>
  </div>
</template>

<script>
import { find, findIndex, cloneDeep } from 'lodash';
import { mapState, mapMutations } from 'vuex';
import { gmapApi } from 'vue2-google-maps';
import { MapMixins } from './mixins/MapMixins';
import { LayerMixins } from './mixins/LayerMixins';

export default {
  name: 'Mapa',
  mixins: [
    MapMixins,
    LayerMixins,
  ],
  data() {
    return {
      mapOptions: null,
    };
  },
  computed: {
    google: gmapApi,
    layersActive() {
      return {
        'text-link': this.activeLayers.length > 0,
      };
    },
    geolocationActiveClass() {
      return {
        'text-link': this.geolocationActive,
      };
    },
    ...mapState('menu', [
      'layerMenuStatus',
      'layerMenu',
    ]),
    ...mapState('map', [
      'mapObj',
      'mapFullScreen',
      'currentPos',
      'markerCurrentPos',
      'geolocationActive',
      'activeLayers',
    ]),
    ...mapState('planroute', {
      planificadorOpened: 'opened',
    }),
  },
  methods: {
    ...mapMutations({
      setMap: 'map/setMap',
      setCurrentPos: 'map/setCurrentPos',
      setMarkerCurrentPos: 'map/setMarkerCurrentPos',
      setGeolocationActive: 'map/setGeolocationActive',
      setMapFullScreen: 'map/setMapFullScreen',
      setLayerMenuStatus: 'menu/setLayerMenuStatus',
      setPlanificadorOpened: 'planroute/setOpened',
    }),
    openLayerMenu() {
      this.setLayerMenuStatus({
        opened: true,
      });
      this.$root.$children[0].$refs.planificador.closePanel();
    },
    loadMap() {
      const generalStyles = [
        {
          featureType: 'poi.business',
          elementType: 'all',
          stylers: [{
            visibility: 'off',
          }],
        },
        {
          featureType: 'poi.attraction',
          elementType: 'all',
          stylers: [{
            visibility: 'off',
          }],
        },
        {
          featureType: 'poi.government',
          elementType: 'all',
          stylers: [{
            visibility: 'off',
          }],
        },
        {
          featureType: 'transit',
          elementType: 'all',
          stylers: [{
            visibility: 'off',
          }],
        },
      ];

      this.mapOptions = {
        center: this.currentPos,
        zoom: 17,
        mapTypeControl: false,
        zoomControl: false,
        streetViewControl: false,
        disableDefaultUI: true,
        gestureHandling: 'greedy',
        styles: generalStyles,
      };

      const mapObj = new google.maps.Map(document.getElementById('map-canvas'), this.mapOptions);
      this.setMap(mapObj);
      google.maps.event.addListener(this.mapObj, 'click', (e) => {
        if (e.placeId !== undefined) return;
        if (this.planificadorOpened) {
          this.setPlanificadorOpened(false);
          return;
        }
        this.setMapFullScreen(!this.mapFullScreen);
      });
      google.maps.event.addListener(this.mapObj, 'zoom_changed', (e) => {
        if (findIndex(this.activeLayers, (o) => o.code == 'near_public_transport') !== -1) {
          this.markerVisibility();
          this.loadNearTransport('near_public_transport');
        }
        if (findIndex(this.activeLayers, (o) => o.code == 'transport_accessible') !== -1) {
          this.markerVisibility();
        }
      });
      google.maps.event.addListener(this.mapObj, 'dragend', (e) => {
        if (findIndex(this.activeLayers, (o) => o.code == 'near_public_transport') !== -1) {
          this.loadNearTransport('near_public_transport');
        }
        if (findIndex(this.activeLayers, (o) => o.code == 'transport_accessible') !== -1) {
          this.loadNearTransport('transport_accessible');
        }
      });
      google.maps.event.addListenerOnce(this.mapObj, 'idle', () => {
        const optionNearTransport = find(this.layerMenu[0].options, (o) => { return o.code == 'near_public_transport'; });
        this.$root.$children[0].$refs.layerMenu.selectOpcion(optionNearTransport);
        this.goToMyPos();
      });
      google.maps.event.addListenerOnce(this.mapObj, 'center_changed', () => {
        if (findIndex(this.activeLayers, (o) => o.code == 'near_public_transport') < 0) {
          return;
        }
        const interestGroup = find(this.layerMenu, (o) => { return o.code == 'interes-general'; });
        const optionAccessible = find(interestGroup.options, (o) => { return o.code == 'transport_accessible'; });
        this.selectOpcion(optionAccessible);
        this.loadNearTransport('transport_accessible');
        this.loadHighlights();
      });
    },
    loadMarkerCurrentPos() {
      if (this.markerCurrentPos != null) {
        this.markerCurrentPos.setMap(null);
      }
      const icon = {
        url: require('@/assets/img/current-pos.png'),
      };
      this.setMarkerCurrentPos(new google.maps.Marker({
        position: this.currentPos,
        icon,
        map: this.mapObj,
      }));
      this.markerCurrentPos.setMap(this.mapObj);
    },
    goToMyPosClick() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.goToMyPos();
        }, (error) => {
          this.$swal(this.$t('geolocation_msg'), '', 'warning');
          this.setGeolocationActive(false);
        });
      }
    },
    goToMyPos() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.setCurrentPos(pos);
          this.setGeolocationActive(true);
          this.mapObj.setCenter({ lat: pos.lat, lng: pos.lng });
          this.mapObj.setZoom(17);
          this.loadMarkerCurrentPos();
          this.loadNearTransport();
        }, (error) => {
          this.setGeolocationActive(false);
          this.loadNearTransport();
        });
      } else {
        this.setGeolocationActive(false);
        this.loadNearTransport();
      }
    },
    mapClicked() {
      this.setMapFullScreen(!this.mapFullScreen);
    },
  },
  mounted() {
    this.loadMap();
  },
};
</script>
