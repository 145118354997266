<template>
  <div class="interior">
    Contenido interior
  </div>
</template>

<script>
export default {
  name: 'Interior',
  methods: {
    changeTitulo() {
      this.$root.titulo = 'changeTitulo Interior';
    },
  },
  mounted() {
    this.changeTitulo();
  },
};
</script>
