import { mapState, mapMutations } from 'vuex';
import { clone, filter } from 'lodash';
import '../../classes/LineaTransportAerobus';
import '../../classes/LineaTransportBus';
import '../../classes/LineaTransportCercanias';
import '../../classes/LineaTransportFerrocarril';
import '../../classes/LineaTransportFunicular';
import '../../classes/LineaTransportMetro';
import '../../classes/LineaTransportTram';
import '../../classes/LineaTransportBusNorth';
import store from '../../store';

export const TransportMixins = {
  computed: {
    ...mapState('transport', [
      'lineaSelected',
    ]),
    ...mapState('planroute', {
      panelOpened: 'panelOpened',
      panelVisible: 'panelVisible',
    }),
    ...mapState('menu', [
      'mainMenuStatus',
      'layerMenuStatus',
    ]),
    menuHeight() {
      const height = window.innerHeight - window.app.$children[0].$refs.barraAjuntament.$el.clientHeight;
      return { height: `${height}px` };
    },
    level2Height() {
      const height = window.innerHeight - window.app.$children[0].$refs.barraAjuntament.$el.clientHeight;
      return { height: `${height}px` };
    },
  },
  methods: {
    ...mapMutations({
      setLineaSelected: 'transport/setLineaSelected',
      setPanelOpened: 'planroute/setPanelOpened',
      setPanelVisible: 'planroute/setPanelVisible',
      setMainMenuStatus: 'menu/setMainMenuStatus',
      setActiveLayers: 'map/setActiveLayers',
    }),
    selectLineaMetro(linea) {
      const lineaTransportMetro = new LineaTransportMetro();
      lineaTransportMetro.loadStation(linea.linea).then(() => {
        this.setLineaSelected(LineaTransportMetro.getLinea(linea.linea));
      });
    },

    selectLineaBus(linea) {
      const lineaTransportBus = new LineaTransportBus();
      lineaTransportBus.loadStation(linea.linea).then(() => {
        this.setLineaSelected(LineaTransportBus.getLinea(linea.linea));
      });
    },

    selectLineaBusNorth(linea) {
      const lineaTransportBusNorth = new LineaTransportBusNorth();
      lineaTransportBusNorth.loadStation(linea.id).then(() => {
        this.setLineaSelected(LineaTransportBusNorth.getLinea(linea.id));
      });
    },

    selectLineaAerobus(linea) {
      const lineaTransportAerobus = new LineaTransportAerobus();
      lineaTransportAerobus.loadStation(linea.linea).then(() => {
        this.setLineaSelected(LineaTransportAerobus.getLinea(linea.linea));
      });
    },

    selectLineaRodalies(linea) {
      const lineaTransportCercanias = new LineaTransportCercanias();
      lineaTransportCercanias.loadStation(linea.linea).then(() => {
        this.setLineaSelected(LineaTransportCercanias.getLinea(linea.linea));
      });
    },

    selectLineaFerrocarril(linea) {
      const lineaTransportFerrocarril = new LineaTransportFerrocarril();
      lineaTransportFerrocarril.loadStation(linea.linea).then(() => {
        this.setLineaSelected(LineaTransportFerrocarril.getLinea(linea.linea));
      });
    },

    selectLineaTram(linea) {
      const lineaTransportTram = new LineaTransportTram();
      lineaTransportTram.loadStation(linea.linea).then(() => {
        this.setLineaSelected(LineaTransportTram.getLinea(linea.linea));
      });
    },

    selectLineaFunicular(linea) {
      const lineaTransportFunicular = new LineaTransportFunicular();
      lineaTransportFunicular.loadStation(linea.linea).then(() => {
        this.setLineaSelected(LineaTransportFunicular.getLinea(linea.linea));
      });
    },

    deselectLine() {
      this.setLineaSelected('');
    },

    getLinea(tipo, name) {
      if (tipo == 'metro') {
        return LineaTransportMetro.getLinea(name);
      }
      if (tipo == 'bus') {
        return LineaTransportBus.getLinea(name);
      }
      if (tipo == 'ferrocarril') {
        return LineaTransportFerrocarril.getLinea(name);
      }
      if (tipo == 'rodalies') {
        return LineaTransportCercanias.getLinea(name);
      }
    },

    getColor(linea) {
      if (linea.type === 'metro') {
        return LineaTransportMetro.getColor(linea.linea);
      }
      if (linea.type === 'bus') {
        return LineaTransportBus.getColor(linea.linea);
      }
      return '';
    },

    loadLineMap(tipo, linea) {
      DataLayer.hideAllDataLayers();
      this.cleanActiveLayers(false);
      if (tipo == 'metro') {
        LineaTransportMetro.loadLineMap(linea, 'line');
      } else if (tipo == 'bus') {
        LineaTransportBus.loadLineMap(linea, 'line');
      } else if (tipo == 'aerobus') {
        LineaTransportAerobus.loadLineMap(linea, 'line');
      } else if (tipo == 'tram') {
        LineaTransportTram.loadLineMap(linea, 'line');
      } else if (tipo == 'funicular') {
        LineaTransportFunicular.loadLineMap(linea, 'line');
      } else if (tipo == 'rodalies') {
        LineaTransportCercanias.loadLineMap(linea, 'line');
      } else if (tipo == 'ferrocarril') {
        LineaTransportFerrocarril.loadLineMap(linea, 'line');
      } else if (tipo == 'busnorth') {
        LineaTransportBusNorth.loadLineMap(linea, 'line');
      }
    },

    loadStationMap(tipo, linea_name, station_name) {
      let TransportClass = null;
      DataLayer.hideAllDataLayers();
      this.cleanActiveLayers(false);
      this.selectAllTransport(false);
      switch (tipo) {
        case 'metro':
          TransportClass = LineaTransportMetro;
          break;
        case 'bus':
          TransportClass = LineaTransportBus;
          break;
        case 'aerobus':
          TransportClass = LineaTransportAerobus;
          break;
        case 'funicular':
          TransportClass = LineaTransportFunicular;
          break;
        case 'tram':
          TransportClass = LineaTransportTram;
          break;
        case 'rodalies':
          TransportClass = LineaTransportCercanias;
          break;
        case 'ferrocarril':
          TransportClass = LineaTransportFerrocarril;
          break;
        case 'busnorth':
          TransportClass = LineaTransportBusNorth;
          break;
        default:
          break;
      }
      const linea = TransportClass.getLinea(linea_name);
      const station = TransportClass.getStation(linea, station_name);
      if (tipo == 'busnorth') {
        window.app.$children[0].$refs.infoPanel.initInfoPanel();
        store.commit('infopanel/setInfoPanel', {
          title: station.nom,
          linea: linea.linea,
          nom_linea: linea.descripcion,
          type: 'busnorth',
          visible: true,
          feature: new google.maps.Data.Feature({}),
          bustimes: station.times,
          datos: [],
        });
        return;
      }
      const lineaTransport = new TransportClass();

      if (station == null || station.code == undefined) {
        lineaTransport.loadStation(linea_name).then(() => {
          TransportClass.loadLineMap(linea_name, 'station', station_name);
        });
      } else {
        window.app.$children[0].$refs.planificador.setStationDestination(linea, station);
        TransportClass.loadLineMap(linea_name, 'station', station_name);
      }
      window.app.$children[0].$refs.planificador.resetPlanificador();
      this.setPanelVisible(false);
      this.setPanelOpened(false);
      if (window.app.$children[0].$refs.mainMenu.$refs.mainmenuSearch) {
        window.app.$children[0].$refs.mainMenu.$refs.mainmenuSearch.cancelSearch();
      }
      if (window.app.$children[0].$refs.layerMenu.$refs.layermenuSearch) {
        window.app.$children[0].$refs.layerMenu.$refs.layermenuSearch.cancelSearch();
      }
    },

    selectAllTransport(selected) {
      this.selectLineAll('metro', selected);
      this.selectLineAll('bus', selected);
      this.selectLineAll('ferrocarril', selected);
      this.selectLineAll('rodalies', selected);
      this.selectLineAll('tram', selected);
      this.selectLineAll('aerobus', selected);
      this.selectLineAll('funicular', selected);
    },
    selectLineAll(type, selected) {
      for (const c in this.lineas[type]) {
        this.lineas[type][c].selected = selected;
        this.selectTransportLine(this.lineas[type][c]);
      }
    },
    selectTransportLine(linea, selected) {
      if (selected != undefined) {
        linea.selected = selected;
      }
      let TransportClass = null;
      let layerLineName = '';
      let layerStationsName = '';
      switch (linea.type) {
        case 'metro':
          TransportClass = LineaTransportMetro;
          layerLineName = 'metrolinies';
          layerStationsName = 'metroestacions';
          break;
        case 'bus':
          TransportClass = LineaTransportBus;
          layerLineName = 'buslinies';
          layerStationsName = 'busparades';
          break;
        case 'ferrocarril':
          TransportClass = LineaTransportFerrocarril;
          layerLineName = 'ferrocarrilslinies';
          layerStationsName = 'ferrocarrilsestacions';
          break;
        case 'rodalies':
          TransportClass = LineaTransportCercanias;
          layerLineName = 'rodalieslinies';
          layerStationsName = 'rodaliesestacions';
          break;
        case 'tram':
          TransportClass = LineaTransportTram;
          layerLineName = 'tramlinies';
          layerStationsName = 'tramestacions';
          break;
        case 'aerobus':
          TransportClass = LineaTransportAerobus;
          layerLineName = 'otherbuslinies';
          layerStationsName = 'otherbusparades';
          break;
        case 'funicular':
          TransportClass = LineaTransportFunicular;
          layerLineName = 'funicularlinies';
          layerStationsName = 'funicularparades';
          break;
        default:
          break;
      }
      if (linea.selected) {
        TransportClass.loadLineMap(linea.linea);
      } else {
        const layers = clone(this.activeLayers);
        layers.splice(layers.indexOf(linea), 1);
        this.setActiveLayers(layers);
        let dataLayer = DataLayer.getDataLayer(`${layerLineName}${linea.linea}`);
        if (dataLayer !== undefined) {
          dataLayer.hide();
          dataLayer.visible = false;
          dataLayer = DataLayer.getDataLayer(`${layerStationsName}${linea.linea}`);
          dataLayer.hide();
          dataLayer.visible = false;
          if (linea.type == 'metro') {
            dataLayer = DataLayer.getDataLayer(`${layerStationsName}${linea.linea}_accessos`);
            dataLayer.hide();
            dataLayer.visible = false;
          }
        }
      }
    },
    getBusTuristicLines(buslines) {
      const touristic_bus = filter(buslines, (o) => { return o.linea == '101' || o.linea == '100'; });
      return touristic_bus;
    },
    getScheduleUrl(line) {
      if (line.type == 'metro') {
        window.open(`https://www.tmb.cat/${this.$i18n.locale}/barcelona/metro/-/lineametro/${line.linea}`, '_blank');
      } else if (line.type == 'bus') {
        window.open(`https://www.tmb.cat/${this.$i18n.locale}/barcelona/autobuses/-/lineabus/${line.linea}`, '_blank');
      } else if (line.type == 'tram') {
        window.open(`https://www.tram.cat/${this.$i18n.locale}/linies-i-horaris`, '_blank');
      } else if (line.type == 'aerobus') {
        window.open(`https://www.aerobusbcn.com/${this.$i18n.locale}/horarios_covid`, '_blank');
      } else if (line.type == 'funicular') {
        window.open(`https://www.tmb.cat/${this.$i18n.locale}/sobre-tmb/transportes-tmb/funicular-montjuic`, '_blank');
      }
    },
  },
};

export const dummy = {};
