import { sortBy } from 'lodash';
import store from '../store/index';
import { getApiUri } from '../api/util';
import api from '../api/api';

window.GoogleRoutesApi = function (language) {
  this.language = language;
};

GoogleRoutesApi.prototype.getSuggestions = function (word) {
  var target = this;
  var promise = new Promise((resolve, reject) => {
    var service = new google.maps.places.AutocompleteService();
    var request = {
      language: target.language,
      input: word,
      componentRestrictions: {
        country: 'es',
      },
    };
    service.getPlacePredictions(request, (predictions, status) => {
      var result = {
        predictions,
        status,
      };
      resolve(result);
    });
  });
  return promise;
};

GoogleRoutesApi.prototype.route = function (origin, destination, options) {
  var promise = new Promise(((resolve, reject) => {
    var directionsService = new google.maps.DirectionsService();
    options.origin = { placeId: origin };
    options.destination = { placeId: destination };
    options.region = 'es';
    options.provideRouteAlternatives = true;
    directionsService.route(options, (response) => {
      resolve(response);
    });
  }));
  return promise;
};

GoogleRoutesApi.prototype.geocode = function (latlng) {
  var promise = new Promise(((resolve, reject) => {
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK') {
        resolve(results);
      } else {
        reject(status);
      }
    });
  }));
  return promise;
};

GoogleRoutesApi.savePlace = function (place) {
  var exists = false;
  for (var c in store.state.planroute.places) {
    var storePlace = store.state.planroute.places[c];
    if (place.place_id == storePlace.place_id) {
      exists = true;
      break;
    }
  }
  if (!exists) {
    const { places } = store.state.planroute;
    places.unshift(place);
    store.commit('planroute/setPlaces', places);
  }
};

GoogleRoutesApi.addressComponentObject = function (address_components) {
  var componentsObject = {};
  $.each(address_components, (k, v1) => { $.each(v1.types, (k2, v2) => { componentsObject[v2] = v1.long_name; }); });
  return componentsObject;
};

GoogleRoutesApi.getMainSecondaryAddresText = function (componentsObject) {
  var main_text = componentsObject.route;
  if (main_text === undefined) {
    main_text = componentsObject.establishment;
  }
  if (main_text === undefined) {
    main_text = componentsObject.transit_station;
  }
  if (main_text === undefined) {
    main_text = componentsObject.point_of_interest;
  }
  if (componentsObject.street_number) main_text = `${main_text}, ${componentsObject.street_number}`;

  var secondaryParts = [];
  if (componentsObject.locality) secondaryParts.push(componentsObject.locality);
  if (componentsObject.administrative_area_level_2 && componentsObject.administrative_area_level_2 != componentsObject.locality) secondaryParts.push(componentsObject.locality);
  if (componentsObject.postal_code) secondaryParts.push(componentsObject.postal_code);
  if (componentsObject.country) secondaryParts.push(componentsObject.country);
  const secondary_text = secondaryParts.join(', ');
  var texts = {
    main_text,
    secondary_text,
  };
  return texts;
};

GoogleRoutesApi.orderByAccessibily = function (response) {
  const promise = new Promise((resolve) => {
    for (const c in response.routes) {
      const route = response.routes[c];
      route.unaccesible = 0;
      for (const c in route.legs[0].steps) {
        const step = route.legs[0].steps[c];
        if (step.travel_mode == 'TRANSIT') {
          const departure_stop = GoogleRoutesApi.clearStationName(step.transit.departure_stop.name);
          const arrival_stop = GoogleRoutesApi.clearStationName(step.transit.arrival_stop.name);
          const { line } = step.transit;
          const departure_params = [
            'type=metroestacionstmb',
            `line=${line.short_name}`,
            `station=${departure_stop}`,
          ];
          const arrival_params = [
            'type=metroestacionstmb',
            `line=${line.short_name}`,
            `station=${arrival_stop}`,
          ];
          Promise.all([
            api.get(`${getApiUri()}search/station/?${departure_params.join('&')}`),
            api.get(`${getApiUri()}search/station/?${arrival_params.join('&')}`),
          ]).then(([
            departure_data,
            arrival_data,
          ]) => {
            if (departure_data.data.results.length > 0) {
              const accessibility = departure_data.data.results[0].properties.NOM_TIPUS_ACCESSIBILITAT;
              if (accessibility == 'No accessible') {
                route.unaccesible += 1;
              }
            }
            if (arrival_data.data.results.length > 0) {
              const accessibility = arrival_data.data.results[0].properties.NOM_TIPUS_ACCESSIBILITAT;
              if (accessibility == 'No accessible') {
                route.unaccesible += 1;
              }
            }
            const ordered = sortBy(response.routes, [(o) => {
              return o.unaccesible;
            }]);
            response.routes = ordered;
            resolve(response);
          });
        }
      }
    }
  });
  return promise;
};

GoogleRoutesApi.clearStationName = function (station_name) {
  station_name = station_name.replace('Pl. ', '');
  return station_name;
};
