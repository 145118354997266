const state = () => ({
  activeSearch: false,
  linesSearchWord: '',
  searchResults: {
    metro: {
      lines: [],
      stations: [],
    },
    bus: {
      lines: [],
      stations: [],
    },
    rodalies: {
      lines: [],
      stations: [],
    },
    ferrocarril: {
      lines: [],
      stations: [],
    },
    tram: {
      stations: [],
    },
  },
});

const mutations = {
  setLinesSearchWord(state, linesSearchWord) {
    state.linesSearchWord = linesSearchWord;
  },

  setSearchResults(state, searchResults) {
    state.searchResults = searchResults;
  },

  setActiveSearch(state, activeSearch) {
    state.activeSearch = activeSearch;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
