<template>
  <div id="routePanelWrapper" v-show="panelVisible()">
    <div @click="togglePanel" v-touch:swipe.top="onSwipeUp" v-touch:swipe.down="onSwipeDown" class="swipeZone">
      <div class="swipeHelper">
        <div class="helper"></div>
      </div>
    </div>
    <div class="routePanelContainer" :class="{'opened': panelTop}">
      <div id="routePanel" v-if="routes && routes.length>0">
        <div class="suggested-route">
          <route-component
            :route="routes[0]"
            :routeIndex="0"
            :travelMode="getTravelMode(0)"
            :type="getType(0)"
            :title="getTitle(0)"
            :suggestedRoute="routes[1]"
          >
          </route-component>
          <hr v-if="routes.length>1" class="route-separator">
        </div>
        <div class="route other-routes">
          <div v-for="(route, index) in routes.slice(1)" v-bind:key="index">
            <route-component
              :route="route"
              :routeIndex="index+1"
              :travelMode="getTravelMode(index+1)"
              :title="getTitle(index+1)"
              type="suggested"
            >
            </route-component>
            <hr v-if="index<routes.length-2" class="route-separator">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import RouteComponent from './RouteComponent.vue';

export default {
  name: 'RoutePanel',
  components: {
    RouteComponent,
  },
  computed: {
    ...mapState('map', [
      'mapObj',
      'mapFullScreen',
    ]),
    ...mapState('planroute', {
      routes: 'routes',
      planificadorOpened: 'opened',
      opened: 'panelOpened',
      visible: 'panelVisible',
      panelTop: 'panelTop',
      travelMode: 'panelTravelMode',
      selectedLocation: 'selectedLocation',
      suggestedRoute: 'suggestedRoute',
    }),
  },
  methods: {
    ...mapMutations({
      setStoreRoutes: 'planroute/setRoutes',
      setTravelMode: 'planroute/setPanelTravelMode',
      setSuggestedRoute: 'planroute/setSuggestedRoute',
      setOpened: 'planroute/setPanelOpened',
      setVisible: 'planroute/setPanelVisible',
      setPanelTop: 'planroute/setPanelTop',
    }),
    panelVisible() {
      if (this.visible && !this.mapFullScreen && !this.planificadorOpened && this.selectedLocation != null) {
        return true;
      }
      return false;
    },
    setRoutes(response) {
      this.setTravelMode(response.request.travelMode);
      this.setStoreRoutes(response.routes);
      this.setSuggestedRoute(null);
      this.setOpened(false);
      this.setPanelTop(false);
      this.setVisible(true);
      this.setPanelHeight();
      this.$nextTick(() => {
        this.posPanel();
      });
    },
    getTravelMode(routeIndex) {
      return this.routes[routeIndex].travelMode;
    },
    getType(routeIndex) {
      if (this.travelMode == 'DRIVING' && routeIndex == 0 && this.routes[routeIndex].travelMode == 'TRANSIT') {
        return 'sostenible';
      }
      return 'suggested';
    },
    getTitle(routeIndex) {
      if (this.travelMode == 'DRIVING' && routeIndex == 0 && this.routes[routeIndex].travelMode == 'TRANSIT') {
        return this.$t('sustainable_route');
      }
      if ((this.travelMode == 'DRIVING' && routeIndex == 1 && this.routes[0].travelMode == 'TRANSIT')
        || (routeIndex == 0)) {
        return this.$t('suggested_route');
      }
      return this.$t('other_routes');
    },
    togglePanel() {
      if (this.opened) {
        this.onSwipeDown();
      } else {
        this.onSwipeUp();
      }
    },
    onSwipeUp() {
      if (this.panelTop) return false;
      $('#routePanelWrapper').animate(
        {
          top: '150px',
          marginTop: 0,
        },
        {
          complete: () => {
            this.setOpened(true);
          },
        },
      );
      this.setPanelTop(true);
      this.setPanelHeight();
    },
    onSwipeDown() {
      if (!this.panelTop) {
        const top = window.innerHeight - 65;
        $('#routePanelWrapper').animate(
          {
            top: `${top}px`,
            marginTop: 0,
          },
          {
            complete: () => {
              $('.map-buttons').css({ 'margin-bottom': 0 });
            },
          },
        );
      } else {
        this.posPanel();
        this.setOpened(false);
        this.setPanelTop(false);
        this.setPanelHeight();
      }
    },
    initPanel() {
      this.posPanel();
      this.setPanelHeight();
    },
    posPanel() {
      let top = document.getElementById('map-canvas').clientHeight;
      top += document.getElementById('planificador').clientHeight + 50;
      let marginTop = -document.getElementById('routeHeader_0').clientHeight - 35;
      if (this.travelMode == 'DRIVING') {
        marginTop -= document.getElementById('routeHeader_1').clientHeight + 35;
      }
      $('#routePanelWrapper').animate(
        {
          top: `${top}px`,
          marginTop: `${marginTop}px`,
        },
        {
          complete: () => {
            $('.map-buttons').css({ 'margin-bottom': `${-marginTop - 35}px` });
          },
        },
      );
      $('.routePanelContainer').scrollTop(0);
    },
    closePanel() {
      this.$root.$children[0].$refs.planificador.closePanel();
    },
    setPanelHeight() {
      $('.routePanelContainer').css('height', `${document.getElementById('map-canvas').clientHeight}px`);
    },
  },
};
</script>
