import axios from 'axios';

export default axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const getToken = (() => {
  return new Promise((resolve) => {
    axios.get(`${process.env.VUE_APP_API_URL}servicios/token`).then((response) => {
      const expires_in = (response.data.expires_in !== undefined) ? response.data.expires_in : 7200;
      const now = Math.floor(Date.now() / 1000);
      const token = {
        access_token: response.data.access_token,
        expires_in,
        expires_timestamp: now + expires_in,
        token_type: response.data.token_type,
      };
      resolve(token);
    });
  });
});
