export default {
  ca: {
    // General
    loader_message: 'La informació<br>s\'està carregant.<br>Espereu un moment.',
    cancel: 'Cancel·la',
    link_contact: 'https://w10.bcn.cat/APPS/irsconsultesWeb/continuar.executant.do?i=c&origen=WEB_BARCELONA_CAT&tema=81&element=1121',
    link_accessibility: 'https://www.barcelona.cat/ca/accessibilitat',
    link_legal: 'https://www.barcelona.cat/ca/avis-legal',
    yes: 'Si',
    geolocation_msg: 'No s\'ha pogut determinar la seva localització. Ha de tenir activada la geolocalització al seu dispositiu mòbil i autoritzar al Com s\'hi va per ubicar-li.',
    // Transports
    metro: 'Metro',
    bus: 'Bus',
    local_trains: 'Rodalies',
    fgc_trains: 'Ferrocarrils',
    trams: 'Tramvia',
    funicular: 'Funicular',
    aerobus: 'Aerobús',
    tourist_bus: 'Bus turístic',
    // Main Menu
    route_preferences: 'Preferències de ruta',
    hello_barcelona_travel_card: 'Hola Barcelona Travel Card',
    go_to_the_airport: 'Anar a l\'aeroport',
    beyond_barcelona: 'Més enllà de Barcelona',
    maps_and_lines: 'Mapes i línies',
    traffic_incidents: 'Incidències',
    help: 'Ajuda',
    contact: 'Contacte',
    legal_disclaimer: 'Avís legal',
    accessibility: 'Accessibilitat',
    recommendations: 'Recomanacions',
    see_line_map: 'Mostra la línia al mapa',
    // Search
    search_line: 'Cerca una línia',
    search_info: 'Cerca una informació',
    station: 'Estació',
    bus_stop: 'Parada de bus',
    local_train_station: 'Estació de Rodalies',
    fgc_train_station: 'Estació de FGC',
    tram_stop: 'Parada del Tram',
    no_results: 'No s\'han trobat resultats.',
    // Travel card
    travel_card_txt: `Viatgeu sense límits en metro, autobús (TMB) i altres xarxes del transport públic de Barcelona fent servir
                      un únic billet. Podeu escollir el format que millor s'adapti a les vostres necesitats:`,
    days: 'dies',
    consecutive_days: 'dies consecutius',
    more_info: 'Més informació',
    airport_metro: 'Metro aeroport',
    airport_local_trains: 'Rodalies aeroport',
    // Layer menu
    active_information: 'Informació activa',
    select_all: 'Selecciona-ho tot',
    see_all_metro_lines: 'Mostra totes les línies de metro',
    see_all_bus_lines: 'Mostra totes les línies de bus',
    see_all_fgc_train_lines: 'Mostra totes les línies de ferrocarrils',
    see_all_local_train_lines: 'Mostra totes les línies de rodalies',
    see_all_trams_lines: 'Mostra totes les línies de tramvia',
    shows_on_map: 'Mostra al mapa',
    general_interest: 'Interès general',
    traffic_state: 'Estat del trànsit',
    near_public_transport: 'Transport públic a la vora',
    low_emissions_zone: 'Zona de baixes emissions',
    accessible_transport: 'Transport accessible',
    turisme: 'Informació turística',
    // Walk layers
    walk: 'A peu',
    pedestrian_zones: 'Zones de vianants',
    zones_30: 'Zones 30',
    escalators: 'Escales mecàniques',
    traffic_calmed_streets: 'Carrers pacificats',
    widened_pavements: 'Ampliació de voreres',
    sides_roads_closed_traffic: 'Laterals tallats al tránsit',
    // Bici layers
    bici: 'Bicicleta',
    cycle_lanes: 'Carrils bici',
    new_bicycle_lanes: 'Corredors bici',
    cycle_lanes_under_construction: 'Carrils bici en construcció',
    roads_suitable_for_cycling: 'Vies pedalables',
    green_route: 'Ronda verda',
    car_parks: 'Aparcaments',
    bicycle_anchoring_points: 'Punts d\'ancoratge bicicletes',
    car_parks_with_bicycle_places: 'Aparcaments amb places per a bicicletes',
    establishments: 'Establiments',
    bicycle_hire: 'Lloguer de bicicletes',
    bike_repairs: 'Reparació de bicicletes',
    public_transport: 'Transport públic',
    bici_friends: 'Edificis amics de la bici',
    certified_building: 'Edifici municipal certificat',
    certified_process_building: 'Edifici municipal en procés de certificació',
    indoor_visitor_parkings: 'Aparcaments interiors visitants',
    outdoor_visitor_parkings: 'Aparcaments exteriors visitants',
    // Car layers
    car_moto: 'Cotxe o moto',
    traffic_jams: 'Retencions',
    traffic_restrictions: 'Afectacions al trànsit',
    sections_with_cones: 'Trams amb cons',
    ring_roads: 'Rondes',
    ring_road_entrances: 'Entrades a la ronda',
    ring_road_exits: 'Sortides de la ronda',
    blue_area: 'Àrea blava',
    green_area: 'Àrea verda',
    exclusive_green_area: 'Àrea verda exclusiva',
    sings_and_other_factors: 'Senyals i altres elements',
    traffic_cameras: 'Càmeres de trànsit',
    loading_and_unloading_zones: 'Zones de càrrega i descàrrega',
    controlled_access_zones: 'Zones d\'accés controlat',
    electric_vehicle_recharging_points: 'Punts de recàrrega de vehicles elèctric',
    petrol_stations: 'Benzineres',
    // Route preferences
    by_type_of_route: 'Per tipus de ruta',
    by_type_of_transport: 'Per tipus de transport públic',
    best_route: 'Millor ruta',
    less_walking: 'Caminar menys',
    fewer_transfers: 'Menys transbordaments',
    accessible_routes: 'Rutes accessibles',
    transport_public_priority: 'Prioritza les rutes amb transport públic accessible, per a persones amb mobilitat reduïda',
    // Planificador
    where_to_go: 'On vols anar?',
    origin: 'Origen',
    destination: 'Destí',
    plan_route: 'Planifica la ruta',
    leave_now: 'Vull sortir ara mateix',
    leave_at: 'Surt a les',
    arrive_at: 'Arriba a les',
    preferences: 'Preferències',
    avoid_tolls: 'Evita peatges',
    avoid_highways: 'Evita autopistes',
    no_matches_found: 'No s\'han trobat coincidències',
    change_keyword: 'Prova a fer la cerca canviant la paraula clau',
    my_current_location: 'La meva ubicació actual',
    recent_searches: 'Recerques recents',
    by_position: 'Per posició',
    date: 'Data',
    time: 'Hora',
    go_from: 'Des de',
    to: 'fins a',
    suggested_route: 'Ruta suggerida',
    sustainable_route: 'Ruta sostenible',
    other_routes: 'Altres rutes',
    no_routes_available: 'No s\'han trobat rutes disponibles per al mitjà de transport seleccionat',
    sustainable_message_1: 'Si viatges en transport públic trigaries',
    sustainable_message_2: 'amb vehicle privat. T\'estalvies les cues de trànsit i l\'aparcament i estàs col·laborant a fer una ciutat més verda i neta.',
    sustainable_message_default: 'T\'oferim una opció per desplaçar-te en transport públic. Si la escull estalviaràs les cues de trànsit i l\'aparcament i estàs col·laborant a fer una ciutat més verda i neta.',
    less_than: 'menys que',
    longer_than: 'més que',
    same_time: 'el mateix temps que',
    hours: 'hores',
    minutes: 'minuts',
    and: 'i',
    only: 'només',
    // Info panel
    how_to_arrive: 'Com hi arribo',
    add_to_favorites: 'Desa a preferits',
    next_exits: 'Properes sortides',
    upcoming_trains: 'Propers trens',
    arrival: 'Arribada',
    departure: 'Sortida',
    no_time_table_available: 'En aquest moment, no n\'hi ha informació d\'horaris disponible',
    adapted_access: 'Accés adaptat',
    adapted_station: 'Estació adaptada',
    lines: 'Línies',
    stop: 'Parada',
    correspondence: 'Correspondències',
    added_to_favorites: 'Desat a preferits',
    imminent: 'Imminent',
    mechanical_bycicles: 'Bicicletes mecàniques',
    electrical_bycicles: 'Bicicletes eléctriques',
    free_bycicle_slots: 'Places lliures',
    address: 'Adreça',
    district: 'Districte',
    phonenumber: 'Telèfon',
    url: 'Web',
    entry: 'Entrada',
    exit: 'Sortida',
    municipality: 'Municipi',
    source: 'Font',
    description: 'Descripció',
    // Help
    in_your_phone: '"Com s\'hi va" al mòbil',
    search_best_route: 'Cerca la millor ruta per moure\'t a Barcelona. Tria on vols anar i com!',
    look_map: 'Mira el mapa',
    click_fullscreen: 'Amb un clic fes el mapa gran. Per tornar a la navegació, toca la pantalla de nou.',
    customize_travel: 'Personalitza el camí ',
    how_to_move: 'indicant com t\'agrada moure\'t',
    all_tranport: 'Tot el transport públic',
    find_line_easy: 'Localitza una línia o una parada i situa-la al mapa amb facilitat.',
    to_your_mesure: 'A la teva mida',
    choose_you_want_to_see: 'Tria que vols veure en qualsevol moment: carrils bici, escales mecàniques, transport accessible, estat del trànsit... Només amb un clic!',
    add_to_home_screen_help: 'Afegeix-lo a la pantalla d\'inici del teu dispositiu i consulta\'l allà on siguis.',
    add_to_home_screen_ios_1: 'Obrir el menú Compartir a Safari',
    add_to_home_screen_ios_2: 'Fer clic a l\'opció Afegir a la pantalla d\'inici.',
    add_to_home_screen_how: 'Com afegir?',
    add_to_home_screen_android_1: 'Obrir el menú de Configuració',
    add_to_home_screen_android_2: 'Fer clic a la opció Afegir a la pantalla d\'inici.',
    add: 'Afegir',
    continue: 'Continua',
    back: 'Enrere',
    to_exit: 'Surt',
    // Incidents
    no_incidents: 'Actualment, no hi han incidències a la ciutat.',
    // Recommendations
    recommendation_1: 'Al transport públic és obligatori l\'ús de mascareta tapant nas i boca.',
    recommendation_2: 'Renteu-vos bé les mans amb aigua i sabó o amb un gel hidroalcohòlic.',
    recommendation_3: 'Cal seguir en tot moment les recomanacions establertes per les autoritats sanitàries.',
  },
  es: {
    // General
    loader_message: 'Cargando<br>información.<br>Por favor, espere.',
    cancel: 'Cancelar',
    link_contact: 'https://w10.bcn.cat/APPS/irsconsultesWeb/continuar.executant.do?i=e&origen=WEB_BARCELONA_CAT&tema=81&element=1121',
    link_accessibility: 'https://www.barcelona.cat/es/accesibilidad',
    link_legal: 'https://www.barcelona.cat/es/aviso-legal',
    yes: 'Sí',
    geolocation_msg: 'No se ha podido determinar su localización. Debe tener activada la geolocalización en su dispositivo móvil y autorizar al Com s\'hi va para ubicar le.',
    // Transports
    metro: 'Metro',
    bus: 'Bus',
    local_trains: 'Cercanías',
    fgc_trains: 'Ferrocarriles',
    trams: 'Tranvia',
    funicular: 'Funicular',
    aerobus: 'Aerobús',
    tourist_bus: 'Bus turístico',
    // Main Menu
    route_preferences: 'Preferencias de ruta',
    hello_barcelona_travel_card: 'Hola Barcelona Travel Card',
    go_to_the_airport: 'Ir al aeropuerto',
    beyond_barcelona: 'Más allá de Barcelona',
    maps_and_lines: 'Mapas y lineas',
    traffic_incidents: 'Incidencias del tráfico',
    help: 'Ayuda',
    contact: 'Contacto',
    legal_disclaimer: 'Aviso legal',
    accessibility: 'Accesibilidad',
    recommendations: 'Recomendaciones',
    see_line_map: 'Ver línea en el mapa',
    // Search
    search_line: 'Busca una línea',
    search_info: 'Busca una información',
    station: 'Estación',
    bus_stop: 'Parada bus',
    local_train_station: 'Estación Cercanías',
    fgc_train_station: 'Estación FGC',
    tram_stop: 'Estació TRAM',
    no_results: 'No se han encontrado resultados.',
    // Travel card
    travel_card_txt: `Viaja sin límites en metro, autobús (TMB) 
                      y otras redes del transporte público de Barcelona utilizando un único billete. 
                      Puedes escoger el formato que mejor se adapte a tus necesidades:`,
    days: 'días',
    consecutive_days: 'días consecutivos',
    more_info: 'Más información',
    airport_metro: 'Metro aeropuerto',
    airport_local_trains: 'Cercanías aeropuerto',
    // Layer menu
    active_information: 'Información activa',
    select_all: 'Seleccionar todo',
    see_all_metro_lines: 'Ver todas las líneas de metro',
    see_all_bus_lines: 'Ver todas las líneas de bus',
    see_all_fgc_train_lines: 'Ver todas las líneas de ferrocarriles',
    see_all_local_train_lines: 'Ver todas las líneas de cercanías',
    see_all_trams_lines: 'Ver todas las líneas de tranvía',
    shows_on_map: 'Muestra en el mapa',
    general_interest: 'Interés general',
    traffic_state: 'Estado del tráfico',
    near_public_transport: 'Transporte público cercano',
    low_emissions_zone: 'Zona bajas emisiones',
    accessible_transport: 'Transporte accesible',
    turisme: 'Información turística',
    // Walk layers
    walk: 'A pie',
    pedestrian_zones: 'Zonas de peatones',
    zones_30: 'Zonas 30',
    escalators: 'Escaleras mecánicas',
    traffic_calmed_streets: 'Calles pacificadas',
    widened_pavements: 'Ampliación de aceras',
    sides_roads_closed_traffic: 'Laterales cerrados al tráfico',
    // Bici layers
    bici: 'Bicicleta',
    cycle_lanes: 'Carriles bici',
    new_bicycle_lanes: 'Corredores bici',
    cycle_lanes_under_construction: 'Carriles bici en construcción',
    roads_suitable_for_cycling: 'Vies ciclables',
    green_route: 'Ronda verde',
    car_parks: 'Aparcamientos',
    bicycle_anchoring_points: 'Puntos de anclaje de bicicletas',
    car_parks_with_bicycle_places: 'Aparcamientos con plazas para bicicletas',
    establishments: 'Establecimientos',
    bicycle_hire: 'Alquiler de bicicletas',
    bike_repairs: 'Reparación de bicicletas',
    public_transport: 'Transporte público',
    bici_friends: 'Edificios amigos de la bici',
    certified_building: 'Edificio municipal certificado',
    certified_process_building: 'Edificio municipal en proceso de certificación',
    indoor_visitor_parkings: 'Aparcamientos interiores para visitantes',
    outdoor_visitor_parkings: 'Aparcamientos exteriores para visitantes',
    // Car layers
    car_moto: 'Coche o moto',
    traffic_jams: 'Retenciones',
    traffic_restrictions: 'Alteraciones en el tráfico',
    sections_with_cones: 'Tramos con conos',
    ring_roads: 'Rondas',
    ring_road_entrances: 'Entradas rondas',
    ring_road_exits: 'Salidas de la ronda',
    blue_area: 'Área azul',
    green_area: 'Área verde',
    exclusive_green_area: 'Área verde exclusiva',
    sings_and_other_factors: 'Señales y otros elementos',
    traffic_cameras: 'Cámeres de tráfico',
    loading_and_unloading_zones: 'Zonas de carga y descarga',
    controlled_access_zones: 'Zonas de acceso controlado',
    electric_vehicle_recharging_points: 'Puntos de recarga vehículos eléctricos',
    petrol_stations: 'Gasolineras',
    // Route preferences
    by_type_of_route: 'Por tipo de ruta',
    by_type_of_transport: 'Por tipo de transporte público',
    best_route: 'Mejor ruta',
    less_walking: 'Caminar menos',
    fewer_transfers: 'Menos transbordos',
    accessible_routes: 'Rutas accesibles',
    transport_public_priority: 'Prioriza las rutas con transporte público accesible, para personas con movilidad reducida',
    // Planificador
    where_to_go: '¿Donde quieres ir?',
    origin: 'Origen',
    destination: 'Destino',
    plan_route: 'Planifica ruta',
    leave_now: 'Salir ahora mismo',
    leave_at: 'Salir a las',
    arrive_at: 'Llegar a las',
    preferences: 'Preferencias',
    avoid_tolls: 'Evitar peajes',
    avoid_highways: 'Evitar autopistas',
    no_matches_found: 'No hay coincidencias',
    change_keyword: 'Prueba realizar la búsqueda cambiando la palabra clave',
    my_current_location: 'Mi ubicación actual',
    recent_searches: 'Búsquedas recientes',
    by_position: 'Por posición',
    date: 'Fecha',
    time: 'Hora',
    go_from: 'Ir desde',
    to: 'hasta',
    suggested_route: 'Ruta sugerida',
    sustainable_route: 'Ruta sostenible',
    other_routes: 'Otras rutas',
    no_routes_available: 'No se han encontrado rutas disponibles en el medio de transporte seleccionado',
    sustainable_message_1: 'Si viajas en transporte público tardarás',
    sustainable_message_2: 'en vehículo privado. Te ahorrarás las colas de tráfico y el aparcamiento y estás colaborando a hacer una ciudad más verde y limpia.',
    sustainable_message_default: 'Te ofrecemos una opción para desplazarte en transporte público. Si la eliges te ahorrarás atascos de tráfico y aparcamiento y está colaborando en hacer una ciudad más verde y limpia.',
    less_than: 'menos que',
    longer_than: 'más que',
    same_time: 'el mismo tiempo que',
    hours: 'horas',
    minutes: 'minutos',
    and: 'y',
    only: 'solo',
    // Info panel
    how_to_arrive: 'Cómo llego',
    add_to_favorites: 'Guardar en favoritos',
    next_exits: 'Próximas salidas',
    upcoming_trains: 'Próximos trenes',
    arrival: 'Llegada',
    departure: 'Salida',
    no_time_table_available: 'En este momento no hay informació de horarios disponible',
    adapted_access: 'Acceso adaptado',
    adapted_station: 'Estación adaptada',
    lines: 'Líneas',
    stop: 'Parada',
    correspondence: 'Correspondencias',
    added_to_favorites: 'Guardado en favoritos',
    imminent: 'Inminente',
    mechanical_bycicles: 'Bicicletas mecánicas',
    electrical_bycicles: 'Bicicletas eléctricas',
    free_bycicle_slots: 'Plazas libres',
    address: 'Dirección',
    district: 'Distrito',
    phonenumber: 'Teléfono',
    url: 'Web',
    entry: 'Entrada',
    exit: 'Sortida',
    municipality: 'Muncipio',
    source: 'Fuente',
    description: 'Descripción',
    // Help
    in_your_phone: 'Com s\'hi va en tu móvil',
    search_best_route: 'Busca la mejor ruta para moverte en Barcelona. ¡Elige dónde quieres ir y cómo!',
    look_map: 'Mira el mapa',
    click_fullscreen: 'Con un click haz el mapa grande. Para volver a la navegación toca la pantalla de nuevo.',
    customize_travel: 'Personaliza tu camino',
    how_to_move: 'Indicando cómo te gustaría moverte.',
    all_tranport: 'Todo el transporte público.',
    find_line_easy: 'Localiza una línea o una parada y situala en el mapa con facilidad.',
    to_your_mesure: 'A tu medida',
    choose_you_want_to_see: 'Elige qué quires ver en cualquier momento: carriles bici, escalera mecánicas, transporte accesible, estado del tráfico... ¡Sólo con un click!',
    add_to_home_screen_help: 'Añádelo a la pantalla de inicio de tu dispositivo y consulta allá donde estés.',
    add_to_home_screen_ios_1: 'Abrir el menú Compartir en Safari',
    add_to_home_screen_ios_2: 'Hacer click en la opción Añadir a la pantalla de inicio.',
    add_to_home_screen_how: '¿Cómo añadirlo?',
    add_to_home_screen_android_1: 'Abrir el menú de Configuración',
    add_to_home_screen_android_2: 'Hacer click en la opción Añadir a la pantalla de inicio.',
    add: 'Añadir',
    continue: 'Continuar',
    back: 'Atrás',
    to_exit: 'Salir',
    // Incidents
    no_incidents: 'Actualmente, no hay incidencias en la ciudad.',
    // Recommendations
    recommendation_1: 'En el transporte público es obligatorio el uso de mascarilla tapando nariz y boca.',
    recommendation_2: 'Lavaos bien las manos con agua y jabón o con gel hidroalcohólico.',
    recommendation_3: 'Seguir en todo momento las recomendaciones establecidas por las autoridades sanitarias.',
  },
  en: {
    // General
    loader_message: 'Loading<br>information<br>Please, wait.',
    cancel: 'Cancel',
    link_contact: 'https://w10.bcn.cat/APPS/irsconsultesWeb/continuar.executant.do?i=i&origen=WEB_BARCELONA_CAT&tema=81&element=1121',
    link_accessibility: 'https://www.barcelona.cat/en/accessibility',
    link_legal: 'https://www.barcelona.cat/en/legal-notice',
    yes: 'Yes',
    geolocation_msg: 'Your location could not be determined. You must have geolocation enabled on your mobile device and authorize Com s\'hi va.',
    // Transports
    metro: 'Metro',
    bus: 'Bus',
    local_trains: 'Local trains',
    fgc_trains: 'FGC trains',
    trams: 'Trams',
    funicular: 'Funicular',
    aerobus: 'Aerobus',
    tourist_bus: 'Touristic bus',
    // Main Menu
    route_preferences: 'Route preferences',
    hello_barcelona_travel_card: 'Hello Barcelona Travel Card',
    go_to_the_airport: 'Getting to the airport',
    beyond_barcelona: 'Beyond Barcelona',
    maps_and_lines: 'Maps and lines',
    traffic_incidents: 'Traffic incidents',
    help: 'Help',
    contact: 'Contact',
    legal_disclaimer: 'Legal disclaimer',
    accessibility: 'Accessibility',
    recommendations: 'Recommendations',
    see_line_map: 'See line on the map',
    // Travel card
    travel_card_txt: `Unlimited travel on the metro, bus (TMB) and other public transport networks 
                      in Barcelona, using the same ticket. Choose the format which suits your needs best:`,
    days: 'days',
    consecutive_days: 'consecutive days',
    more_info: 'More information',
    airport_metro: 'Airport metro',
    airport_local_trains: 'Airport local trains',
    // Search
    search_line: 'Search line',
    search_info: 'Search information',
    station: 'Station',
    bus_stop: 'Bus stop',
    local_train_station: 'Local train station',
    fgc_train_station: 'FGC station',
    tram_stop: 'Tram stop',
    no_results: 'No results found.',
    // Layer menu
    active_information: 'Active information',
    select_all: 'Select all',
    see_all_metro_lines: 'See all metro lines',
    see_all_bus_lines: 'See all bus lines',
    see_all_fgc_train_lines: 'See all train FGC lines',
    see_all_local_train_lines: 'See all train local lines',
    see_all_trams_lines: 'See all tram lines',
    shows_on_map: 'Shows on map',
    general_interest: 'General interest',
    traffic_state: 'Traffic situation',
    near_public_transport: 'Near public transport',
    low_emissions_zone: 'Low emission zone',
    accessible_transport: 'Accessible transport',
    turisme: 'Tourist information',
    // Walk layers
    walk: 'Walk',
    pedestrian_zones: 'Pedestrian zones',
    zones_30: '30 kph zones',
    escalators: 'Escalators',
    traffic_calmed_streets: 'Traffic calmed streets',
    widened_pavements: 'Widened pavements',
    sides_roads_closed_traffic: 'Side roads closed to traffic',
    // Bici layers
    bici: 'Bycicle',
    cycle_lanes: 'Cicle lanes',
    new_bicycle_lanes: 'New bicycle lanes',
    cycle_lanes_under_construction: 'Cicle lanes under construction',
    roads_suitable_for_cycling: 'Roads suitable for cycling',
    green_route: 'Green route',
    car_parks: 'Car parks',
    bicycle_anchoring_points: 'Bicycle anchoring points',
    car_parks_with_bicycle_places: 'Car parks with bicycle places',
    establishments: 'Establishments',
    bicycle_hire: 'Bicycle hire',
    bike_repairs: 'Bike repairs',
    public_transport: 'Public transport',
    bici_friends: 'Bicycle-friendly buildings',
    certified_building: 'Certified municipal building',
    certified_process_building: 'Municipal building in process of certification',
    indoor_visitor_parkings: 'Covered car parking for visitors',
    outdoor_visitor_parkings: 'Outdoor car parking for visitors',
    // Car layers
    car_moto: 'Car or motorbike',
    traffic_jams: 'Traffic jams',
    traffic_restrictions: 'Trafics restrictions',
    sections_with_cones: 'Sections with cones',
    ring_roads: 'Ring roads',
    ring_road_entrances: 'Ring-road entrances',
    ring_road_exits: 'Ring-road entrances exits',
    blue_area: 'Blue area',
    green_area: 'Green area',
    exclusive_green_area: 'Exclusive green area',
    sings_and_other_factors: 'Signs and other factors',
    traffic_cameras: 'Traffic cameras',
    loading_and_unloading_zones: 'Loading and unloading zones',
    controlled_access_zones: 'Controlled access zones',
    electric_vehicle_recharging_points: 'Electric-vehicle recharging points',
    petrol_stations: 'Petrol stations',
    // Route preferences
    by_type_of_route: 'By type of route',
    by_type_of_transport: 'By type of public transport',
    best_route: 'Best route',
    less_walking: 'Less walking',
    fewer_transfers: 'Fewer transfers',
    accessible_routes: 'Accessible routes',
    transport_public_priority: 'Prioritize routes with accessible public transport, for people with reduced mobility',
    // Planificador
    where_to_go: 'Where to go?',
    origin: 'Origin',
    destination: 'Destination',
    plan_route: 'Plan your route',
    leave_now: 'Leave right now',
    leave_at: 'Leave at',
    arrive_at: 'Arrive at',
    preferences: 'Preferences',
    avoid_tolls: 'Avoid tolls',
    avoid_highways: 'Avoid highways',
    no_matches_found: 'No matches found',
    change_keyword: 'Try searching by changing your keyword',
    my_current_location: 'My current location',
    recent_searches: 'Recent searches',
    by_position: 'By position',
    date: 'Date',
    time: 'Time',
    go_from: 'Go from',
    to: 'to',
    suggested_route: 'Suggested route',
    sustainable_route: 'Sustainable route',
    other_routes: 'Other routes',
    no_routes_available: 'No available routes were found on the selected means of transport',
    sustainable_message_1: 'If you travel by public transport it will take',
    sustainable_message_2: 'by private vehicle. You will save on traffic queues and parking and you are collaborating to make a greener and cleaner city.',
    sustainable_message_default: 'We offer you an option to get around by public transport. If you choose it, you will save traffic and parking jams and it is helping to make a greener and cleaner city.',
    less_than: 'less than',
    longer_than: 'longer than',
    same_time: 'the same time as',
    hours: 'hours',
    minutes: 'minutes',
    and: 'and',
    only: 'only',
    // Info panel
    how_to_arrive: 'How to arrive',
    add_to_favorites: 'Add to favorites',
    next_exits: 'Next exits',
    upcoming_trains: 'Upcoming trains',
    arrival: 'Arrival',
    departure: 'Departure',
    no_time_table_available: 'No timetable information is available at this time',
    adapted_access: 'Adapted access',
    adapted_station: 'Adapted station',
    lines: 'Line',
    stop: 'Stop',
    correspondence: 'Correspondence',
    added_to_favorites: 'Added to favorites',
    imminent: 'Inminent',
    mechanical_bycicles: 'Mechanical bycicles',
    electrical_bycicles: 'Electrical bycicles',
    free_bycicle_slots: 'Free bycicle slots',
    address: 'Address',
    district: 'District',
    phonenumber: 'Phone number',
    url: 'Web',
    entry: 'Entry',
    exit: 'Exit',
    municipality: 'Municipality',
    source: 'Source',
    description: 'Description',
    // Help
    in_your_phone: 'Com s\'hi va on your mobile',
    search_best_route: 'Find the best route to move around Barcelona. Choose where you want to go and how!',
    look_map: 'Look at the map',
    click_fullscreen: 'With one click, make the map large. To return to navigation, touch the screen again.',
    customize_travel: 'Customize your way',
    how_to_move: 'Indicating how you would like to move.',
    all_tranport: 'All public transport',
    find_line_easy: 'Find a line or stop and easily locate it on the map.',
    to_your_mesure: 'Tailored to your needs',
    choose_you_want_to_see: 'Choose what you want to see at any time: bike lanes, escalators, accessible transport, traffic state ... Just with one click!',
    add_to_home_screen_help: 'Add it to the home screen of your device and consult wherever you are.',
    add_to_home_screen_ios_1: 'Open Safari share menu',
    add_to_home_screen_ios_2: 'Tap on Add to Home Screen.',
    add_to_home_screen_how: 'How to add?',
    add_to_home_screen_android_1: 'Open Browser settings button',
    add_to_home_screen_android_2: 'Tap on Add to Home Screen.',
    add: 'Add',
    continue: 'Continue',
    back: 'Previous',
    to_exit: 'Exit',
    // Incidents
    no_incidents: 'Currently, there are no incidents in the city.',
    // Recommendations
    recommendation_1: 'In public transport, the use of a mask covering the nose and mouth is mandatory.',
    recommendation_2: 'Wash your hands well with soap and water or hydroalcoholic gel.',
    recommendation_3: 'Always follow the recommendations established by the health authorities.',
  },
};
