import Vue from 'vue';
import Vuex from 'vuex';
import transport from './modules/transport';
import map from './modules/map';
import search from './modules/search';
import menu from './modules/menu';
import infopanel from './modules/infopanel';
import planroute from './modules/planroute';
import incidents from './modules/incidents';
import help from './modules/help';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
  },
  modules: {
    transport,
    map,
    search,
    menu,
    infopanel,
    planroute,
    incidents,
    help,
  },
});
