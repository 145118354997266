<template>
  <div id="app" :style="appStyle()">
    <help ref="help"></help>
    <barra-ajuntament ref="barraAjuntament" v-show="!mapFullScreen"></barra-ajuntament>
    <div class="content-app">
      <header ref="mainHeader" class="mainHeader" v-show="!mapFullScreen">
        Com s'hi va
      </header>
      <main-menu ref="mainMenu" v-show="!mapFullScreen"></main-menu>
      <traffic-incidents ref="incidents" v-show="!mapFullScreen"></traffic-incidents>
      <planificador ref="planificador" v-show="!mapFullScreen"></planificador>
      <div class="menu-background" v-if="backgroundVisible()"></div>
      <layer-menu ref="layerMenu"></layer-menu>
      <mapa ref="mapa"></mapa>
      <route-panel ref="routePanel"></route-panel>
      <info-panel ref="infoPanel"></info-panel>
    </div>
    <router-view></router-view>
    <div class="loader-container">
      <div class="text-center">
        <div class="spinner"></div>
        <div class="text-center d-block" v-html="$t('loader_message')"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import './classes/maps/Util';
import './classes/GoogleRoutesApi';
import './classes/LineaTransportAerobus';
import './classes/LineaTransportBus';
import './classes/LineaTransportCercanias';
import './classes/LineaTransportFerrocarril';
import './classes/LineaTransportFunicular';
import './classes/LineaTransportMetro';
import './classes/LineaTransportTram';
import './classes/LineaTransportBusNorth';

import BarraAjuntament from './components/BarraAjuntament.vue';
import Planificador from './components/Planificador.vue';
import MainMenu from './components/MainMenu.vue';
import LayerMenu from './components/LayerMenu.vue';
import Mapa from './components/Mapa.vue';
import RoutePanel from './components/RoutePanel.vue';
import InfoPanel from './components/InfoPanel.vue';
import TrafficIncidents from './components/TrafficIncidents.vue';
import Help from './components/Help.vue';
import api, { getToken } from './api/api';

export default {
  components: {
    BarraAjuntament,
    Planificador,
    MainMenu,
    LayerMenu,
    Mapa,
    RoutePanel,
    InfoPanel,
    TrafficIncidents,
    Help,
  },
  computed: {
    ...mapState([
      'token',
    ]),
    ...mapState('infopanel', [
      'infoPanel',
    ]),
    ...mapState('menu', [
      'layerMenuStatus',
      'mainMenuStatus',
    ]),
    ...mapState('map', [
      'mapFullScreen',
    ]),
  },
  methods: {
    ...mapMutations({
      setToken: 'setToken',
      setTrafficIncidentsFirstRead: 'incidents/setTrafficIncidentsFirstRead',
      setTrafficIncidents: 'incidents/setTrafficIncidents',
    }),
    backgroundVisible() {
      let visible = false;
      if (this.mainMenuStatus.opened || this.layerMenuStatus.opened || this.infoPanel.visible) {
        visible = true;
      }
      return visible;
    },
    appStyle() {
      return { minHeight: `${window.innerHeight}px` };
    },
    loadTransport() {
      const lineaTransportMetro = new LineaTransportMetro();
      lineaTransportMetro.loadTransport();
      const lineaTransportBus = new LineaTransportBus();
      lineaTransportBus.loadTransport();
      const lineaTransportAerobus = new LineaTransportAerobus();
      lineaTransportAerobus.loadTransport();
      const lineaTransportFunicular = new LineaTransportFunicular();
      lineaTransportFunicular.loadTransport();
      const lineaTransportTram = new LineaTransportTram();
      lineaTransportTram.loadTransport();
      var lineaTransportCercanias = new LineaTransportCercanias();
      lineaTransportCercanias.loadTransport();
      const lineaTransportFerrocarril = new LineaTransportFerrocarril();
      lineaTransportFerrocarril.loadTransport();
      const lineaTransportBusNorth = new LineaTransportBusNorth();
      lineaTransportBusNorth.loadTransport();
    },
  },
  async beforeCreate() {
    const token = await getToken();
    this.setToken(token);
    this.loadTransport();
    if (localStorage.trafficIncidents != undefined) {
      this.setTrafficIncidents(JSON.parse(localStorage.trafficIncidents));
    }
    this.$root.$children[0].$refs.incidents.loadIncidents(true);
    const tokenRefreshTime = 7199;
    setInterval(async () => {
      const token = await getToken();
      this.setToken(token);
    }, 60 * tokenRefreshTime);
  },
};
</script>
