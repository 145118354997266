/* eslint-disable */

import axios from 'axios';
import { getApiUri } from '../api/util';
import store from '../store/index';
import api from '../api/api';

window.LineaTransportBus = function() {

}

LineaTransportBus.prototype.loadTransport = function () {
  api.get(`${getApiUri()}buslinies/?RECORRIDO=0`).then(function (response) {
    var results = response.data.features;
    for (var c in results) {
      var result = results[c];
      var linea = {
        linea: result.properties.Nom,
        descripcio: result.properties.DescIntern,
        color: LineaTransportBus.getColor(result.properties.Nom),
        type: 'bus',
        icon: 'csvicon-bus-stop',
        estacions: {}
      }
      if (linea.linea.indexOf("A") == -1 && linea.linea.indexOf(' ') == -1) {
        store.commit('transport/setLineas', {
          bus: {
            [result.properties.Nom]: linea,
          },
        });
      }
    }
    const ordered = {};
    Object.keys(store.state.transport.lineas.bus).sort().forEach(function(key) {
      ordered[key] = store.state.transport.lineas.bus[key];
    });

    store.commit('transport/setLineas', {
      bus: null,
    });

    store.commit('transport/setLineas', {
      bus: ordered,
    });
  });

}

LineaTransportBus.prototype.loadStation = function (linea) {
  var promise = new Promise(function(resolve, reject) {
    api.get(`${getApiUri()}busparades/${linea}/`).then(function (response) {
      var results = response.data.features;
      for (var c in results) {
        var result = results[c];
        var parada = {
          nom: result.properties.Descripcio,
          codi: result.properties.Codigo,
          ordre: parseInt(result.properties.Ordre),
          lineas: result.properties.Linies,
          municipi: result.properties.Municipi,
          geometry: result.geometry
        }

        store.commit('transport/setLineas', {
          bus: {
            [linea]: {
              estacions: {
                [result.properties.Ordre]: parada,
              },
            },
          },
        });
      }
      resolve(response);
    });
  });
  return promise;
}

LineaTransportBus.getLinea = function(linea_name) {
  for (let c in store.state.transport.lineas.bus) {
    if (store.state.transport.lineas.bus[c].linea == linea_name) {
      return store.state.transport.lineas.bus[c];
    }
  }
  return {};
}

LineaTransportBus.getStation = function(linea, code) {
  for (let c in linea.estacions) {
    if (linea.estacions[c].codi == code) {
      return linea.estacions[c];
    }
  }
  return null;
}

LineaTransportBus.getColor = function(linea_name) {
  if (linea_name.indexOf('D')!=-1) return '93117e';
  if (linea_name.indexOf('H')!=-1) return '013888';
  if (linea_name.indexOf('V')!=-1) return '41701d';
  return 'e00000'
}

LineaTransportBus.displayInfo = function (feature) {
  window.app.$children[0].$refs.infoPanel.initInfoPanel();

  store.commit('infopanel/setInfoPanel', {
    title: feature.getProperty('Descripcio'),
    linea: feature.getProperty('Linea'),
    nom_linea: feature.getProperty('Linea'),
    type: 'bus',
    feature: feature,
    datos: [
      {'label': window.app.$t('stop'), 'data': feature.getProperty('Codigo')},
      {'label': window.app.$t('lines'), 'data': feature.getProperty('Linies')}
    ],
  });
  window.app.$children[0].$refs.infoPanel.updateBusTime();
}

LineaTransportBus.loadLineMap = function(linea_name, zoom, station_name) {

  var linea = LineaTransportBus.getLinea(linea_name);

  var configStation = Object.assign({}, {
    ZOOM: 16,
    zoomPoint: 17,
    CENTER: new google.maps.LatLng(41.404, 2.173),
    MAP_TYPE: google.maps.MapTypeId.ROADMAP,
    REGION: 'ca',
    BUFFER_DISTANCE: 0.2, // Km
    savePosition: true,
    displayInfo: true,
    setMarkerSearch: true,
    adaptativeMarkers: true,
    iconURL: "/img/markers/publico",
    forceZoom: false,
    mapTypeControl: false,
    minZoomLevel: 14,
    visible: true,
  });

  configStation.callback = {
    displayInfo: LineaTransportBus.displayInfo,
  }

  configStation.style = {
    strokeColor: "#"+linea.color,
    fillColor: "#"+linea.color,
    strokeOpacity: 1,
    strokeWeight: 2,
    path: "/img/markers/publico",
    marker: "parada_bus.svg",
    icon: "parada_bus.svg",
  };

  configStation.name = "busparades" + linea.linea;

  var configLine = Object.assign({}, {
    ZOOM: 16,
    zoomPoint: 17,
    CENTER: new google.maps.LatLng(41.404, 2.173),
    MAP_TYPE: google.maps.MapTypeId.ROADMAP,
    REGION: 'ca',
    BUFFER_DISTANCE: 0.2, // Km
    savePosition: true,
    setMarkerSearch: true,
    adaptativeMarkers: true,
    iconURL: "/img/markers/",
    forceZoom: false,
    mapTypeControl: false,
  });

  configLine.style = {
    strokeColor: "#"+linea.color,
    fillColor: "#"+linea.color,
    strokeOpacity: 1,
    strokeWeight: 2,
  };

  configLine.name = "buslinies" + linea.linea;

  var dataLayerStation = new DataLayer(configStation, store.state.map.mapObj);
  var dataLayerLine = new DataLayer(configLine, store.state.map.mapObj);

  var layerUrlLine = `${getApiUri()}buslinies/${linea.linea}/`;
  var layerUrlStacions = `${getApiUri()}busparades/${linea.linea}/`;

  Promise.all([
    dataLayerLine.addGeoJsonDataLayer(layerUrlLine),
    dataLayerStation.addGeoJsonDataLayer(layerUrlStacions)
  ]).then(() => {
    if (station_name!=undefined) {
      var station = LineaTransportBus.getStation(linea, station_name);
      store.state.map.mapObj.setCenter(new google.maps.LatLng(station.geometry.coordinates[1],station.geometry.coordinates[0]));
      store.state.map.mapObj.setZoom(configStation.zoomPoint);
    }
    if (zoom=='line') {
      dataLayerLine.cropToBounds();
      if (store.state.map.mapObj.getZoom()<12) {
        store.state.map.mapObj.setZoom(12);
      }
    }
    linea.selected = true;
    store.commit('map/addActiveLayer', linea);

    store.commit('menu/setMainMenuStatus', {
      opened: false,
    });
  });

}
