const state = () => ({
  places: [],
  modo: 'WALKING',
  displayMode: '',
  whenToGo: 'now',
  opened: false,
  selectedLocation: null,
  preferencesOpened: false,
  fromInput: false,
  toInput: false,
  fromValue: '',
  toValue: '',
  fromObject: null,
  toObject: null,
  predictions: [],
  suggestionsNoResults: false,
  showResultsPanel: false,
  routes: null,
  directionsRenderer: null,
  transitDate: null,
  transitTime: null,
  avoidTolls: false,
  avoidHighways: false,
  panelOpened: false,
  panelVisible: false,
  panelTravelMode: '',
  panelTop: false,
  suggestedRoute: null,
});

const mutations = {
  setPlaces(state, places) {
    state.places = places;
    localStorage.places = JSON.stringify(places);
  },
  setModo(state, modo) {
    state.modo = modo;
  },
  setDisplayMode(state, displayMode) {
    state.displayMode = displayMode;
  },
  setWhenToGo(state, whenToGo) {
    state.whenToGo = whenToGo;
  },
  setOpened(state, opened) {
    state.opened = opened;
  },
  setSelectedLocation(state, selectedLocation) {
    state.selectedLocation = selectedLocation;
  },
  setPreferencesOpened(state, preferencesOpened) {
    state.preferencesOpened = preferencesOpened;
  },
  setFromInput(state, fromInput) {
    state.fromInput = fromInput;
  },
  setToInput(state, toInput) {
    state.toInput = toInput;
  },
  setFromValue(state, fromValue) {
    state.fromValue = fromValue;
  },
  setToValue(state, toValue) {
    state.toValue = toValue;
  },
  setFromObject(state, fromObject) {
    state.fromObject = fromObject;
  },
  setToObject(state, toObject) {
    state.toObject = toObject;
  },
  setPredictions(state, predictions) {
    state.predictions = predictions;
  },
  setSuggestionsNoResults(state, suggestionsNoResults) {
    state.suggestionsNoResults = suggestionsNoResults;
  },
  setShowResultsPanel(state, showResultsPanel) {
    state.showResultsPanel = showResultsPanel;
  },
  setRoutes(state, routes) {
    state.routes = routes;
  },
  setDirectionsRenderer(state, directionsRenderer) {
    state.directionsRenderer = directionsRenderer;
  },
  setTransitDate(state, transitDate) {
    state.transitDate = transitDate;
  },
  setTransitTime(state, transitTime) {
    state.transitTime = transitTime;
  },
  setAvoidTolls(state, avoidTolls) {
    state.avoidTolls = avoidTolls;
  },
  setAvoidHighways(state, avoidHighways) {
    state.avoidHighways = avoidHighways;
  },
  setPanelOpened(state, opened) {
    state.panelOpened = opened;
  },
  setPanelVisible(state, visible) {
    state.panelVisible = visible;
  },
  setPanelTravelMode(state, travelMode) {
    state.panelTravelMode = travelMode;
  },
  setPanelTop(state, panelTop) {
    state.panelTop = panelTop;
  },
  setSuggestedRoute(state, suggestedRoute) {
    state.suggestedRoute = suggestedRoute;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
