import { merge } from 'lodash';

const state = () => ({
  mainMenuStatus: {
    opened: false,
    opcionMenu: '',
  },
  layerMenuStatus: {
    opened: false,
  },
  layerMenu: [],
});

const mutations = {
  setMainMenuStatus(state, mainMenuStatus) {
    state.mainMenuStatus = merge({}, state.mainMenuStatus, mainMenuStatus);
  },
  setLayerMenuStatus(state, layerMenuStatus) {
    state.layerMenuStatus = merge({}, state.layerMenuStatus, layerMenuStatus);
  },

  setLayerMenu(state, layerMenu) {
    state.layerMenu = layerMenu;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
