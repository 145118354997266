import { differenceWith, isEqual } from 'lodash';
import { getApiUri } from '../../api/util';
import api from '../../api/api';

const state = () => ({
  trafficIncidents: {
    first_read: false,
    incidents: [],
    new_incidents: [],
    readed: true,
    new: 0,
  },
  forceClose: false,
});

const mutations = {
  setTrafficIncidents(state, incidents) {
    state.trafficIncidents = incidents;
    localStorage.trafficIncidents = JSON.stringify(state.trafficIncidents);
  },

  setTrafficIncidentsReaded(state, readed) {
    state.trafficIncidents.readed = readed;
    localStorage.trafficIncidents = JSON.stringify(state.trafficIncidents);
  },

  setTrafficIncidentsFirstRead(state, first_read) {
    state.trafficIncidents.first_read = first_read;
    localStorage.trafficIncidents = JSON.stringify(state.trafficIncidents);
  },

  setTrafficIncidentsNew(state, num_new) {
    state.trafficIncidents.new = num_new;
    if (num_new == 0) state.trafficIncidents.new_incidents = [];
    localStorage.trafficIncidents = JSON.stringify(state.trafficIncidents);
  },
  setForceClose(state, closed) {
    state.forceClose = closed;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
