import { merge } from 'lodash';

const state = () => ({
  preferencias: {
    transitModes: [],
    metro: false,
    bus: false,
    ferrocarril: false,
    tren: false,
    tram: false,
    tipoRuta: 'BEST-ROUTE',
  },
  lineaSelected: '',
  lineas: {
    metro: {},
    bus: {},
    ferrocarril: {},
    rodalies: {},
    funicular: {},
    aerobus: {},
    tram: {},
    busnorth: {},
  },
  alterationMessages: {},
});

const mutations = {
  setPreferencias(state, preferencias) {
    state.preferencias = merge({}, state.preferencias, preferencias);
    localStorage.preferencias = JSON.stringify(preferencias);
  },

  setLineaSelected(state, linea) {
    state.lineaSelected = linea;
  },

  setLineas(state, lineas) {
    state.lineas = merge({}, state.lineas, lineas);
  },

  setAlterationMessages(state, alterationMessages) {
    state.alterationMessages = alterationMessages;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
