<template>
  <div id="trafficIncidents"
       v-if="!forceClose && trafficIncidents.first_read && !trafficIncidents.readed && trafficIncidents.incidents.length>0">
    <div class="alert-icon csvicon-alerta"></div>
    <div class="close-icon bcn-icon-tancar-medium" @click="close"></div>
    <div class="incidents">
      <ul>
        <li v-for="(incident, key) in getNewIncidents"
            v-bind:key="key"
            v-html="incident"
        ></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { differenceWith, isEqual } from 'lodash';
import { getApiUri } from '../api/util';
import api from '../api/api';

export default {
  name: 'TrafficIncidents',
  data() {
    return {
      interval: null,
      cont_test: 0,
      mode: 'notest',
    };
  },
  computed: {
    ...mapState('incidents', [
      'trafficIncidents',
      'forceClose',
    ]),
    ...mapState('planroute', [
      'opened',
    ]),
    getNewIncidents() {
      return this.trafficIncidents.incidents.filter((incident) => {
        return this.trafficIncidents.new_incidents.indexOf(incident) !== -1;
      });
    },
  },
  methods: {
    ...mapMutations({
      setTrafficIncidents: 'incidents/setTrafficIncidents',
      setTrafficIncidentsReaded: 'incidents/setTrafficIncidentsReaded',
      setTrafficIncidentsNew: 'incidents/setTrafficIncidentsNew',
      setTrafficIncidentsFirstRead: 'incidents/setTrafficIncidentsFirstRead',
      setTrafficIncidentsForceClose: 'incidents/setForceClose',
    }),
    close() {
      this.setTrafficIncidentsReaded(true);
      this.setTrafficIncidentsNew(0);
      this.setTrafficIncidentsFirstRead(false);
    },
    loadIncidents(firstRead = false) {
      api.get(`${getApiUri()}services/trafficincidents/`).then((response) => {
        let incidents = response.data.results[window.app.$i18n.locale];
        if (incidents) {
          incidents.shift(); // Discard first element
          // Incidents to test functionality
          if (process.env.NODE_ENV !== 'production' && this.mode == 'test') {
            incidents = this.getTestIncidents(this.cont_test, incidents);
            if (this.cont_test > 0) {
              this.setTrafficIncidentsFirstRead(false);
            }
            this.cont_test = (this.cont_test < 4) ? this.cont_test + 1 : 0;
          }
          this.setNewIncidents(incidents, firstRead);
        } else {
          this.setNewIncidents([], firstRead);
        }
      });
    },
    setNewIncidents(incidents = [], firstRead) {
      // Remove from "new_incidentes" items not in "incidents"
      const new_incidents = this.trafficIncidents.new_incidents
        .filter((incident) => {
          return incidents.indexOf(incident) !== -1;
        });
      // Add new incidents
      const diff = differenceWith(incidents, this.trafficIncidents.incidents, isEqual);
      if (diff.length > 0 || incidents.length == 0) {
        let cont = 0;
        incidents.forEach((incident) => {
          if (this.trafficIncidents.incidents.indexOf(incident) === -1) {
            cont++;
            new_incidents.push(incident);
          }
        });
        this.setTrafficIncidents({
          incidents,
          first_read: firstRead,
          new_incidents,
          readed: false,
          new: cont,
        });
      }
    },
  },
  created() {
    let timeToCheck = 60;
    if (process.env.NODE_ENV !== 'production' && this.mode == 'test') {
      timeToCheck = 30;
    }
    this.interval = setInterval(() => {
      this.loadIncidents();
    }, 1000 * timeToCheck);
    // Incidents closed in Com s'hi va meet
    this.setTrafficIncidentsForceClose(true);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
