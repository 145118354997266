export const dummy = {};

export const getApiUri = () => {
  return `${process.env.VUE_APP_API_URL}`;

  /*
  const hostname = process.env.VUE_APP_BACKEND_ADDRESS || window.location.hostname;
  let port = process.env.VUE_APP_BACKEND_PORT;
  const protocol = process.env.VUE_APP_BACKEND_PROTOCOL;
  const suffix = process.env.VUE_APP_BACKEND_API_SUFFIX;

  let uri = `${protocol}://${hostname}`;
  if (port.length > 0) {
    return `${uri}:${port}${suffix}`; // do not put ending slash
  }
  return `${uri}${suffix}`; // do not put ending slash
  */
};
