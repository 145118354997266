import { merge, findIndex } from 'lodash';

const state = () => ({
  mapObj: null,
  mapFullScreen: false,
  geolocationActive: false,
  currentPos: { lat: 41.385, lng: 2.173 },
  markerCurrentPos: null,
  dataLayers: {
    active: false,
  },
  activeLayers: [],
});

const mutations = {
  setMap(state, map) {
    state.mapObj = map;
  },
  setMapFullScreen(state, mapFullScreen) {
    state.mapFullScreen = mapFullScreen;
  },

  setGeolocationActive(state, geolocationActive) {
    state.geolocationActive = geolocationActive;
  },

  setCurrentPos(state, newPos) {
    state.currentPos = newPos;
  },

  setMarkerCurrentPos(state, markerCurrentPos) {
    state.markerCurrentPos = markerCurrentPos;
  },

  // setDataLayers
  // setActiveLayers

  cleanActiveLayers(state, clearInterestLayers = true) {
    const interestLayers = [
      'traficoactual_gen',
      'near_public_transport',
      'bajasemisiones_general',
      'transport_accessible',
    ];
    for (const c in state.activeLayers) {
      if (interestLayers.indexOf(state.activeLayers[c].code) < 0 || clearInterestLayers) {
        state.activeLayers.splice(c, 1);
      }
    }
  },

  addActiveLayer(state, newLayer) {
    if (findIndex(state.activeLayers, (o) => o == newLayer) < 0) {
      state.activeLayers.push(newLayer);
    }
  },

  setActiveLayers(state, layers) {
    state.activeLayers = layers;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
