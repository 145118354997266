<template>
  <div class="route" :class="travelMode">
    <!-- Route header transit -->
    <div class="header" :id="`routeHeader_${routeIndex}`"
         @click="toggleRoute()"
         v-if="travelMode=='TRANSIT'"
         v-touch:swipe.down="onSwipeDown"
         v-touch:swipe.up="onSwipeUp">
      <div class="tit"><span>{{title}}</span> <div class="float-right">{{route.legs[0].duration.text}}</div></div>
      <div class="clearfix"></div>
      <div class="arrival_time" v-if="route.legs[0].arrival_time"><span class="bcn-icon-rellotge"></span> {{route.legs[0].arrival_time.text}}</div>
      <div
        class="step"
        v-for="(step, index_step) in route.legs[0].steps"
        v-bind:key="index_step"
        :class="{'first': index_step==0}"
      >
        <span v-if="step.travel_mode=='TRANSIT'">
          <img class="icon" :src="getTransitIcon(step)" v-if="step.transit.line.vehicle.type!='BUS'">
          <img class="icon" :src="step.transit.line.vehicle.icon" v-if="step.transit.line.vehicle.type=='BUS'">
          <div class="line" :style="{'background-color':step.transit.line.color, 'color':step.transit.line.text_color}">{{step.transit.line.short_name}}</div>
        </span>
        <span v-if="step.travel_mode=='WALKING'">
          <img class="icon walking" src="https://maps.gstatic.com/mapfiles/transit/iw2/6/walk.png">
        </span>
      </div>
      <div class="share-zone" :class="{'without-text': type!='sostenible'}">
        <div class="sostenible" v-if="type=='sostenible' && diffTime<=30">{{$t('sustainable_message_1')}} {{getDiffTime()}} {{$t('sustainable_message_2')}}.</div>
        <div class="sostenible" v-if="type=='sostenible' && diffTime>30">{{$t('sustainable_message_default')}}</div>
        <div class="share-icon bcn-icon-comparteix" @click="share"></div>
      </div>
    </div>
    <!-- Route header not transit -->
    <div class="header not_transit" :id="`routeHeader_${routeIndex}`" v-if="travelMode!='TRANSIT'" @click="toggleRoute()" >
      <div class="tit"><span>{{title}}</span>  <div class="float-right">{{route.legs[0].duration.text}}</div></div>
      <div class="clearfix"></div>
      <div class="arrival_time"><span class="bcn-icon-rellotge"></span> {{getArrivalTime(route.legs[0].duration.value)}}</div>
      <div class="desc">
        <img class="icon" :src="getIconTravelMode()">
        <span>{{route.summary}}</span>
        <span class="distance">({{route.legs[0].distance.text}})</span>
      </div>
      <div class="share-zone without-text">
        <div class="share-icon bcn-icon-comparteix" @click="share"></div>
      </div>
    </div>
    <!-- Route detail transit -->
    <div class="route-detail transit" :class="{'collapse': !opened}" v-if="travelMode=='TRANSIT'">
      <div
        class="step"
        v-for="(step, index_step) in route.legs[0].steps"
        v-bind:key="index_step"
        :class="getStepStatus(step, index_step)"
        :style="getStepBorder(step)"
      >
        <div>
          <div class="tit" v-if="step.travel_mode=='WALKING' && index_step==0">{{route.legs[0].start_address}}</div>
          <div class="walking_desc" v-if="step.travel_mode=='WALKING'">
            <span><img class="icon walking" src="https://maps.gstatic.com/mapfiles/transit/iw2/6/walk.png"></span>
            <span>
              <div class="instructions">{{step.instructions}}</div>
              <div class="walking_time_step" v-if="step.travel_mode=='WALKING'">{{step.duration.text}} ({{step.distance.text}})</div>
            </span>
          </div>

          <div class="tit" v-if="step.travel_mode=='TRANSIT'"><span class="time">{{step.transit.departure_time.text}}</span>{{step.transit.departure_stop.name}}</div>
          <div v-if="step.travel_mode=='TRANSIT'" class="transit-info">
            <div class="mr-1 nowrap">
              <img class="icon mr-1" :src="step.transit.line.vehicle.local_icon" v-if="step.transit.line.vehicle.type!='BUS'">
              <img class="icon" :src="step.transit.line.vehicle.icon" v-if="step.transit.line.vehicle.type=='BUS'">
              <div class="line" :style="{'background-color':step.transit.line.color, 'color':step.transit.line.text_color}">{{step.transit.line.short_name}}</div>
            </div>
            <div>
              <span class="instructions">
                {{step.instructions}}
                <div class="transit_time_step">{{step.duration.text}} ({{step.transit.num_stops}} parades)</div>
                <div class="alert-alteration" v-if="alterationAlertVisible(step.transit.line.short_name)">
                  <span class="csvicon-alerta"></span>
                  <a :href="alterationAlertVisible(step.transit.line.short_name).links[$i18n.locale]" target="_blank">
                    {{ alterationAlertVisible(step.transit.line.short_name).missatges[$i18n.locale] }}
                  </a>
                </div>
              </span>
            </div>
          </div>
          <div class="tit" v-if="step.travel_mode=='TRANSIT'"><span class="time">{{step.transit.arrival_time.text}}</span>{{step.transit.arrival_stop.name}}</div>
          <div class="tit last" v-if="step.travel_mode=='WALKING' && index_step==route.legs[0].steps.length-1"><span class="time">{{route.legs[0].arrival_time.text}}</span>{{route.legs[0].end_address}}</div>
        </div>
      </div>
    </div>
    <!-- Route detail not transit -->
    <div class="route-detail no-transit" :class="{'collapse': !opened}" v-if="travelMode!='TRANSIT'">
      <div
        class="step"
        v-for="(step, index_step) in route.legs[0].steps"
        v-bind:key="index_step"
        :class="getStepStatus(step, index_step)"
      >
        <div>
          <div class="adp-maneuver" :class="getManeuverIcon(step)"></div>
          <div class="instructions">
            <span v-html="step.instructions"></span>
            <div class="distance">{{step.distance.text}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Duration, DateTime } from 'luxon';
import { app } from '../main';
import { getApiUri } from '../api/util';
import api from '../api/api';

export default {
  name: 'RouteComponent',
  props: ['route', 'routeIndex', 'travelMode', 'title', 'type', 'suggestedRoute'],
  data() {
    return {
      opened: false,
      diffTime: 0,
    };
  },
  computed: {
    ...mapState('planroute', {
      panelOpened: 'panelOpened',
    }),
    ...mapState('transport', [
      'alterationMessages',
    ]),
  },
  methods: {
    getStepBorder(step) {
      let borderColor = '#707070';
      if (step.travel_mode == 'TRANSIT' && step.transit.line.color != '#ffffff') borderColor = step.transit.line.color;
      return {
        'border-left-style': (step.travel_mode == 'WALKING') ? 'dotted' : 'solid',
        'border-left-color': borderColor,
      };
    },
    getStepStatus(step, index_step) {
      return {
        first: index_step == 0,
        last: index_step == this.route.legs[0].steps.length - 1,
        'walking-intermediate': step.travel_mode == 'WALKING' && index_step > 0 && index_step < this.route.legs[0].steps.length - 1,
      };
    },
    getTransitIcon(step) {
      if (step.transit.line.vehicle.local_icon) {
        return step.transit.line.vehicle.local_icon;
      }
      return step.transit.line.vehicle.icon;
    },
    getIconTravelMode() {
      const url = 'https://maps.gstatic.com/tactile/pane/travel-modes/';
      let icon = 'walk';
      if (this.travelMode == 'DRIVING') {
        icon = 'drive';
      } else if (this.travelMode == 'BICYCLING') {
        icon = 'bike';
      } else if (this.travelMode == 'TRANSIT') {
        icon = 'transit';
      }
      return `${url + icon}_1x.png`;
    },
    getArrivalTime(duration) {
      const now = DateTime.local();
      const arrivalTime = now.plus({ seconds: duration });
      return arrivalTime.toLocaleString(DateTime.TIME_24_SIMPLE);
    },
    getManeuverIcon(step) {
      return `adp-${step.maneuver}`;
    },
    onSwipeDown() {
      if (this.routeIndex == 0 && this.panelOpened) {
        this.$root.$children[0].$refs.routePanel.onSwipeDown();
      }
    },
    onSwipeUp() {
      if (this.routeIndex == 0 && !this.panelOpened) {
        this.$root.$children[0].$refs.routePanel.onSwipeUp();
      }
    },
    toggleRoute() {
      if (this.routeIndex == 0 && !this.panelOpened) {
        this.$root.$children[0].$refs.routePanel.onSwipeUp();
        return;
      }
      this.opened = !this.opened;
      const { directionsRenderer } = app.$children[0].$refs.planificador;
      directionsRenderer.setRouteIndex(this.routeIndex);
    },
    alterationAlertVisible(line) {
      if (line == undefined) return false;
      for (const c in this.alterationMessages) {
        const incidencia = this.alterationMessages[c];
        if (incidencia.linia.toLowerCase() == line.toLowerCase()
          && new Date(incidencia.data_inici) < new Date()
          && new Date(incidencia.data_fi) > new Date()) {
          return incidencia;
        }
      }
      return false;
    },
    share() {
      const start_adress = this.route.legs[0].start_address;
      const end_adress = this.route.legs[0].end_address;
      const origin = `${this.route.legs[0].start_location.lat()},${this.route.legs[0].start_location.lng()}`;
      const destination = `${this.route.legs[0].end_location.lat()},${this.route.legs[0].end_location.lng()}`;
      const params = [];
      params.push(`origin=${origin}`);
      params.push(`destination=${destination}`);
      params.push(`origin_txt=${start_adress}`);
      params.push(`destination_txt=${end_adress}`);
      params.push(`travelMode=${this.travelMode}`);
      const directory = process.env.VUE_APP_PUBLIC_PATH.substr(1);
      const url = `${process.env.VUE_APP_BASE_URL}${directory}${this.$i18n.locale}?${encodeURIComponent(params.join('&'))}`;
      api.get(`${getApiUri()}services/short_url/?url=${url}`).then((response) => {
        const shareData = {
          title: 'Com s\'hi va',
          text: `${this.$t('go_from')} ${start_adress} ${this.$t('to')} ${end_adress}`,
          url: response.data.short_url,
        };
        navigator.share(shareData);
      });
    },
    getDiffTime() {
      const durationRoute = this.suggestedRoute.legs[0].duration.value;
      const durationSostenibleRoute = this.route.legs[0].duration.value;
      const diff = durationSostenibleRoute - durationRoute;
      this.diffTime = diff / 60;
      const diffDuration = Duration.fromMillis(Math.abs(diff) * 1000).shiftTo('hours', 'minutes', 'seconds');
      let { hours } = diffDuration;
      let { minutes } = diffDuration;
      if (diffDuration.seconds > 30) {
        minutes += 1;
      }
      if (diffDuration.minutes == 60) {
        minutes = 0;
        hours += 1;
      }
      let textos = (diff > 0) ? `${this.$t('only')} ` : '';
      if (minutes > 0) {
        textos += `${minutes} ${this.$t('minutes')}`;
      }
      if (hours > 0) textos = `${hours} ${this.$t('hours')} ${this.$t('and')} ${textos}`;
      const moreOrLess = (diff > 0) ? this.$t('longer_than') : this.$t('less_than');
      textos = `${textos} ${moreOrLess}`;
      if (hours == 0 && minutes == 0) {
        textos = this.$t('same_time');
      }
      return textos;
    },
  },
  mounted() {
    // noop
  },
};
</script>
