import { merge } from 'lodash';

const state = () => ({
  infoPanel: {
    type: '',
    visible: false,
    foto: null,
    code: '',
    icon_title: null,
    feature: null,
    linea: null,
    lineas: [],
    lineas_bus: [],
    lineas_tram: [],
    accessible: false,
    datos: [],
    bustimes: [],
    traintimes: [],
  },
});

const mutations = {
  setInfoPanel(state, infoPanel) {
    state.infoPanel = merge({}, state.infoPanel, infoPanel);
  },

  initInfoPanel(state) {
    state.infoPanel = {
      type: '',
      code: '',
      visible: false,
      foto: null,
      feature: null,
      linea: null,
      icon_title: null,
      lineas: [],
      lineas_bus: [],
      lineas_tram: [],
      accessible: false,
      datos: [],
      bustimes: [],
      traintimes: [],
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
