import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../components/Home.vue';
import Interior from '../components/Interior.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/', redirect: '/ca' },
  {
    path: '/:lang/',
    component: {
      template: '<router-view></router-view>',
    },
    children: [
      { path: '/', component: Home },
      { path: '/interior', component: Interior },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  const ga = window.ga || (() => {});
  ga('set', 'page', to.fullPath);
  ga('send', 'pageview');
});

export default router;
