const state = () => ({
  opened: false,
  viewed: false,
});

const mutations = {
  setViewed(state, viewed) {
    state.viewed = viewed;
    localStorage.helpViewed = viewed;
  },
  setOpened(state, opened) {
    state.opened = opened;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
