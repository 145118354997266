<template>
  <div id="layerMenu">
    <transition enter-active-class="animated fadeIn slideInRight" leave-active-class="animated fadeOut slideOutRight">
      <div class="menu-content" v-if="layerMenuStatus.opened" :style="menuHeight" v-touch:swipe.right="toggleMenu">
        <div class="closeButton" @click="toggleMenu"></div>
        <div class="container-menu">
          <h1>{{$t('shows_on_map')}}</h1>

          <search ref="layermenuSearch" :placeholder="$t('search_info')"  layers="all"></search>

          <!-- Informació activa -->
          <div v-if="activeLayers.length>0">
            <div class="tit-activa">{{$t('active_information')}}</div>
            <ul class="seleccion">
              <li class="mr-1 mb-1" v-for="(item, index) in activeLayers" v-bind:key="index">
                <span v-if="item.code!=undefined">
                  <span :class="getIcon(item)"></span>
                  <span class="ml-2 bcn-icon-tancar-medium" @click="selectOpcion(item)"></span>
                  <span class="text">{{$t(item.nombre)}}</span>
                </span>
                <span v-if="item.code==undefined">
                  <span :class="getIconActiveLayers(item)"></span>
                  <span class="ml-2 bcn-icon-tancar-medium" @click="selectTransportLine(item, false)"></span>
                  <span class="text">{{item.linea}} - {{item.descripcio}}</span>
                </span>
              </li>
            </ul>
          </div>

          <!-- Menu options -->
          <ul class="menu" v-show="!activeSearch">
            <!-- First level -->
            <li v-for="(grupo, index) in layerMenu" v-bind:key="index">
              <div>
                <hr>
              </div>
              <div class="tit" :class="hasOptions(grupo)" v-b-toggle="'grupo'+index">{{$t(grupo.grupo)}}</div>
              <b-collapse tag="ul" :id="'grupo'+index">
                <!-- Second level -->
                <li>
                  <p-check class="p-icon opcion pt-0" @change="selectAll(grupo)" v-model="grupo.select_all.selected" v-if="grupo.select_all">
                    <i class="icon bcn-icon-ok-bold" slot="extra"></i>
                    {{$t(grupo.select_all.text)}}
                  </p-check>
                </li>
                <li v-for="(opcion, indexopcion) in grupo.options" :class="optionStyle(grupo, opcion)" v-bind:key="indexopcion">
                  <div @click="selectOpcion(opcion)"
                       class="opcion" :class="hasOptions(opcion, grupo)"
                       v-b-toggle="'opcion'+index+'_'+indexopcion">
                    <span class="bcn-icon-ok-medium" v-if="opcion.selected && opcion.suboptions==undefined && (grupo.code!='transport' || opcion.code=='taxi')"></span>
                    <span :class="{'selected': opcion.selected}">{{$t(opcion.nombre)}}</span>
                  </div>
                  <!-- Third level general -->
                  <b-collapse tag="ul" :id="'opcion'+index+'_'+indexopcion" v-if="grupo.code!='transport'">
                    <li v-for="(subopcion, indexsubopcion) in opcion.options" @click="selectOpcion(subopcion)" v-bind:key="indexsubopcion">
                      <span class="bcn-icon-ok-medium" v-if="subopcion.selected"></span>
                      <span>{{$t(subopcion.nombre)}}</span>
                    </li>
                  </b-collapse>

                  <!-- Third level metro -->
                  <b-collapse tag="ul" :id="'opcion'+index+'_'+indexopcion" v-if="opcion.code=='metro'">
                    <li class="mb-2 d-block">
                      <p-check class="p-icon pt-0 pretty-transport"
                               @change="selectLineAll(opcion.select_all.type, opcion.select_all.selected)"
                               v-model="opcion.select_all.selected"
                               v-if="opcion.select_all">
                        <i class="icon bcn-icon-ok-bold" slot="extra"></i>
                        {{$t(opcion.select_all.text)}}
                      </p-check>
                    </li>
                    <li class="linea"
                        :class="{'selected': linea.selected}"
                        v-for="(linea, indexsubopcion) in lineas.metro"
                        @click="toggleLine(linea)"
                        v-bind:key="indexsubopcion"
                    >
                      <span class="bcn-icon-ok-medium" v-if="linea.selected"></span>
                      <span><img class="logo-metro" :src="require(`@/assets/img/metro/${linea.linea}.png`)"></span>
                      <span class="text">{{linea.descripcio}}</span>
                    </li>
                  </b-collapse>

                  <!-- Third level bus -->
                  <b-collapse :id="'opcion'+index+'_'+indexopcion" v-if="opcion.code=='bus'">
                    <ul>
                      <li class="mb-2 d-block">
                        <p-check class="p-icon pt-0 pretty-transport"
                                 @change="selectLineAll(opcion.select_all.type, opcion.select_all.selected)"
                                 v-model="opcion.select_all.selected"
                                 v-if="opcion.select_all">
                          <i class="icon bcn-icon-ok-bold" slot="extra"></i>
                          {{$t(opcion.select_all.text)}}
                        </p-check>
                      </li>
                      <li class="linea"
                          v-for="(linea, indexsubopcion) in lineas.bus"
                          :class="{'selected': linea.selected}"
                          @click="toggleLine(linea)"
                          v-bind:key="indexsubopcion">
                        <span class="bcn-icon-ok-medium" v-if="linea.selected"></span>
                        <span><span class="marca" :style="{'background-color': '#'+linea.color}">{{linea.linea}}</span></span>
                        <span class="text">{{linea.descripcio}}</span>
                      </li>
                    </ul>
                  </b-collapse>

                  <!-- Third level bus turistic -->
                  <b-collapse :id="'opcion'+index+'_'+indexopcion" v-if="opcion.code=='tourist_bus'">
                    <ul>
                      <li class="linea"
                          v-for="(linea, indexsubopcion) in getBusTuristicLines(lineas.bus)"
                          :class="{'selected': linea.selected}"
                          @click="toggleLine(linea)"
                          v-bind:key="indexsubopcion">
                        <span class="bcn-icon-ok-medium" v-if="linea.selected"></span>
                        <span><span class="marca" :style="{'background-color': '#'+linea.color}">{{linea.linea}}</span></span>
                        <span class="text">{{linea.descripcio}}</span>
                      </li>
                    </ul>
                  </b-collapse>

                  <!-- Third level Rodalies -->
                  <b-collapse tag="ul" :id="'opcion'+index+'_'+indexopcion" v-if="opcion.code=='rodalies'">
                    <ul>
                      <li class="mb-2 d-block">
                        <p-check class="p-icon pt-0 pretty-transport"
                                 @change="selectLineAll(opcion.select_all.type, opcion.select_all.selected)"
                                 v-model="opcion.select_all.selected"
                                 v-if="opcion.select_all">
                          <i class="icon bcn-icon-ok-bold" slot="extra"></i>
                          {{$t(opcion.select_all.text)}}
                        </p-check>
                      </li>
                      <li class="linea"
                          v-for="(linea, indexsubopcion) in lineas.rodalies"
                          :class="{'selected': linea.selected}"
                          @click="toggleLine(linea)"
                          v-bind:key="indexsubopcion">
                        <span class="bcn-icon-ok-medium" v-if="linea.selected"></span>
                        <span><img class="logo-metro" :src="require(`@/assets/img/rodalies/${linea.linea}.png`)"></span>
                        <span class="text">{{linea.descripcio}}</span>
                      </li>
                    </ul>
                  </b-collapse>

                  <!-- Third level FGC -->
                  <b-collapse tag="ul" :id="'opcion'+index+'_'+indexopcion" v-if="opcion.code=='ferrocarril'">
                    <ul>
                      <li class="mb-2 d-block">
                        <p-check class="p-icon pt-0 pretty-transport"
                                 @change="selectLineAll(opcion.select_all.type, opcion.select_all.selected)"
                                 v-model="opcion.select_all.selected"
                                 v-if="opcion.select_all">
                          <i class="icon bcn-icon-ok-bold" slot="extra"></i>
                          {{$t(opcion.select_all.text)}}
                        </p-check>
                      </li>
                      <li class="linea"
                          v-for="(linea, indexsubopcion) in lineas.ferrocarril"
                          :class="{'selected': linea.selected}"
                          @click="toggleLine(linea)"
                          v-bind:key="indexsubopcion">
                        <span class="bcn-icon-ok-medium" v-if="linea.selected"></span>
                        <span><img class="logo-fgc" :src="require(`@/assets/img/fgc/${linea.linea}.png`)"></span>
                        <span class="text">{{linea.descripcio}}</span>
                      </li>
                    </ul>
                  </b-collapse>

                  <!-- Third level Tram -->
                  <b-collapse :id="'opcion'+index+'_'+indexopcion" v-if="opcion.code=='tram'">
                    <ul>
                      <li class="mb-2 d-block">
                        <p-check class="p-icon pt-0 pretty-transport"
                                 @change="selectLineAll(opcion.select_all.type, opcion.select_all.selected)"
                                 v-model="opcion.select_all.selected"
                                 v-if="opcion.select_all">
                          <i class="icon bcn-icon-ok-bold" slot="extra"></i>
                          {{$t(opcion.select_all.text)}}
                        </p-check>
                      </li>
                      <li class="linea"
                          v-for="(linea, indexsubopcion) in lineas.tram"
                          :class="{'selected': linea.selected}"
                          @click="toggleLine(linea)"
                          v-bind:key="indexsubopcion">
                        <span class="bcn-icon-ok-medium" v-if="linea.selected"></span>
                        <span><img class="logo-metro" src="@/assets/img/logo-tram.png"></span>
                        <span class="text">{{linea.linea}} - {{linea.descripcio}}</span>
                      </li>
                    </ul>
                  </b-collapse>

                  <!-- Third level Aerobus -->
                  <b-collapse :id="'opcion'+index+'_'+indexopcion" v-if="opcion.code=='aerobus'">
                    <ul>
                      <li class="linea"
                        v-for="(linea, indexsubopcion) in lineas.aerobus"
                        :class="{'selected': linea.selected}"
                        @click="toggleLine(linea)"
                        v-bind:key="indexsubopcion">
                        <span class="bcn-icon-ok-medium" v-if="linea.selected"></span>
                        <span><span class="marca" :style="{'background-color': '#'+linea.color}">{{linea.linea}}</span></span>
                        <span class="text">{{linea.descripcio}}</span>
                      </li>
                    </ul>
                  </b-collapse>

                  <!-- Third level Funicular -->
                  <b-collapse :id="'opcion'+index+'_'+indexopcion" v-if="opcion.code=='funicular'">
                    <ul>
                      <li class="linea"
                        v-for="(linea, indexsubopcion) in lineas.funicular"
                        :class="{'selected': linea.selected}"
                        @click="toggleLine(linea)"
                        v-bind:key="indexsubopcion">
                        <span class="bcn-icon-ok-medium" v-if="linea.selected"></span>
                        <span><img class="logo-metro" :src="require(`@/assets/img/${linea.logo}`)"></span>
                        <span class="text">{{linea.linea}} - {{linea.descripcio}}</span>
                      </li>
                    </ul>
                  </b-collapse>
                </li>
              </b-collapse>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  find,
  clone,
  cloneDeep,
  findIndex,
} from 'lodash';
import { mapState, mapMutations } from 'vuex';
import { TransportMixins } from './mixins/TransportMixins';
import { LayerMixins } from './mixins/LayerMixins';
import Search from './Search.vue';

export default {
  name: 'LayerMenu',
  mixins: [
    TransportMixins,
    LayerMixins,
  ],
  components: {
    Search,
  },
  data() {
    return {
      searchWord: '',
      awaitingSearch: false,
      directionIcons: [],
    };
  },
  computed: {
    menuHeight() {
      const height = $(window).height() - $('#brand').height();
      return { height: `${height}px` };
    },
    level2Height() {
      const height = $(window).height() - $('#brand').height() - 30;
      return { height: `${height}px` };
    },
    ...mapState('infopanel', [
      'infoPanel',
    ]),
    ...mapState('menu', [
      'layerMenuStatus',
      'layerMenu',
    ]),
    ...mapState('search', [
      'searchResults',
      'activeSearch',
    ]),
    ...mapState('transport', [
      'lineas',
    ]),
    ...mapState('map', [
      'mapObj',
      'activeLayers',
    ]),
  },
  methods: {
    ...mapMutations({
      setActiveLayers: 'map/setActiveLayers',
      addActiveLayer: 'map/addActiveLayer',
      setInfoPanel: 'infopanel/setInfoPanel',
      setLineas: 'transport/setLineas',
      setLayerMenuStatus: 'menu/setLayerMenuStatus',
    }),
    toggleMenu() {
      this.setInfoPanel({
        visible: false,
      });
      this.setLayerMenuStatus({
        opened: !this.layerMenuStatus.opened,
      });
    },
    hasOptions(option, grupo) {
      return {
        collapsed: (option.options && option.options.length > 0) || (grupo.code == 'transport' && option.code != 'taxi'),
        'has-options': (option.options && option.options.length > 0) || (grupo.code == 'transport' && option.code != 'taxi'),
      };
    },
    optionStyle(opcion) {
      return {
        'interes-general': opcion.code == 'interes-general',
        selected: opcion.selected,
      };
    },
    selectAll(grupo) {
      if (grupo.select_all.type == 'general') {
        grupo.options.forEach((option) => {
          if (option.url != undefined) {
            option.selected = grupo.select_all.selected;
            this.activateLayer(option);
          }
          if (option.options) {
            option.options.forEach((suboption) => {
              if (suboption.url != undefined) {
                suboption.selected = grupo.select_all.selected;
                this.activateLayer(suboption);
              }
            });
          }
        });
      } else {
        this.selectAllTransport(grupo.select_all.selected);
      }
    },
    toggleLine(linea) {
      linea.selected = !linea.selected;
      this.selectTransportLine(linea);
    },
    getLinea(tipo, name) {
      if (tipo == 'metro') {
        return LineaTransportMetro.getLinea(name);
      }
      if (tipo == 'bus') {
        return LineaTransportBus.getLinea(name);
      }
    },
    getColor(linea) {
      if (linea.type == 'metro') {
        return LineaTransportMetro.getColor(linea.linea);
      }
      if (linea.type == 'bus') {
        return LineaTransportBus.getColor(linea.linea);
      }
    },
  },
};

</script>
