export default [
  {
    order: 0,
    grupo: 'general_interest',
    code: 'interes-general',
    options: [
      {
        nombre: 'turisme',
        icon: 'csvicon-information',
        code: 'turisme',
        url: 'turisme/',
        maxZoomClusteringLevel: 16,
        clusterMarker: 'turisme.svg',
        adaptativeMarkers: true,
        minZoomLevel: 10,
        iconFolder: 'apie',
        markerIcon: 'turisme.svg',
        displayInfo: true,
        infoTitle: 'name',
        info: [
          { label: 'address', value: 'address' },
          { label: 'district', value: 'district' },
        ],
      },
      {
        nombre: 'traffic_state',
        icon: 'csvicon-car',
        code: 'traficoactual_gen',
        url: 'tramstransit/',
        idField: 'id',
        style: {
          strokeColor: '#cccccc',
          strokeOpacity: 1,
          strokeWeight: 3,
        },
      },
      {
        nombre: 'near_public_transport',
        icon: 'csvicon-public-transport',
        code: 'near_public_transport',
      },
      {
        nombre: 'low_emissions_zone',
        icon: 'csvicon-low-emissions',
        code: 'bajasemisiones_general',
        url: 'bajasemisiones/',
        style: {
          strokeColor: '#f0b14a',
          strokeOpacity: 1,
          strokeWeight: 4,
        },
      },
      {
        nombre: 'accessible_transport',
        icon: 'csvicon-wheelchair',
        code: 'transport_accessible',
      },
    ],
  },
  {
    order: 1,
    grupo: 'walk',
    modo: 'WALKING',
    code: 'walk',
    translations: 'walkTranslations',
    icon: 'csvicon-walk',
    options: [
      {
        nombre: 'pedestrian_zones',
        code: 'zonaspeatonales',
        url: 'zonesvianants/',
        minZoomLevel: 10,
        style: {
          strokeColor: '#4285F4',
          strokeOpacity: 1,
          strokeWeight: 1,
          fillColor: '#4285F4',
        },
      },
      {
        nombre: 'zones_30',
        code: 'zonas30',
        url: 'zones30/',
        minZoomLevel: 10,
        style: {
          strokeColor: '#70B8AF',
          strokeOpacity: 1,
          strokeWeight: 0,
          fillColor: '#70B8AF',
        },
      },
      {
        nombre: 'escalators',
        code: 'escalerasmecanicas',
        url: 'escalesmecaniques/',
        maxZoomClusteringLevel: 16,
        clusterMarker: 'escales_mecaniques.svg',
        adaptativeMarkers: true,
        minZoomLevel: 10,
        iconFolder: 'apie',
        markerIcon: 'escales_mecaniques.svg',
        displayInfo: true,
        infoTitle: 'name',
        info: [
          { label: 'address', value: 'address' },
          { label: 'district', value: 'district' },
          { label: 'url', value: 'code_url', type: 'url' },
        ],
      },
      {
        nombre: 'traffic_calmed_streets',
        code: 'carrerspacificats',
        url: 'carrerspacificats/',
        minZoomLevel: 10,
        style: {
          strokeColor: '#741f7d',
          strokeOpacity: 1,
          strokeWeight: 2,
        },
      },
      {
        nombre: 'widened_pavements',
        code: 'ampliaciovoreres',
        url: 'ampliaciovoreres/',
        minZoomLevel: 10,
        style: {
          strokeColor: '#F08178',
          strokeOpacity: 1,
          strokeWeight: 2,
        },
      },
      // {
      //   nombre: 'sides_roads_closed_traffic',
      //   code: 'lateralstallats',
      //   url: 'lateralstallats/',
      //   minZoomLevel: 10,
      //   style: {
      //     strokeColor: '#5B5B5B',
      //     strokeOpacity: 1,
      //     strokeWeight: 2,
      //   },
      // },
    ],
  },
  {
    order: 2,
    grupo: 'bici',
    code: 'bici',
    modo: 'BICYCLING',
    translations: 'biciTranslations',
    icon: 'csvicon-bici',
    select_all: {
      type: 'general',
      text: 'select_all',
    },
    options: [
      {
        nombre: 'cycle_lanes',
        code: 'carrilbicigrupo',
        options: [
          {
            nombre: 'cycle_lanes',
            code: 'carrilsbici',
            url: 'carrilbici/',
            minZoomLevel: 10,
            style: {
              strokeColor: '#088106',
              strokeOpacity: 1,
              strokeWeight: 2,
            },
          },
          {
            nombre: 'new_bicycle_lanes',
            code: 'corredorsbici',
            url: 'corredorsbici/',
            minZoomLevel: 10,
            style: {
              strokeColor: '#d49f07',
              strokeOpacity: 1,
              strokeWeight: 2,
            },
          },
          {
            nombre: 'cycle_lanes_under_construction',
            code: 'carrilbiciconstruccion',
            url: 'carrilbiciconstruccio/',
            minZoomLevel: 10,
            style: {
              strokeColor: '#D871BB',
              strokeOpacity: 1,
              strokeWeight: 2,
            },
          },
          {
            nombre: 'zones_30',
            code: 'zonas30',
            url: 'zones30/',
            minZoomLevel: 10,
            style: {
              strokeColor: '#70B8AF',
              strokeOpacity: 1,
              strokeWeight: 0,
              fillColor: '#70B8AF',
            },
          },
          {
            nombre: 'roads_suitable_for_cycling',
            code: 'viasciclables',
            url: 'viesciclables/',
            minZoomLevel: 10,
            style: {
              strokeColor: 'grey',
              strokeOpacity: 1,
              strokeWeight: 2,
            },
          },
          {
            nombre: 'green_route',
            code: 'rondaverde',
            url: 'rondaverda/',
            minZoomLevel: 10,
            style: {
              strokeColor: '#6C9F43',
              strokeOpacity: 1,
              strokeWeight: 2,
            },
          },
        ],
      },
      {
        nombre: 'car_parks',
        code: 'puntosanclajebici',
        options: [
          {
            nombre: 'bicycle_anchoring_points',
            code: 'puntosanclajebicis',
            url: 'puntsancoratgebicicletes/',
            maxZoomClusteringLevel: 16,
            clusterMarker: 'anclaje.svg',
            adaptativeMarkers: true,
            minZoomLevel: 10,
            iconFolder: 'bicicleta',
            markerIcon: 'anclaje.svg',
            displayInfo: true,
            infoTitle: 'name',
            info: [
              { label: 'address', value: 'address' },
              { label: 'district', value: 'district' },
              { label: 'phonenumber', value: 'phonenumber' },
              { label: 'url', value: 'code_url', type: 'url' },
            ],
          },
          {
            nombre: 'car_parks_with_bicycle_places',
            code: 'aparcamientosserviciobicis',
            url: 'aparcamentsserveibicis/',
            maxZoomClusteringLevel: 16,
            clusterMarker: 'parking_bici.svg',
            adaptativeMarkers: true,
            minZoomLevel: 10,
            iconFolder: 'bicicleta',
            markerIcon: 'parking_bici.svg',
            displayInfo: true,
            infoTitle: 'name',
            info: [
              { label: 'address', value: 'address' },
              { label: 'district', value: 'district' },
              { label: 'phonenumber', value: 'phonenumber' },
              { label: 'url', value: 'code_url', type: 'url' },
            ],
          },
        ],
      },
      // {
      //   nombre: 'Bicing',
      //   code: 'bicing',
      //   url: 'bicingelectric/',
      //   maxZoomClusteringLevel: 16,
      //   clusterMarker: 'bicing.svg',
      //   adaptativeMarkers: true,
      //   minZoomLevel: 10,
      //   iconFolder: 'bicicleta',
      //   markerIcon: 'bicing.svg',
      //   displayInfo: true,
      //   infoTitle: 'street',
      //   info: [
      //     { label: 'free_bycicle_slots', value: 'slots' },
      //     { label: 'mechanical_bycicles', value: 'mechanicalBikes' },
      //     { label: 'electrical_bycicles', value: 'electricalBikes' },
      //   ],
      // },
      {
        nombre: 'bicycle_hire',
        code: 'lloguerbici',
        url: 'lloguerbicis/',
        maxZoomClusteringLevel: 16,
        clusterMarker: 'alquiler_bici.svg',
        adaptativeMarkers: true,
        minZoomLevel: 10,
        iconFolder: 'bicicleta',
        markerIcon: 'alquiler_bici.svg',
        displayInfo: true,
        infoTitle: 'name',
        info: [
          { label: 'address', value: 'address' },
          { label: 'district', value: 'district' },
          { label: 'phonenumber', value: 'phonenumber' },
          { label: 'url', value: 'code_url', type: 'url' },
        ],
      },
      {
        nombre: 'bike_repairs',
        code: 'bicirepair',
        url: 'tallersbici/',
        adaptativeMarkers: true,
        maxZoomClusteringLevel: 16,
        clusterMarker: 'tallers_bici.svg',
        minZoomLevel: 10,
        iconFolder: 'bicicleta',
        markerIcon: 'tallers_bici.svg',
        displayInfo: true,
        infoTitle: 'name',
        info: [
          { label: 'address', value: 'address' },
          { label: 'district', value: 'district' },
          { label: 'phonenumber', value: 'phonenumber' },
          { label: 'url', value: 'code_url', type: 'url' },
        ],
      },
      {
        nombre: 'bici_friends',
        code: 'bicifriends',
        url: 'bicifriends/',
        adaptativeMarkers: true,
        maxZoomClusteringLevel: 16,
        clusterMarker: 'edifici_amicbici_certified.svg',
        minZoomLevel: 10,
        iconFolder: 'bicicleta',
        markerIcon: 'edifici_amicbici_certified.svg',
        displayInfo: true,
        infoTitle: 'edifici',
        info: [
          { label: 'address', value: 'address' },
          { label: 'district', value: 'districte' },
        ],
      },
    ],
  },
  {
    order: 3,
    grupo: 'public_transport',
    translations: 'transportTranslations',
    code: 'transport',
    modo: 'TRANSIT',
    select_all: {
      type: 'transport',
      text: 'select_all',
    },
    options: [
      {
        nombre: 'metro',
        code: 'metro',
        select_all: {
          type: 'metro',
          text: 'see_all_metro_lines',
        },
      },
      {
        nombre: 'bus',
        code: 'bus',
        select_all: {
          type: 'bus',
          text: 'see_all_bus_lines',
        },
      },
      {
        nombre: 'tourist_bus',
        code: 'tourist_bus',
      },
      {
        nombre: 'fgc_trains',
        code: 'ferrocarril',
        select_all: {
          type: 'ferrocarril',
          text: 'see_all_fgc_train_lines',
        },
      },
      {
        nombre: 'local_trains',
        code: 'rodalies',
        select_all: {
          type: 'rodalies',
          text: 'see_all_local_train_lines',
        },
      },
      {
        nombre: 'trams',
        code: 'tram',
        select_all: {
          type: 'tram',
          text: 'see_all_trams_lines',
        },
      },
      {
        nombre: 'aerobus',
        code: 'aerobus',
      },
      {
        nombre: 'funicular',
        code: 'funicular',
      },
      {
        nombre: 'Taxi',
        code: 'taxi',
        url: 'paradestaxi/',
        icon: 'csvicon-car',
        maxZoomClusteringLevel: 16,
        clusterMarker: 'taxi.svg',
        adaptativeMarkers: true,
        minZoomLevel: 10,
        iconFolder: 'publico',
        markerIcon: 'taxi.svg',
        displayInfo: true,
        infoTitle: 'name',
        info: [
          { label: 'address', value: 'address' },
          { label: 'district', value: 'district' },
          { label: 'phonenumber', value: 'phonenumber' },
          { label: 'url', value: 'code_url', type: 'url' },
        ],
      },
    ],
  },
  {
    order: 4,
    grupo: 'car_moto',
    translations: 'trafficTranslations',
    code: 'car',
    modo: 'DRIVING',
    icon: 'csvicon-car',
    select_all: {
      type: 'general',
      text: 'select_all',
    },
    options: [
      {
        nombre: 'traffic_state',
        code: 'traficoactual',
        url: 'tramstransit/',
        idField: 'id',
        style: {
          strokeColor: '#cccccc',
          strokeOpacity: 1,
          strokeWeight: 3,
        },
      },
      {
        nombre: 'traffic_incidents',
        code: 'incidencies',
        options: [
          {
            nombre: 'traffic_jams',
            code: 'retenciones',
            url: 'incidencies/2/',
            minZoomLevel: 10,
            iconFolder: 'trafico',
            markerIcon: 'retenciones.svg',
            adaptativeMarkers: true,
            displayInfo: true,
          },
          {
            nombre: 'traffic_restrictions',
            code: 'afectacions',
            url: 'afectacions/',
            style: {
              strokeColor: '#464343',
              fillColor: '#464343',
              strokeOpacity: 1,
              strokeWeight: 3,
            },
            minZoomLevel: 10,
            iconFolder: 'trafico',
            markerIcon: 'obras.svg',
            adaptativeMarkers: true,
            displayInfo: true,
            infoTitle: 'descripcio',
            info: [
              { label: 'causa', value: 'causa' },
              { label: 'cap_a', value: 'cap_a' },
              { label: 'road', value: 'carretera' },
            ],
          },
          {
            nombre: 'sections_with_cones',
            code: 'tramscons',
            url: 'incidencies/1/',
            iconFolder: 'trafico',
            markerIcon: 'conos.svg',
            minZoomLevel: 10,
            adaptativeMarkers: true,
            displayInfo: true,
          },
        ],
      },
      {
        nombre: 'ring_roads',
        code: 'rondes',
        options: [
          {
            nombre: 'ring_road_entrances',
            code: 'entradasrondas',
            url: 'entradesisortidesrondes/?LLEPOST_I=E',
            minZoomLevel: 10,
            iconFolder: 'trafico',
            markerIcon: 'entrada.svg',
            adaptativeMarkers: true,
            displayInfo: true,
            infoTitle: 'NOM_COMPLE',
            info: [
              { label: 'entry', value: 'NUMPOST_I' },
            ],
          },
          {
            nombre: 'ring_road_exits',
            code: 'salidasrondas',
            url: 'entradesisortidesrondes/?LLEPOST_I=S',
            minZoomLevel: 10,
            iconFolder: 'trafico',
            markerIcon: 'salida.svg',
            adaptativeMarkers: true,
            displayInfo: true,
            infoTitle: 'NOM_COMPLE',
            info: [
              { label: 'exit', value: 'NUMPOST_I' },
            ],
          },
        ],
      },
      {
        nombre: 'car_parks',
        code: 'aparcaments',
        options: [
          {
            nombre: 'car_parks',
            code: 'aparcamientos',
            url: 'aparcaments/',
            iconFolder: 'trafico',
            markerIcon: 'parking.svg',
            maxZoomClusteringLevel: 16,
            clusterMarker: 'parking.svg',
            adaptativeMarkers: true,
            minZoomLevel: 10,
            displayInfo: true,
            infoTitle: 'name',
            info: [
              { label: 'address', value: 'address' },
              { label: 'district', value: 'district' },
              { label: 'phonenumber', value: 'phonenumber' },
              { label: 'url', value: 'code_url', type: 'url' },
            ],
          },
          {
            nombre: 'blue_area',
            code: 'zonasazules',
            url: 'areesaparcaments/?tipus=AZL',
            idField: 'id',
            style: {
              strokeColor: '#0000ff',
              strokeOpacity: 1,
              strokeWeight: 3,
            },
          },
          {
            nombre: 'green_area',
            code: 'zonasverdes',
            url: 'areesaparcaments/?tipus=VM',
            idField: 'id',
            style: {
              strokeColor: '#008000',
              strokeOpacity: 1,
              strokeWeight: 3,
            },
          },
          {
            nombre: 'exclusive_green_area',
            code: 'zonasverdesexclusivas',
            url: 'areesaparcaments/?tipus=VR',
            idField: 'id',
            style: {
              strokeColor: '#00ff00',
              strokeOpacity: 1,
              strokeWeight: 3,
            },
          },
        ],
      },
      {
        nombre: 'sings_and_other_factors',
        code: 'senyals',
        options: [
          {
            nombre: 'traffic_cameras',
            code: 'camarastrafico',
            url: 'camerestransit/',
            maxZoomClusteringLevel: 16,
            clusterMarker: 'camara.svg',
            adaptativeMarkers: true,
            minZoomLevel: 10,
            iconFolder: 'trafico',
            markerIcon: 'camara.svg',
            displayInfo: true,
            infoTitle: 'carretera',
            info: [
              { label: 'municipio', value: 'municipi' },
              { label: 'font', value: 'font' },
            ],
          },
          {
            nombre: 'loading_and_unloading_zones',
            code: 'cargadescarga',
            url: 'zonescarregadescarrega/',
            maxZoomClusteringLevel: 16,
            clusterMarker: 'camion.svg',
            adaptativeMarkers: true,
            minZoomLevel: 10,
            iconFolder: 'trafico',
            markerIcon: 'camion.svg',
            displayInfo: true,
            infoTitle: 'name',
            info: [
              { label: 'address', value: 'address' },
              { label: 'district', value: 'district' },
              { label: 'phonenumber', value: 'phonenumber' },
              { label: 'url', value: 'code_url', type: 'url' },
            ],
          },
          {
            nombre: 'controlled_access_zones',
            code: 'zonasaccesocontrolado',
            url: 'zonesaccescontrolat/',
            maxZoomClusteringLevel: 16,
            clusterMarker: 'restringido.svg',
            adaptativeMarkers: true,
            minZoomLevel: 10,
            iconFolder: 'trafico',
            markerIcon: 'restringido.svg',
            displayInfo: true,
            infoTitle: 'Name',
            info: [
              { label: 'description', value: 'descriptio' },
            ],
          },
          {
            nombre: 'zones_30',
            code: 'zonas30',
            url: 'zones30/',
            minZoomLevel: 10,
            style: {
              strokeColor: '#70B8AF',
              strokeOpacity: 1,
              strokeWeight: 0,
              fillColor: '#70B8AF',
            },
          },
          {
            nombre: 'electric_vehicle_recharging_points',
            code: 'recargavehiculose',
            url: 'recarregavehicleselectrics/',
            minZoomLevel: 10,
            maxZoomClusteringLevel: 16,
            clusterMarker: 'enchufe.svg',
            iconFolder: 'trafico',
            markerIcon: 'enchufe.svg',
            adaptativeMarkers: true,
            displayInfo: true,
            infoTitle: 'name',
            info: [
              { label: 'address', value: 'address' },
            ],
          },
          {
            nombre: 'petrol_stations',
            code: 'gasolineras',
            url: 'benzineres/',
            maxZoomClusteringLevel: 16,
            clusterMarker: 'enchufe.svg',
            minZoomLevel: 10,
            iconFolder: 'trafico',
            markerIcon: 'gasolinera.svg',
            adaptativeMarkers: true,
            displayInfo: true,
            infoTitle: 'name',
            info: [
              { label: 'address', value: 'address' },
              { label: 'district', value: 'district' },
              { label: 'phonenumber', value: 'phonenumber' },
              { label: 'url', value: 'code_url', type: 'url' },
            ],
          },
        ],
      },
    ],
  },
];
