<template>
  <div id="help" v-if="opened">
    <div class="background"></div>
    <div class="content" :style="slideWidth()">
      <div class="slides">
        <div class="slide-container" :style="slideWidth()">
          <carousel ref="carousel" :items="1" :nav="false" @changed="changedPage">
            <div class="front-card" :style="slideWidth()">
              <div class="img" :style="{backgroundImage: 'url(' + require(`@/assets/img/help/help-1.${$i18n.locale}.jpg`) + ')'}">
              </div>
              <div class="text">
                <h2 class="m-big">{{$t('in_your_phone')}}</h2>
                <p>{{$t('search_best_route')}}</p>
              </div>
            </div>
            <div class="front-card" :style="slideWidth()">
              <div class="img" :style="{backgroundImage: 'url(' + require('@/assets/img/help/help-2.jpg') + ')'}">
              </div>
              <div class="text">
                <h2 class="m-big">{{$t('look_map')}}</h2>
                <p>{{$t('click_fullscreen')}}</p>
              </div>
            </div>
            <div class="front-card" :style="slideWidth()">
              <div class="img" :style="{backgroundImage: 'url(' + require(`@/assets/img/help/help-3.${$i18n.locale}.jpg`) + ')'}">
              </div>
              <div class="text pt-3">
                <h2>{{$t('customize_travel')}}</h2>
                <p>{{$t('how_to_move')}}</p>
                <h2>{{$t('all_tranport')}}</h2>
                <p>{{$t('find_line_easy')}}</p>
              </div>
            </div>
            <div class="front-card" :style="slideWidth()">
              <div class="img" :style="{backgroundPosition: '50% 100%', backgroundImage: 'url(' + require('@/assets/img/help/help-4.jpg') + ')'}">
              </div>
              <div class="text pt-2 pl-2 pr-2">
                <h2 class="m-big">{{$t('to_your_mesure')}}</h2>
                <p>{{$t('choose_you_want_to_see')}}</p>
              </div>
            </div>
            <div class="front-card" :style="slideWidth()">
              <div class="img" :style="{backgroundPosition: '50% 100%', backgroundImage: 'url(' + require('@/assets/img/help/help-5.jpg') + ')'}">
              </div>
              <div class="text pt-2 pl-2 pr-2">
                <h2 class="m-big">{{$t('in_your_phone')}}</h2>
                <p>{{$t('add_to_home_screen_help')}}</p>
                <a href="javascript://" @click="addToHomeScreenHelp">{{$t('add')}}</a>
              </div>
            </div>
          </carousel>
          <div class="back-card"></div>
          <div class="middle-card"></div>
        </div>
      </div>
      <div class="buttons">
        <div>
          <a href="javascript://" class="prev" @click="changePage(-1)" v-if="currentPage > 1">{{$t('back')}}</a>
          <a href="javascript://" class="next float-right" @click="changePage(1)" v-if="currentPage < totalItems">{{$t('continue')}}</a>
          <a href="javascript://" class="exit float-right" @click="close()" v-if="currentPage == totalItems">{{$t('to_exit')}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import carousel from 'vue-owl-carousel';

export default {
  name: 'Help',
  components: {
    carousel,
  },
  data() {
    return {
      currentPage: 1,
      totalItems: 5,
    };
  },
  computed: {
    ...mapState('help', [
      'opened',
    ]),
  },
  methods: {
    ...mapMutations({
      setOpened: 'help/setOpened',
    }),
    slideWidth() {
      return { width: `${window.innerWidth - 30}px` };
    },
    changedPage(e) {
      this.currentPage = e.item.index + 1;
    },
    changePage(direction) {
      const owl = this.$refs.carousel;
      if (direction < 0) {
        $(`#${this.$refs.carousel.prevHandler}`).click();
        return false;
      }
      $(`#${this.$refs.carousel.nextHandler}`).click();
      return false;
    },
    addToHomeScreenHelp() {
      const device = (this.isIOS()) ? 'ios' : 'android';
      const icon = require(`@/assets/img/help/action-icon-${device}.png`);
      const msg = `<p class="mt-3"><b>1.</b> ${this.$t(`add_to_home_screen_${device}_1`)}<img class="ml-2 action-icon" src="${icon}"></p><p><b>2.</b> ${this.$t(`add_to_home_screen_${device}_2`)}</p>`;
      this.$swal({
        html: msg,
        title: this.$t('add_to_home_screen_how'),
      });
    },
    isIOS() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    },
    close() {
      this.setOpened(false);
    },
  },
  mounted() {
    $('.owl-dot').first().click();
  },
};
</script>
