<template>
  <div>
    <div class="cercador">
      <input type="text" :placeholder="placeholder" @change="linesSearchChange" v-model="linesSearchWord">
      <i class="bcn-icon-cerca" v-if="!activeSearch"></i>
      <i class="bcn-icon-tancar-medium cancel-search" v-if="activeSearch" @click="cancelSearch"></i>
    </div>
    <!-- Search results-->
    <transition enter-active-class="animated fadeIn slideInLeft" leave-active-class="animated fadeOut slideOutLeft">
      <div class="search-results" :style="level2Height" v-if="activeSearch">
        <ul>
          <li v-if="!hasResults()">
            {{$t('no_results')}}
          </li>
          <li class="search-result" v-for="(item, index) in searchResults.metro.lines" v-bind:key="'metro'+index">
            <div @click="selectLine('metro', item.properties.NOM_LINIA)">
              <div class="subtitle">{{$t('metro')}}</div>
              <img class="logo-metro" :src="require(`@/assets/img/metro/${item.properties.NOM_LINIA}.png`)">
              <text-highlight :queries="[linesSearchWord]">{{item.properties.DESC_SERVEI}}</text-highlight>
            </div>
          </li>

          <li class="search-result"
              v-for="(item, index) in searchResults.metro.stations"
              v-bind:key="'metrostation'+index"
              v-show="layers=='transport'"
          >
            <div @click="loadStationMap('metro',item.properties.NOM_LINIA, item.properties.CODI_ESTACIO)">
              <div class="subtitle">{{$t('station')}}</div>
              <img class="logo-metro" :src="require(`@/assets/img/metro/${item.properties.NOM_LINIA}.png`)">
              <text-highlight :queries="[linesSearchWord]">{{item.properties.NOM_ESTACIO}}</text-highlight>
            </div>
          </li>

          <li class="search-result" v-for="(item, index) in searchResults.bus.lines" v-bind:key="'bus'+index">
            <div @click="selectLine('bus', item.properties.Nom)">
              <div class="subtitle">{{$t('bus')}}</div>
              <div class="d-table parada">
                <div class="d-table-cell nowrap">
                  <span :style="{
                    'border-left': '2px solid #'+getColor(getLinea('bus',item.properties.Nom)),
                    'color': '#'+getColor(getLinea('bus',item.properties.Nom))
                  }">
                    <span class="csvicon-bus "></span>
                  </span>
                  <span class="color-black">
                  <text-highlight :queries="[linesSearchWord]">{{item.properties.Nom}}</text-highlight></span>
                </div>
                <div class="d-table-cell pl-1">
                  <span>
                    <text-highlight :queries="[linesSearchWord]">{{item.properties.DescIntern}}</text-highlight>
                  </span>
                </div>
              </div>
            </div>
          </li>

          <li class="search-result"
              v-for="(item, index) in searchResults.bus.stations"
              v-bind:key="'busstation'+index"
              v-show="layers=='transport'"
          >
            <div @click="loadStationMap('bus',item.properties.Linea, item.properties.Codigo)">
              <div class="subtitle">{{$t('bus_stop')}}</div>
              <div class="d-table parada">
                <div class="d-table-cell nowrap">
                  <span>
                    <span class="color-black mr-1">
                      <span class="csvicon-bus-stop"></span>
                      <span><text-highlight :queries="[linesSearchWord]">{{item.properties.Linea}}</text-highlight></span>
                    </span>
                  </span>
                </div>
                <div class="d-table-cell pl-1">
                  <div>
                    <span>
                      <text-highlight :queries="[linesSearchWord]">{{item.properties.Descripcio}} ({{item.properties.Codigo}})</text-highlight>
                    </span>
                  </div>
                  <span>
                    <text-highlight :queries="[linesSearchWord]">{{getLinea('bus', item.properties.Linea).descripcio}}</text-highlight>
                  </span>
                </div>
              </div>
            </div>
          </li>

          <li class="search-result" v-for="(item, index) in searchResults.rodalies.lines" v-bind:key="'rodalies'+index">
            <div @click="selectLine('rodalies', item.properties.line)">
              <div class="subtitle">{{$t('local_trains')}}</div>
              <img class="logo-metro" :src="require(`@/assets/img/rodalies/${item.properties.line}.png`)">
              <text-highlight :queries="[linesSearchWord]">{{item.properties.description}}</text-highlight>
            </div>
          </li>

          <li class="search-result"
              v-for="(item, index) in searchResults.rodalies.stations"
              v-bind:key="'rodaliesstation'+index"
              v-show="layers=='transport'"
          >
            <div @click="loadStationMap('rodalies',item.properties.line, item.properties.url)">
              <div class="subtitle">{{$t('local_train_station')}}</div>
              <img class="logo-metro" :src="require(`@/assets/img/rodalies/${item.properties.line}.png`)">
              <text-highlight :queries="[linesSearchWord]">{{item.properties.name}}</text-highlight>
            </div>
          </li>

          <li class="search-result" v-for="(item, index) in searchResults.ferrocarril.lines" v-bind:key="'ferrocarril'+index">
            <div @click="selectLine('ferrocarril', item.properties.line)">
              <div class="subtitle">{{$t('fgc_trains')}}</div>
              <img class="logo-metro" :src="require(`@/assets/img/fgc/${item.properties.line}.png`)">
              <text-highlight :queries="[linesSearchWord]">{{item.properties.description}}</text-highlight>
            </div>
          </li>

          <li class="search-result"
              v-for="(item, index) in searchResults.ferrocarril.stations"
              v-bind:key="'ferrocarrilstation'+index"
              v-show="layers=='transport'"
          >
            <div @click="loadStationMap('ferrocarril',item.properties.line, item.properties.id)">
              <div class="subtitle">{{$t('fgc_station')}}</div>
              <img class="logo-metro" :src="require(`@/assets/img/fgc/${item.properties.line}.png`)">
              <text-highlight :queries="[linesSearchWord]">{{item.properties.description}}</text-highlight>
            </div>
          </li>

          <li class="search-result"
              v-for="(item, index) in searchResults.tram.stations"
              v-bind:key="'tramstation'+index"
              v-show="layers=='transport'"
          >
            <div @click="loadStationMap('tram',item.properties.LINIA.split('-')[0], item.properties.ID)">
              <div class="subtitle">{{$t('tram_stop')}}</div>
              <img class="logo-metro" :src="require(`@/assets/img/logo-tram.png`)">
              <text-highlight :queries="[linesSearchWord]">{{item.properties.LINIA}} - {{item.properties.NOM_ESTACI}}</text-highlight>
            </div>
          </li>

          <li class="search-result option-layer"
              v-for="(item, index) in getLayerMenuOptions()"
              v-bind:key="'menuoptions'+index"
              v-show="layers=='all'"
          >
            <div @click="selectOpcion(item)">
              <span :class="`csvicon-${item.group_code}`" class="mr-1"></span>
              <text-highlight :queries="[linesSearchWord]">{{item.nombre}}</text-highlight>
            </div>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { getApiUri } from '../api/util';
import { TransportMixins } from './mixins/TransportMixins';
import api from '../api/api';

export default {
  name: 'Search',
  props: ['placeholder', 'layers'],
  mixins: [TransportMixins],
  data() {
    return {
      awaitingSearch: false,
    };
  },
  computed: {
    ...mapState('menu', [
      'layerMenu',
    ]),
    ...mapState('search', [
      'searchResults',
      'linesSearchWord',
      'activeSearch',
    ]),
    ...mapState('map', [
      'activeLayers',

    ]),
    ...mapState('transport', [
      'lineas',
    ]),
    linesSearchWord: {
      get() {
        return this.$store.state.search.linesSearchWord;
      },
      set(value) {
        this.setLinesSearchWord(value);
      },
    },
    level2Height() {
      const height = $(window).height() - $('#brand').height() - 0;
      return { height: `${height}px` };
    },
  },
  methods: {
    ...mapMutations({
      setLineas: 'transport/setLineas',
      setSearchResults: 'search/setSearchResults',
      setActiveSearch: 'search/setActiveSearch',
      setLinesSearchWord: 'search/setLinesSearchWord',
      cleanActiveLayers: 'map/cleanActiveLayers',
    }),
    linesSearchChange() {
      this.setLinesSearchWord(this.linesSearchWord);
      if (this.linesSearchWord.length > 1) {
        if (!this.awaitingSearch) {
          this.setSearchResults({
            metro: {
              lines: [],
              stations: [],
            },
            bus: {
              lines: [],
              stations: [],
            },
            rodalies: {
              lines: [],
              stations: [],
            },
            ferrocarril: {
              lines: [],
              stations: [],
            },
            tram: {
              stations: [],
            },
          });
          this.awaitingSearch = true;
          this.search(this.linesSearchWord).then((response) => {
            this.setSearchResults(response.data.results);
            this.awaitingSearch = false;
            for (const c in this.searchResults.bus.stations) {
              const result = this.searchResults.bus.stations[c];
              const parada = {
                nom: result.properties.Descripcio,
                codi: result.properties.Codigo,
                ordre: parseInt(result.properties.Ordre),
                lineas: result.properties.Linies,
                municipi: result.properties.Municipi,
                geometry: result.geometry,
              };
              if (this.lineas.bus[result.properties.Linea] != undefined) {
                this.setLineas({
                  bus: {
                    [result.properties.Linea]: {
                      estacions: {
                        [parada.ordre]: parada,
                      },
                    },
                  },
                });
              }
            }
            this.setActiveSearch(true);
          });
        }
      }
      if (this.linesSearchWord == '') {
        this.setActiveSearch(false);
      }
    },

    selectLine(type, linea_name) {
      let transportMethod = null;
      let TransportClass = null;
      let linea = null;
      switch (type) {
        case 'metro':
          transportMethod = this.selectLineaMetro;
          TransportClass = LineaTransportMetro;
          break;
        case 'bus':
          transportMethod = this.selectLineaBus;
          TransportClass = LineaTransportBus;
          break;
        case 'ferrocarril':
          transportMethod = this.selectLineaFerrocarril;
          TransportClass = LineaTransportFerrocarril;
          break;
        case 'rodalies':
          transportMethod = this.selectLineaRodalies;
          TransportClass = LineaTransportCercanias;
          break;
        case 'tram':
          transportMethod = this.selectLineaTram;
          TransportClass = LineaTransportTram;
          break;
        default:
          break;
      }
      linea = this.getLinea(type, linea_name);
      if (this.layers == 'transport') {
        transportMethod(linea);
      } else {
        TransportClass.loadLineMap(linea_name);
        this.setLinesSearchWord('');
        this.setActiveSearch(false);
      }
    },

    search(word) {
      const promise = new Promise((resolve, reject) => {
        api.get(`${getApiUri()}search/?word=${word}`).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      });
      return promise;
    },

    cancelSearch() {
      this.setLinesSearchWord('');
      this.setActiveSearch(false);
    },

    getLayerMenuOptions() {
      const options = [];
      if (!this.activeSearch) return options;
      const group_codes = ['walk', 'bici', 'car'];
      for (const g in this.layerMenu) {
        const group = this.layerMenu[g];
        if (group_codes.indexOf(group.code) !== -1) {
          for (const o in group.options) {
            const option = group.options[o];
            if (option.options !== undefined) {
              for (const s in option.options) {
                const suboption = option.options[s];
                suboption.group_name = group.grupo;
                suboption.group_code = group.code;
                options.push(suboption);
              }
            } else {
              option.group_name = group.grupo;
              option.group_code = group.code;
              options.push(option);
            }
          }
        }
      }
      return options.filter((o) => this.normalizedContains(o.nombre, this.linesSearchWord));
    },
    hasResults() {
      const listsToCheck = [
        this.searchResults.metro.lines,
        this.searchResults.bus.lines,
        this.searchResults.ferrocarril.lines,
        this.searchResults.rodalies.lines,
      ];
      if (this.layers == 'transport') {
        listsToCheck.push(this.searchResults.metro.stations);
        listsToCheck.push(this.searchResults.bus.stations);
        listsToCheck.push(this.searchResults.ferrocarril.stations);
        listsToCheck.push(this.searchResults.rodalies.stations);
        listsToCheck.push(this.searchResults.tram.stations);
      }
      for (const c in listsToCheck) {
        if (listsToCheck[c].length > 0) {
          return true;
        }
      }
      if (this.layers == 'all') {
        if (this.getLayerMenuOptions().length > 0) {
          return true;
        }
      }
      return false;
    },
    selectOpcion(opcion) {
      opcion.selected = true;
      this.$root.$children[0].$refs.layerMenu.activateLayer(opcion);
      this.setLinesSearchWord('');
      this.setActiveSearch(false);
    },
    removeDiacritics(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    normalizedContains(haystack, needle) {
      const regExp = new RegExp(this.removeDiacritics(needle), 'gi');
      return regExp.test(this.removeDiacritics(haystack));
    },
  },
};
</script>
