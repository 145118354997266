<template>
  <div class="infoPanelWrapper" v-if="infoPanel.visible">
    <div id="data-panel" class="ficha" >
      <a href="javascript://" class="closeCard" @click="close"><span class="bcn-icon-tancar-medium"></span></a>
      <div class="content">
        <div class="info">
          <h3 class="title">
            <img :src="require(`@/assets/img/${infoPanel.linea}`)" class="metro"
                 v-if="infoPanel.type!='busnorth' && infoPanel.type!='aerobus' && infoPanel.type!='bus' && infoPanel.type!='aerobus' && infoPanel.type!='info_point'">
            <img :src="require(`@/assets/img/${infoPanel.icon_title}`)" class="icon_title" v-if="infoPanel.icon_title!=null">
            <span class="bus-line"
                  :style="{'background-color': '#'+getBusColor(infoPanel.linea)}"
                  v-if="infoPanel.type=='bus' || infoPanel.type=='aerobus'"
            >
                {{infoPanel.linea}}
              </span>
            <div>
              {{infoPanel.title}}
              <div class="access" v-if="infoPanel.feature.getProperty('NOM_ACCES')!=undefined">{{infoPanel.feature.getProperty('NOM_ACCES')}}</div>
            </div>
          </h3>
        </div>
        <div v-if="infoPanel.foto!=null" v-html="infoPanel.foto" class="foto">
        </div>
        <ul class="data">
          <div v-if="infoPanel.accessible && infoPanel.feature.getProperty('NOM_ACCES')==undefined" class="adapted">
            <span class="csvicon-ascensor mr-1" v-if="getNumAscensorMetro()>0"></span>
            <span class="csvicon-wheelchair mr-1"></span> {{$t('adapted_station')}}
          </div>
          <div v-if="infoPanel.feature.getProperty('NOM_TIPUS_ACCESSIBILITAT')=='Accessible' && infoPanel.feature.getProperty('NOM_ACCES')!=undefined" class="adapted">
            <span class="csvicon-ascensor mr-1" v-if="infoPanel.feature.getProperty('NUM_ASCENSORS')>0"></span>
            <span class="csvicon-wheelchair mr-1"></span> {{$t('adapted_access')}}
          </div>
          <div class="lineas" v-if="infoPanel.lineas.length>0">
            <span class="txt-lineas">{{$t('lines')}}</span>
            <div class="logos">
              <img class="metro"
                   :src="require(`@/assets/img/${linea}`)"
                   v-for="(linea, index) in infoPanel.lineas"
                   v-bind:key="index"
              >
            </div>
          </div>
          <hr v-if="infoPanel.lineas_bus.length>0">
          <div class="lineas" v-if="infoPanel.lineas_bus.length>0">
            <span class="txt-lineas"><span class="csvicon-bus"></span></span>
            <div class="logos">
                <span
                  v-for="(linea, index) in infoPanel.lineas_bus"
                  v-bind:key="index"
                  class="bus-line"
                  :style="{'background-color': '#'+getBusColor(linea)}"
                >
                  {{linea}}
                </span>
            </div>
          </div>

          <div class="lineas" v-if="infoPanel.lineas_tram.length>0">
            <span class="txt-lineas"><img class="metro tram" :src="require(`@/assets/img/logo-tram.png`)"></span>
            <div class="logos tramlines">
              {{infoPanel.lineas_tram.join(', ')}}
            </div>
          </div>
          <a v-if="infoPanel.code === 'bicing'" @click="updateBicing()" class="link-update" href="javascript://">
            <span class="icon-reload bcn-icon-proces-tramit"></span>
          </a>
          <li v-for="(dato, index) in infoPanel.datos" v-bind:key="index">
            <strong v-html="dato.label" class="label"></strong>
            <span v-html="dato.data" class="dato"></span>
          </li>
        </ul>
        <div v-if="infoPanel.type=='busnorth'">
          <hr>
          <div v-for="(time,index) in futureTimes(infoPanel.bustimes)" v-bind:key="index">
            <div v-if="index==0" class="fs-16 mb-2 font-weight-bold">{{$t('next_exits')}}</div>
            <div class="mb-1">
              <span class="mr-2">{{time.departure_time}}</span>
              <span>{{time.direction}}</span>
            </div>
          </div>
          <div v-if="infoPanel.bustimes.length==0">{{$t('no_time_table_available')}}</div>
          <div class="clearfix"></div>
        </div>
        <div v-if="infoPanel.type=='bus'">
          <hr>
          <a @click="updateBusTime()" class="link-update" href="javascript://">
            <span class="icon-reload bcn-icon-proces-tramit"></span>
          </a>
          <div v-for="(time,index) in infoPanel.bustimes" v-bind:key="index">
            <div v-if="index==0" class="fs-16 mb-2 font-weight-bold">{{$t('next_exits')}}</div>
            <div class="mb-1">
              <span class="line-time-bus" :style="{'background-color': '#'+getBusColor(time.line)}">{{time.line}}</span>
              <span>{{$t(time.time)}}</span>
            </div>
          </div>
          <div v-if="infoPanel.bustimes.length==0">{{$t('no_time_table_available')}}</div>
          <div class="clearfix"></div>
        </div>
        <div v-if="infoPanel.type=='rodalies'" style="display: none">
          <hr>
          <a @click="updateTrainsTime()" class="link-update" href="javascript://">
            <span class="icon-reload bcn-icon-proces-tramit"></span>
          </a>
          <div class="train_times">
            <div v-for="(time,index) in infoPanel.traintimes" v-bind:key="index">
              <div v-if="index==0" class="fs-16 mb-2 font-weight-bold">{{$t('upcoming_trains')}}</div>
              <div class="mb-1" :class="visibleTime(index)">
                <span class="visibleItem"><img :src="require(`@/assets/img/rodalies/${time.line}.png`)" class="metro"></span>
                <span class="mr-1 visibleItem scheduled">{{$t('arrival')}}: <b>{{time.arrive}}</b> - {{$t('departure')}}: <b>{{time.leave}}</b></span>
                <span class="csvicon-wheelchair mr-1 visibleItem"></span>
                <div class="destination">{{time.destination_name}}</div>
              </div>
            </div>
          </div>
          <div v-if="infoPanel.traintimes.length==0">{{$t('no_time_table_available')}}</div>
          <div class="clearfix"></div>
        </div>

        <div class="links" v-if="infoPanel.type!='busnorth'">
          <hr>
          <a
            v-if="false"
            href="javascript://"
            title="Desa"
            @click="savePlace"
            class="saveLocation pull-left"
          >
            <span class="bcn-icon-favorits-light"></span><span> {{$t('add_to_favorites')}}</span>
          </a>
          <a class="link-comshiva pull-left font-600"
             href="javascript://"
             @click="howToArrive"
          >
            <span class="bcn-icon-mapa"></span><span> {{$t('how_to_arrive')}}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import DateTime from 'luxon';
import { mapState, mapMutations } from 'vuex';
import { filter } from 'lodash';
import { getApiUri } from '../api/util';
import api from '../api/api';

export default {
  name: 'InfoPanel',
  computed: mapState('infopanel', [
    'infoPanel',
  ]),
  methods: {
    ...mapMutations({
      setInfoPanel: 'infopanel/setInfoPanel',
      initInfoPanel: 'infopanel/initInfoPanel',
    }),
    close() {
      this.setInfoPanel({
        visible: false,
      });
    },
    getBusColor(linea) {
      return LineaTransportBus.getColor(linea);
    },
    getNumAscensorMetro() {
      let numAscensors = 0;
      for (const c in this.infoPanel.feature.getProperty('ACCESSOS')) {
        if (this.infoPanel.feature.getProperty('ACCESSOS')[c].NUM_ASCENSORS > 0) {
          numAscensors++;
        }
      }
      return numAscensors;
    },
    savePlace() {
      this.getPlace().then((place) => {
        this.$swal(this.$t('added_to_favorites'), this.infoPanel.title, 'success');
        GoogleRoutesApi.savePlace(place);
      });
    },
    getPlace() {
      const promise = new Promise((resolve, reject) => {
        const googlePlaceApi = new GoogleRoutesApi(this.$i18n.locale);
        let position = new google.maps.LatLng();
        this.infoPanel.feature.getGeometry().forEachLatLng((latlng) => {
          position = latlng;
        });
        googlePlaceApi.geocode(position).then((results) => {
          const val = results[0];
          const componentsObject = GoogleRoutesApi.addressComponentObject(val.address_components);
          const addressText = GoogleRoutesApi.getMainSecondaryAddresText(componentsObject).main_text;
          let secondary_text = addressText;
          if (this.infoPanel.type != 'info_point') {
            secondary_text = `Línia ${this.infoPanel.nom_linea} de ${this.infoPanel.type}`;
          }
          val.custom_text = {
            main_text: this.infoPanel.title,
            secondary_text,
          };
          resolve(val);
        });
      });
      return promise;
    },
    howToArrive() {
      let position = new google.maps.LatLng();
      this.infoPanel.feature.getGeometry().forEachLatLng((latlng) => {
        position = latlng;
      });
      const destination = `${position.lat()},${position.lng()}`;
      const destination_txt = this.infoPanel.title;
      this.$root.$children[0].$refs.planificador.openPlan(
        undefined,
        destination,
        undefined,
        destination_txt,
        'WALKING',
      );
      this.setInfoPanel({
        visible: false,
      });
    },
    updateBusTime() {
      const { feature } = this.infoPanel;
      const codigo = feature.getProperty('Codigo');

      api.get(`${getApiUri()}servicios/busestimes/${codigo}`).then((response) => {
        const bustimes = [];
        for (const c in response.data) {
          const time = response.data[c];
          bustimes.push({
            line: time.line,
            destination: time.destination,
            time: time['text-ca'],
          });
        }

        this.setInfoPanel({
          bustimes: null,
        });
        this.setInfoPanel({
          bustimes,
          visible: true,
        });
      });
    },
    futureTimes(times) {
      const now = moment().format('HH:mm');
      return filter(times, (t) => { return t.departure_time > now; });
    },
    getIncidenciaDate(date, input_format = 'yyyy-LL-dd HH:mm', output_format = DateTime.DATETIME_SHORT) {
      return DateTime.fromFormat(date, input_format).toLocaleString(output_format);
    },
    updateTrainsTime() {
      const { feature } = this.infoPanel;
      const codigo = feature.getProperty('codigo');
      // api.get(`${getApiUri()}servicios/traintimes/${codigo}`).then((response) => {
      //   const traintimes = [];
      //   for (const c in response.data.results) {
      //     const time = response.data.results[c];
      //     traintimes.push({
      //       line: time.line,
      //       train: time.train,
      //       destination_name: time.destination_name,
      //       leave: time.leave.slice(0, -3),
      //       arrive: time.arrive.slice(0, -3),
      //     });
      //   }
      //
      //   this.setInfoPanel({
      //     traintimes: null,
      //   });
      //   this.setInfoPanel({
      //     traintimes,
      //     visible: true,
      //   });
      // });
    },
    visibleTime(index) {
      let cssClass = 'visible_time';
      if (index > 0) {
        const current_time = this.infoPanel.traintimes[index];
        const previous_time = this.infoPanel.traintimes[index - 1];
        if (current_time.train == previous_time.train) {
          cssClass = 'not_visible_time';
        }
      }
      return cssClass;
    },
    updateBicing() {
      const { feature } = this.infoPanel;
      const station = feature.getProperty('id');
      api.get(`${getApiUri()}servicios/bikesstation/${station}`).then((response) => {
        const data = response.data.results;
        this.infoPanel.datos = [
          { label: this.$t('free_bycicle_slots'), data: data.slots },
        ];
        if (data.mechanical_bikes > 0) {
          this.infoPanel.datos.push({ label: this.$t('mechanical_bycicles'), data: data.mechanical_bikes });
        }
        if (data.electrical_bikes > 0) {
          this.infoPanel.datos.push({ label: this.$t('electrical_bycicles'), data: data.electrical_bikes });
        }
        this.setInfoPanel(this.infoPanel);
      });
    },
  },
};
</script>
