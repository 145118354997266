/* eslint-disable */

import axios from 'axios';
import { getApiUri } from '../api/util';
import store from '../store/index';
import api from '../api/api';

window.LineaTransportFerrocarril = function() {

}

LineaTransportFerrocarril.prototype.loadTransport = function () {
  api.get(`${getApiUri()}ferrocarrilslinies/`).then(function (response) {
    const results = response.data.features;
    for (let c in results) {
      const result = results[c];
      const linea = {
        linea: result.properties.line,
        descripcio: result.properties.description,
        color: result.properties.stroke,
        type: 'ferrocarril',
        icon: 'csvicon-metro',
        estacions: {}
      }
      store.commit('transport/setLineas', {
        ferrocarril: {
          [result.properties.line]: linea,
        },
      });
    }
    const ordered = {};
    Object.keys(store.state.transport.lineas.ferrocarril).sort().forEach(function(key) {
      ordered[key] = store.state.transport.lineas.ferrocarril[key];
    });

    store.commit('transport/setLineas', {
      ferrocarril: null,
    });

    store.commit('transport/setLineas', {
      ferrocarril: ordered,
    });
  });
}

LineaTransportFerrocarril.prototype.loadStation = function (linea) {
  var promise = new Promise(function(resolve, reject) {
    api.get(`${getApiUri()}ferrocarrilsestacions/${linea}/`).then(function (response) {
      const results = response.data.features;
      for (let c in results) {
        const result = results[c];
        const estacio = {
          nom: result.properties.description,
          line: result.properties.line,
          codi: result.properties.id,
          ordre: parseInt(result.properties.order),
          geometry: result.geometry
        }
        if (result.properties.line==linea) {
          store.commit('transport/setLineas', {
            ferrocarril: {
              [linea]: {
                estacions: {
                  [result.properties.order]: estacio,
                },
              },
            },
          });
        }
      }
      resolve(response);
    });
  });
  return promise;
}

LineaTransportFerrocarril.getColor = function(linea) {
  for (var c in store.state.lineas.ferrocarril) {
    if (store.state.transport.lineas.ferrocarril[c].linea==linea) {
      return store.state.transport.lineas.ferrocarril[c].color;
    }
  }
  return '777777'
}

LineaTransportFerrocarril.getLinea = function(linea) {
  for (var c in store.state.transport.lineas.ferrocarril) {
    if (store.state.transport.lineas.ferrocarril[c].linea==linea) {
      return store.state.transport.lineas.ferrocarril[c];
    }
  }
  return {};
}

LineaTransportFerrocarril.getStation = function(linea, code) {
  for (var c in linea.estacions) {
    if (linea.estacions[c].codi==code) {
      return linea.estacions[c];
    }
  }
  return {};
}

LineaTransportFerrocarril.displayInfo = function(feature) {
  window.app.$children[0].$refs.infoPanel.initInfoPanel();

  store.commit('infopanel/setInfoPanel', {
    datos: null,
  });

  const logoFGC = require(`@/assets/img/logo-fgc.png`);
  var linea = LineaTransportFerrocarril.getLinea(feature.getProperty('line'));
  store.commit('infopanel/setInfoPanel', {
    title: feature.getProperty('description'),
    linea: `fgc/${feature.getProperty('line')}.png`,
    nom_linea: feature.getProperty('line'),
    datos: [
      {"label": `<img class="metro" src="${logoFGC}">`, "data": `${linea.descripcio}`}
    ],
    type: 'ferrocarril',
    feature: feature,
    visible: true,
  });
}

LineaTransportFerrocarril.loadLineMap = function(linea_name, zoom, station_name) {

  var linea = LineaTransportFerrocarril.getLinea(linea_name);

  var configStation = Object.assign({}, {
    ZOOM: 16,
    zoomPoint: 17,
    minZoomLevel: 14,
    CENTER: new google.maps.LatLng(41.404, 2.173),
    MAP_TYPE: google.maps.MapTypeId.ROADMAP,
    REGION: 'ca',
    BUFFER_DISTANCE: 0.2, // Km
    savePosition: true,
    displayInfo: true,
    setMarkerSearch: true,
    adaptativeMarkers: true,
    iconURL: "/img/markers/publico",
    forceZoom: false,
    mapTypeControl: false,
    visible: true,
  });

  configStation.callback = {
    displayInfo: LineaTransportFerrocarril.displayInfo,
  }

  let marker = `metro_${linea.linea}.svg`;
  // fgc-1.svg: green / fgc-2.svg: orange
  if (linea.linea.indexOf('R') === 0) marker = 'fgc-2.svg';
  if (linea.linea.indexOf('S') === 0) marker = 'fgc-2.svg';

  configStation.style = {
    strokeColor: linea.color,
    fillColor: linea.color,
    strokeOpacity: 1,
    strokeWeight: 2,
    path: "/img/markers/publico",
    marker: marker,
    icon: marker,
  };

  configStation.name = `ferrocarrilsestacions${linea.linea}`;

  var configLine = Object.assign({}, {
    ZOOM: 16,
    zoomPoint: 17,
    CENTER: new google.maps.LatLng(41.404, 2.173),
    MAP_TYPE: google.maps.MapTypeId.ROADMAP,
    REGION: 'ca',
    BUFFER_DISTANCE: 0.2, // Km
    savePosition: true,
    setMarkerSearch: true,
    adaptativeMarkers: true,
    iconURL: "/img/markers/",
    forceZoom: false,
    mapTypeControl: false,
  });

  configLine.style = {
    strokeColor: linea.color,
    fillColor: linea.color,
    strokeOpacity: 1,
    strokeWeight: 2,
  };

  configLine.name = `ferrocarrilslinies${linea.linea}`;
  var dataLayerStation = new DataLayer(configStation, store.state.map.mapObj);
  var dataLayerLine = new DataLayer(configLine, store.state.map.mapObj);

  var layerUrlLine = `${getApiUri()}ferrocarrilslinies/${linea.linea}/`;
  var layerUrlStacions = `${getApiUri()}ferrocarrilsestacions/${linea.linea}/`;

  Promise.all([
    dataLayerLine.addGeoJsonDataLayer(layerUrlLine),
    dataLayerStation.addGeoJsonDataLayer(layerUrlStacions)
  ]).then(() => {
    if (station_name!=undefined) {
      var station = LineaTransportFerrocarril.getStation(linea, station_name);
      store.state.map.mapObj.setCenter(new google.maps.LatLng(station.geometry.coordinates[1],station.geometry.coordinates[0]));
      store.state.map.mapObj.setZoom(configStation.zoomPoint);
    }
    if (zoom=='line') {
      dataLayerLine.cropToBounds();
    }
    linea.selected = true;
    store.commit('map/addActiveLayer', linea);

    store.commit('menu/setMainMenuStatus', {
      opened: false,
    });
  });

}
