<template>
  <div id="barra-ajuntament" @click="clicked"></div>
</template>

<script>
export default {
  name: 'BarraAjuntament',
  methods: {
    initBar() {
      const target = this;
      bcn.lang = this.$route.params.lang;
      bcn.brandInjection({
        version: 'v2017',
        urls: {
          ca: 'https://www.barcelona.cat/brand/banner/ca/com-shi-va2015.html',
          es: 'https://www.barcelona.cat/brand/banner/es/com-shi-va2015.html',
          en: 'https://www.barcelona.cat/brand/banner/en/com-shi-va2015.html',
        },
        keys: ['UA-46029296-16'],
        callback() {
          $('#brand').prependTo('#barra-ajuntament');
          $('#brand .bcnbrand-lang .bcnbrand-list li').each(function () {
            const directory = process.env.VUE_APP_PUBLIC_PATH;
            $(this).find('a').attr('href', `${directory}${$(this).find('a').attr('href').substr(1)}`);
          });
        },
      });
    },
    clicked() {
      let display = 'block';
      if ($('.bcnbrand-shortcuts').hasClass('bcnbrand-open-nav') || $('.bcnbrand-lang').hasClass('bcnbrand-open-nav')) {
        display = 'none';
      }
      $('#mainMenu').css('display', display);
    },
  },
  mounted() {
    this.initBar();
  },
};
</script>
