import { mapState, mapMutations } from 'vuex';
import {
  find,
  clone,
  cloneDeep,
  findIndex,
} from 'lodash';
import { DateTime } from 'luxon';
import { getApiUri } from '../../api/util';
import api from '../../api/api';
import { app } from '../../main';

export const LayerMixins = {
  computed: {
    ...mapState('map', [
      'mapObj',
      'activeLayers',
    ]),
    ...mapState('menu', [
      'layerMenuStatus',
      'layerMenu',
    ]),
    ...mapState('transport', [
      'alterationMessages',
    ]),
  },
  methods: {
    ...mapMutations({
      setActiveLayers: 'map/setActiveLayers',
      addActiveLayer: 'map/addActiveLayer',
      setInfoPanel: 'infopanel/setInfoPanel',
    }),
    selectOpcion(opcion) {
      const grupo = this.getGrupo(opcion);
      if ((opcion.options && opcion.options.length > 0) || (grupo.code == 'transport' && opcion.code != 'taxi')) return;
      opcion.selected = !opcion.selected;
      this.activateLayer(opcion);
      if (opcion.code == 'near_public_transport') {
        this.$root.$children[0].$refs.mapa.loadNearTransport('near_public_transport');
      }
      if (opcion.code == 'transport_accessible') {
        this.$root.$children[0].$refs.mapa.loadNearTransport('transport_accessible');
      }
    },

    getGrupo(opcion) {
      let grupo;
      for (const c in this.layerMenu) {
        grupo = this.layerMenu[c];
        for (const d in grupo.options) {
          const opcion2 = grupo.options[d];
          if (opcion2.code == opcion.code) {
            return grupo;
          }
          for (const e in opcion2.options) {
            const opcion3 = opcion2.options[e];
            if (opcion3.code == opcion.code) {
              return grupo;
            }
          }
        }
      }
      return grupo;
    },

    getIcon(opcion) {
      const clase = {};
      let { icon } = opcion;
      if (opcion.icon == undefined) {
        for (const c in this.layerMenu) {
          const grupo = this.layerMenu[c];
          const icon_group = grupo.icon;
          for (const d in grupo.options) {
            const opcion2 = grupo.options[d];
            if (opcion2.code == opcion.code) {
              icon = icon_group;
              break;
            }
            for (const e in opcion2.options) {
              const opcion3 = opcion2.options[e];
              if (opcion3.code == opcion.code) {
                icon = icon_group;
                break;
              }
            }
          }
        }
      }
      clase[icon] = icon;
      if (icon) {
        return clase;
      }
    },

    activateLayer(option) {
      if (option.selected) {
        if (findIndex(this.activeLayers, (o) => o.code == option.code) < 0) {
          this.addActiveLayer(option);
          this.loadLayer(option);
        }
      } else {
        const layers = clone(this.activeLayers);
        const layerIndex = findIndex(layers, (o) => o.code == option.code);
        if (layerIndex !== -1) {
          layers.splice(layerIndex, 1);
        }
        this.setActiveLayers(layers);
        if (option.code == 'afectacions') {
          option.code = 'incidencias3';
        }
        if (option.code == 'carrilsbici') {
          for (const c in this.directionIcons) {
            this.directionIcons[c].setMap(null);
          }
        }
        const dataLayer = DataLayer.getDataLayer(option.code);
        if (!dataLayer) {
          console.warn(`Layer ${option.code} doesn't exist`);
          return;
        }
        dataLayer.hide();
        dataLayer.visible = false;
      }
    },
    loadLayerByConfig(config, url) {
      let dataLayer = DataLayer.getDataLayer(config.name);
      if (dataLayer !== undefined) {
        delete DataLayer.layers[config.name];
      }
      dataLayer = new DataLayer(config, this.mapObj);
      dataLayer.addGeoJsonDataLayer(`${getApiUri()}${url}`).then(() => {
        setTimeout(() => {
          dataLayer.show();
          dataLayer.setVisibilityByZoom();
        }, 1000);
      });
    },
    loadLayer(opcion) {
      const target = this;
      const grupo = this.getGrupo(opcion);
      let dataLayer = DataLayer.getDataLayer(opcion.code);
      if (dataLayer !== undefined) {
        delete DataLayer.layers[opcion.code];
      }
      const config = {
        name: opcion.code,
        minZoomLevel: opcion.minZoomLevel,
        visible: true,
      };

      if (opcion.adaptativeMarkers) {
        config.adaptativeMarkers = true;
        config.style = {
          path: `/img/markers/${opcion.iconFolder}`,
          marker: opcion.markerIcon,
          icon: opcion.markerIcon,
        };
      }
      if (opcion.style != undefined) {
        config.style = opcion.style;
      }

      if (opcion.displayInfo != undefined) {
        config.displayInfo = true;
        config.callback = {
          displayInfo(feature, config) {
            app.$children[0].$refs.infoPanel.initInfoPanel();

            let infoTitle = feature.getProperty(opcion.infoTitle);
            if (feature.getProperty('ACT_DESCRIPCIO') != undefined) {
              infoTitle = feature.getProperty('ACT_DESCRIPCIO');
              const datos = [];
              datos.push({
                label: '',
                data: `Des de ${target.getIncidenciaDate(feature.getProperty('ACT_DESDE'))}
                  fins a ${target.getIncidenciaDate(feature.getProperty('ACT_FINS'))}`,
              });
              target.setInfoPanel({
                datos,
              });
            }

            target.setInfoPanel({
              title: infoTitle,
              feature,
              code: opcion.code,
              icon_title: `markers/${opcion.iconFolder}/${opcion.markerIcon}`,
              type: 'info_point',
              datos: [],
            });

            let link = '';
            // Traffic cameras
            if (opcion.code == 'camarastrafico') {
              if (feature.getProperty('pk') != '') {
                target.setInfoPanel({
                  title: `${feature.getProperty('carretera')}(Km ${feature.getProperty('pk')})`,
                });
              }

              target.setInfoPanel({
                foto: `<img src="${feature.getProperty('link')}" class="w-100">`,
              });
            }
            for (const i in opcion.info) {
              const datos = opcion.info[i];
              let value = feature.getProperty(datos.value);
              const { label } = datos;
              if (datos.type == 'url') {
                link = (value.indexOf('https') != -1) ? value : `https://${value}`;
                value = `<a href="${link}" target="_blank">${value}</a>`;
              }
              if (feature.getProperty(datos.value) != '') {
                if (opcion.code == 'bicing' && opcion.info[i].value == 'slots') {
                  value -= feature.getProperty('bikes');
                }
                const datos = clone(target.infoPanel.datos);
                datos.push({
                  label: target.$t(label),
                  data: value,
                });
                target.setInfoPanel({
                  datos,
                });
              }
            }

            // Bici-friendly buildings
            if (opcion.code == 'bicifriends') {
              const datos = clone(target.infoPanel.datos);
              datos.push({
                label: (feature.getProperty('any_certificacio') == 0) ? target.$t('certified_process_building') : target.$t('certified_building'),
                data: '',
              });
              if (feature.getProperty('aparcaments_interiors_visitants') > 0) {
                datos.push({
                  label: target.$t('indoor_visitor_parkings'),
                  data: feature.getProperty('aparcaments_interiors_visitants'),
                });
              }
              if (feature.getProperty('aparcaments_exteriors_visitants') > 0) {
                datos.push({
                  label: target.$t('outdoor_visitor_parkings'),
                  data: feature.getProperty('aparcaments_exteriors_visitants'),
                });
              }
              target.setInfoPanel({
                datos,
              });
            }

            target.setInfoPanel({
              visible: true,
            });
            if (target.infoPanel.code == 'bicing') {
              app.$children[0].$refs.infoPanel.updateBicing();
            }
          },
        };
      }
      if (opcion.idField) {
        config.idField = opcion.idField;
      }
      if (opcion.maxZoomClusteringLevel) {
        config.maxZoomClusteringLevel = opcion.maxZoomClusteringLevel;
        config.clustering = true;
        config.clusterAnchor = [-10, 0];
        config.clusterTextSize = 11;
        config.clusterTextColor = '#ffffff';
        config.clusterFontWeigth = 'bold';
        config.clusterURL = '/cluster/';
        config.clusterGridSize = 60;
        config.clusterIconSize = [24, 35];
        config.style = {
          path: `/img/markers/${opcion.iconFolder}`,
          marker: opcion.markerIcon,
          clusterMarker: opcion.clusterMarker,
          icon: opcion.markerIcon,
        };
      }
      dataLayer = new DataLayer(config, this.mapObj);
      if (opcion.url) {
        dataLayer.addGeoJsonDataLayer(`${getApiUri()}${opcion.url}`).then(() => {
          setTimeout(() => {
            dataLayer.show();
            dataLayer.setVisibilityByZoom();
            if (dataLayer.layerName.indexOf('traficoactual') !== -1) {
              this.setTrafficState(dataLayer.layerName);
            }
            if (dataLayer.layerName == 'afectacions') {
              this.loadIncidencias(config);
            }
            if (dataLayer.layerName == 'carrilsbici') {
              this.loadArrowDirections(dataLayer);
            }
            if (dataLayer.layerName == 'bicing') {
              this.loadBicing(dataLayer);
            }
            if (dataLayer.layerName == 'bicifriends') {
              this.loadBiciFriends(dataLayer);
            }
          }, 1000);
        });
      }
    },

    getIconActiveLayers(linea) {
      let icon = '';
      switch (linea.type) {
        case 'metro':
          icon = 'csvicon-metro';
          break;
        case 'bus':
          icon = 'csvicon-bus';
          break;
        case 'ferrocarril':
          icon = 'csvicon-metro';
          break;
        case 'rodalies':
          icon = 'csvicon-metro';
          break;
        case 'tram':
          icon = 'csvicon-tranvia';
          break;
        case 'aerobus':
          icon = 'csvicon-bus';
          break;
        case 'funicular':
          icon = 'csvicon-funicular';
          break;
        default:
          break;
      }
      return icon;
    },

    setTrafficState(dataLayerName, future) {
      var trafficService = 'servicios/traffic/';
      /*
      Estado del tráfico
       1 = muy fluido,{background-color: #33ffff}  // azul
       2 = fluido, {background-color: #66ff00}     // verde
       3 = denso, {background-color: #ffff99}      // amarillo
       4 = muy denso,  {background-color: #ff9966} // naranja
       5 = congestión, {background-color: #ff0066} // rojo
       0 = sin datos, {background-color: #cccccc}  // gris
       6 = cortado,{background-color: #000000}     // negro
       99 = no match {background-color: #cccccc}   // valor propio para pintar los step sin match con el fichero de tramos
      */
      var trafficStyles = [];
      trafficStyles[1] = {
        strokeColor: '#33ffff',
        strokeOpacity: 1.0,
        strokeWeight: 8,
        zIndex: 999,
      };
      trafficStyles[2] = {
        strokeColor: '#66ff00',
        strokeOpacity: 1.0,
        strokeWeight: 8,
        zIndex: 999,
      };
      trafficStyles[3] = {
        strokeColor: '#ffff99',
        strokeOpacity: 1.0,
        strokeWeight: 8,
        zIndex: 99,
      };
      trafficStyles[4] = {
        strokeColor: '#ff9966',
        strokeOpacity: 1.0,
        strokeWeight: 8,
        zIndex: 999,
      };
      trafficStyles[5] = {
        strokeColor: '#ff0066',
        strokeOpacity: 1.0,
        strokeWeight: 8,
        zIndex: 999,
      };
      trafficStyles[0] = {
        strokeColor: '#cccccc',
        strokeOpacity: 1.0,
        strokeWeight: 8,
        zIndex: 999,
      };
      trafficStyles[6] = {
        strokeColor: '#000000',
        strokeOpacity: 1.0,
        strokeWeight: 8,
        zIndex: 999,
      };
      trafficStyles[99] = { // no cambiar el índice
        strokeColor: '#cccccc',
        strokeOpacity: 1,
        strokeWeight: 8,
        zIndex: 999,
      };

      api.get(`${getApiUri()}${trafficService}`).then((response) => {
        const layer = DataLayer.getDataLayer(dataLayerName);
        for (let i = 0; i < response.data.results.length; i++) {
          const path = response.data.results[i];
          const id = path.path_id.trim();
          if (layer.getFeatureById(id)) {
            let state = path.actual_state;
            if (future) state = path.future_state;
            const color = trafficStyles[state].strokeColor;
            layer.overrideStyle(layer.getFeatureById(id), { strokeColor: color });
          }
        }
      });
    },

    loadArrowDirections(dataLayerCicleLanes) {
      const configArrows = cloneDeep(dataLayerCicleLanes.conf);
      configArrows.name = `${dataLayerCicleLanes.get('layerName')}_arrows`;
      configArrows.minZoomLevel = 15;

      const dataLayerArrows = new DataLayer(configArrows, this.mapObj);
      const featarr = dataLayerCicleLanes.getAllFeatures();
      const directionTypes = ['UNIDIRECCIONAL', 'BIDIRECCIONAL'];
      const arrowsFeatures = [];
      this.directionIcons = [];
      for (var c in featarr) {
        var feature = featarr[c];
        var type = '';
        if (feature.getProperty('ETIQUETA').indexOf('unidireccional') != -1) type = 'UNIDIRECCIONAL';
        if (feature.getProperty('ETIQUETA').indexOf('bidireccional') != -1) type = 'BIDIRECCIONAL';
        if (directionTypes.indexOf(type) != -1) {
          const coordinates = [];
          const geometry = feature.getGeometry();
          geometry.forEachLatLng((latlng) => {
            coordinates.push(latlng);
          });
          var left = 'M -35,0.4 -45.8,13.7 0.1,74.8 46.5,13.7 35.8,0.4 0.1,48.2 z';
          var right = 'M 35.8,74.8 46.5,61.5 0.7,0.4 -45.8,61.5 -35,74.8 0.7,27 z';
          var path = left;
          var rotation = 180;
          const polyline = new google.maps.Polyline({
            path: coordinates,
            strokeColor: '#088106',
            strokeOpacity: 0,
            strokeWeight: 1,
            geodesic: true,
            icons: [
              {
                icon: {
                  path,
                  fillColor: '#088106',
                  rotation,
                  fillOpacity: 1,
                  scale: 0.14,
                },
                offset: '30px',
                repeat: '90px',
              },
            ],
          });
          this.directionIcons.push(polyline);
          if (type == 'BIDIRECCIONAL') {
            const polyline2 = new google.maps.Polyline({
              path: coordinates,
              strokeColor: '#088106',
              strokeOpacity: 0,
              strokeWeight: 1,
              geodesic: true,
              icons: [
                {
                  icon: {
                    path: right,
                    fillColor: '#088106',
                    rotation: 180,
                    fillOpacity: 1,
                    scale: 0.14,
                  },
                  offset: '10px',
                  repeat: '90px',
                },
              ],
            });
            this.directionIcons.push(polyline2);
          }
        }
      }
      for (const c in this.directionIcons) {
        if (this.mapObj.getZoom() >= 15) {
          this.directionIcons[c].setMap(this.mapObj);
          this.directionIcons[c].visible = true;
        }
      }
      google.maps.event.addListener(this.mapObj, 'zoom_changed', () => {
        const zoomLevel = this.mapObj.getZoom();
        let iconsVisible = null;
        const bicycleLanesLayer = find(this.activeLayers, (o) => { return o.code == 'carrilsbici'; });
        if (zoomLevel >= 15 && bicycleLanesLayer !== undefined) {
          iconsVisible = this.mapObj;
        }
        for (const c in this.directionIcons) {
          this.directionIcons[c].setMap(iconsVisible);
          this.directionIcons[c].visible = true;
        }
      });
    },

    loadIncidencias(config) {
      config.name = 'incidencias3';
      config.adaptativeMarkers = true;
      config.style = {
        path: '/img/markers/trafico',
        marker: 'obras.png',
        icon: 'obras.png',
      };
      const dataLayer = new DataLayer(config, this.mapObj);
      dataLayer.addGeoJsonDataLayer(`${getApiUri()}incidencies/3/`).then(() => {
        dataLayer.show();
      });
    },

    getIncidenciaDate(date, input_format = 'yyyy-LL-dd HH:mm', output_format = DateTime.DATETIME_SHORT) {
      return DateTime.fromFormat(date, input_format).toLocaleString(output_format);
    },

    loadBicing(dataLayer) {
      dataLayer.setStyle((feature) => {
        var capacity = feature.getProperty('slots');
        var bikes = feature.getProperty('bikes');
        var slots = capacity - bikes;
        var percentage = (bikes * 100) / capacity;
        var icon = 'bicing.svg';
        if (percentage < 90) icon = 'bicing-75.svg';
        if (percentage < 60) icon = 'bicing-50.svg';
        if (percentage < 35) icon = 'bicing-25.svg';
        if (percentage == 0) icon = 'bicing-0.svg';
        const config = dataLayer.conf;
        var { style } = config;
        style.icon = {
          url: require(`@/assets${style.path}/${icon}`),
        };
        return style;
      });
    },

    loadBiciFriends(dataLayer) {
      dataLayer.setStyle((feature) => {
        let icon = 'edifici_amicbici_certified.svg';
        if (feature.getProperty('any_certificacio') == '') {
          icon = 'edifici_amicbici_in_process.svg';
        }
        const config = dataLayer.conf;
        var { style } = config;
        style.icon = {
          url: require(`@/assets${style.path}/${icon}`),
        };
        return style;
      });
    },

    loadTaxi() {
      const transportGroup = find(this.layerMenu, (o) => { return o.code == 'transport'; });
      const optionTaxi = find(transportGroup.options, (o) => { return o.code == 'taxi'; });
      this.selectOpcion(optionTaxi);
    },

    alterationAlertVisible(line) {
      if (line == undefined) return false;
      for (const c in this.alterationMessages) {
        const incidencia = this.alterationMessages[c];
        if (incidencia.linia.toLowerCase() == line.toLowerCase()
          && new Date(incidencia.data_inici) < new Date()
          && new Date(incidencia.data_fi) > new Date()) {
          return incidencia;
        }
      }
      return false;
    },

    loadHighlights() {
      const target = this;
      const config = {
        name: 'highlights',
        minZoomLevel: 10,
        visible: true,
        adaptativeMarkers: true,
        style: {
          path: '/img/markers/publico',
          marker: 'highlight.svg',
          icon: 'highlight.svg',
          clusterMarker: 'highlight.svg',
        },
        clustering: true,
        maxZoomClusteringLevel: 16,
        clusterAnchor: [-10, 0],
        clusterTextSize: 11,
        clusterTextColor: '#ffffff',
        clusterFontWeigth: 'bold',
        clusterURL: '/cluster/',
        clusterGridSize: 60,
        clusterIconSize: [24, 35],
        displayInfo: true,
        infoTitle: 'Nom',
        info: [],
        callback: {
          displayInfo(feature, config) {
            app.$children[0].$refs.infoPanel.initInfoPanel();
            const infoTitle = feature.getProperty('Nom');
            target.setInfoPanel({
              title: infoTitle,
              feature,
              code: 'highlights',
              icon_title: 'markers/publico/highlight.svg',
              type: 'info_point',
              datos: [],
              visible: true,
            });
          },
        },
      };
      this.loadLayerByConfig(config, 'highlights/');
    },
  },
};

export const dummy = {};
