export default [
  {
    linia: 'L5',
    missatges: {
      ca: 'Alteracions a l\'L5 del metro. Consulta alternatives de transport.',
      es: 'Alteraciones a la L5 del metro. Consulta alternativas de transporte.',
      en: 'Alterations to metro L5. Consult alternative means of transport.',
    },
    links: {
      ca: 'https://tmb.cat/millores-metro?utm_source=Ajuntament&utm_medium=referral&utm_campaign=md_tallL5SS_03_2022&utm_content=ca',
      es: 'https://tmb.cat/mejoras-metro?utm_source=Ajuntament&utm_medium=referral&utm_campaign=md_tallL5SS_03_2022&utm_content=es',
      en: 'https://tmb.cat/network-improvements/metro-improvements?utm_source=Ajuntament&utm_medium=referral&utm_campaign=md_tallL5SS_03_2022&utm_content=en',
    },
    data_inici: '2022-07-01T00:00:00.000Z',
    data_fi: '2022-08-31T00:00:00.000Z',
  },
  {
    linia: 'L3',
    missatges: {
      ca: 'Alteracions a l\'L3 del metro. Consulta alternatives de transport.',
      es: 'Alteraciones a la L3 del metro. Consulta alternativas de transporte.',
      en: 'Alterations to metro L3. Consult alternative means of transport.',
    },
    links: {
      ca: 'https://tmb.cat/millores-metro?utm_source=Ajuntament&utm_medium=referral&utm_campaign=md_tallL5SS_03_2022&utm_content=ca',
      es: 'https://tmb.cat/mejoras-metro?utm_source=Ajuntament&utm_medium=referral&utm_campaign=md_tallL5SS_03_2022&utm_content=es',
      en: 'https://tmb.cat/network-improvements/metro-improvements?utm_source=Ajuntament&utm_medium=referral&utm_campaign=md_tallL5SS_03_2022&utm_content=en',
    },
    data_inici: '2022-07-11T00:00:00.000Z',
    data_fi: '2022-08-17T00:00:00.000Z',
  },
];
